// src/pages/About.jsx
import React from 'react';

const About = () => {
  return (
    <div className="mt-20 max-w-3xl mx-auto bg-white p-8 rounded-md shadow-md">
      {/* Section 1: About Atlaz */}
      <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Purpose</h2>
      <div className="text-gray-700 leading-relaxed space-y-4">
        <p>
        Knowledge is the foundation of human progress. By sharing concepts through language, we minimize suffering and turn challenges into meaningful pursuits.
        </p>
        <p>
        LLMs excel at synthesizing information—writing code, answering questions, and summarizing text—but they lack true understanding. Knowledge graphs provide structured, machine-readable representations that enhance reasoning for both humans and AI.
        </p>
        <p>
        Atlaz simplifies the creation and deployment of Knowledge Graphs for AI applications. It automatically generates graphs from domain-specific texts and serves them through a Python library, enabling seamless integration into RAG pipelines and advanced reasoning.
        </p>
      </div>

      {/* Section 2: Founder Information */}
      <div className="mt-12">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Journey</h2>
        <div className="flex flex-col items-center space-y-6">
          {/* Text Section */}
          <div className="text-gray-700 leading-relaxed">
            <p className="mb-4">
              Atlaz was founded by Rasmus Bjersing, based on a vision of a new era of AI powered by knowledge graphs.
            </p>
            <p className="mb-4">
              As a kid, I was fascinated by how to structure information. In primary school, I would spend hours drawing geographical maps, and as a student, I would spend days organizing my course material into meticulous mind maps in Microsoft Excel.
            </p>
            <p className="mb-4">
              After discovering Knowledge Graphs, I was hooked. It became a hobby taking up more and more of my time and grew into an integral way of how I worked and studied. The idea of a perfect data model and the potential it holds for AI was eventually too much to resist. I started Atlaz to empower others to build, visualize, and deploy knowledge graphs—turning raw data into powerful information structures for accurate, explainable, and safe AI.
            </p>
          </div>
          {/* Image Section */}
          <div className="flex justify-center">
            <img 
              src="/founder_image.png" 
              alt="Rasmus, Founder of Atlaz" 
              className="w-48 h-auto rounded-full shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;