// src/cached_examples.js

export const cachedExamples = {
    example1: {
        "nodes": [
            {
                "id": "100",
                "label": "Entity",
                "color": "lightgreen",
                "shape": "box",
                "info": "A general entity that serves as the root for all other entities."
            },
            {
                "id": "1",
                "label": "Tesla, Inc.",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An American electric vehicle and clean energy company."
            },
            {
                "id": "2",
                "label": "Securities and Exchange Commission (SEC)",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A U.S. government agency responsible for enforcing the federal securities laws and regulating the securities industry."
            },
            {
                "id": "3",
                "label": "Common Stock",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A type of security that represents ownership in a corporation."
            },
            {
                "id": "4",
                "label": "Convertible Senior Notes",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A type of debt security that can be converted into a predetermined number of the issuer's shares."
            },
            {
                "id": "30",
                "label": "Tesla Manufacturing Facility",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A facility where Tesla manufactures its products."
            },
            {
                "id": "5",
                "label": "Fremont Factory",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Fremont, California."
            },
            {
                "id": "6",
                "label": "Gigafactory Shanghai",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Shanghai, China."
            },
            {
                "id": "7",
                "label": "Gigafactory Berlin-Brandenburg",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Berlin-Brandenburg, Germany."
            },
            {
                "id": "8",
                "label": "Gigafactory Texas",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Texas, USA."
            },
            {
                "id": "9",
                "label": "Gigafactory Nevada",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A Tesla manufacturing facility located in Nevada, USA."
            },
            {
                "id": "10",
                "label": "Form 10-Q",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A quarterly report filed by public companies to the SEC, providing a comprehensive overview of the company's financial performance."
            },
            {
                "id": "31",
                "label": "Legal Action",
                "color": "lime",
                "shape": "box",
                "info": "A process of taking legal action."
            },
            {
                "id": "11",
                "label": "Litigation",
                "color": "lime",
                "shape": "box",
                "info": "The process of taking legal action."
            },
            {
                "id": "12",
                "label": "Derivative Action",
                "color": "lime",
                "shape": "box",
                "info": "A lawsuit brought by a shareholder on behalf of a corporation against a third party."
            },
            {
                "id": "33",
                "label": "Tesla Product",
                "color": "lightgreen",
                "shape": "box",
                "info": "A product produced by Tesla."
            },
            {
                "id": "32",
                "label": "Tesla Vehicle",
                "color": "lightgreen",
                "shape": "box",
                "info": "A vehicle produced by Tesla."
            },
            {
                "id": "13",
                "label": "Model S",
                "color": "lightgreen",
                "shape": "box",
                "info": "A luxury all-electric sedan produced by Tesla."
            },
            {
                "id": "14",
                "label": "Model X",
                "color": "lightgreen",
                "shape": "box",
                "info": "A luxury all-electric SUV produced by Tesla."
            },
            {
                "id": "15",
                "label": "Model 3",
                "color": "lightgreen",
                "shape": "box",
                "info": "A compact all-electric sedan produced by Tesla."
            },
            {
                "id": "16",
                "label": "Model Y",
                "color": "lightgreen",
                "shape": "box",
                "info": "A compact all-electric SUV produced by Tesla."
            },
            {
                "id": "17",
                "label": "Cybertruck",
                "color": "lightgreen",
                "shape": "box",
                "info": "An all-electric pickup truck produced by Tesla."
            },
            {
                "id": "18",
                "label": "Tesla Semi",
                "color": "lightgreen",
                "shape": "box",
                "info": "An all-electric semi-truck produced by Tesla."
            },
            {
                "id": "19",
                "label": "Powerwall",
                "color": "lightgreen",
                "shape": "box",
                "info": "A rechargeable home battery system produced by Tesla."
            },
            {
                "id": "20",
                "label": "Megapack",
                "color": "lightgreen",
                "shape": "box",
                "info": "A large-scale battery storage product produced by Tesla."
            }
        ],
        "edges": [
            {
                "source": "100",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "30",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "31",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "33",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "100",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "All entities are entities."
            },
            {
                "source": "3",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Common stock is a type of financial instrument."
            },
            {
                "source": "30",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Fremont Factory is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Shanghai is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Berlin-Brandenburg is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Texas is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "30",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gigafactory Nevada is a type of Tesla Manufacturing Facility."
            },
            {
                "source": "31",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Litigation is a type of Legal Action."
            },
            {
                "source": "31",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Derivative Action is a type of Legal Action."
            },
            {
                "source": "33",
                "target": "32",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Tesla Vehicle is a type of Tesla Product."
            },
            {
                "source": "33",
                "target": "19",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Powerwall is a type of Tesla Product."
            },
            {
                "source": "33",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Megapack is a type of Tesla Product."
            },
            {
                "source": "32",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model S is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model X is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model 3 is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "16",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Model Y is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "17",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Cybertruck is a type of Tesla Vehicle."
            },
            {
                "source": "32",
                "target": "18",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Tesla Semi is a type of Tesla Vehicle."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "files",
                "info": "Tesla, Inc. files Form 10-Q as part of its regulatory requirements."
            },
            {
                "source": "1",
                "target": "13",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model S at the Fremont Factory."
            },
            {
                "source": "1",
                "target": "15",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model 3 at Gigafactory Shanghai."
            },
            {
                "source": "1",
                "target": "16",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Model Y at Gigafactory Berlin-Brandenburg."
            },
            {
                "source": "1",
                "target": "17",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Cybertruck at Gigafactory Texas."
            },
            {
                "source": "1",
                "target": "18",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "manufactures",
                "info": "Tesla, Inc. manufactures Tesla Semi at Gigafactory Nevada."
            },
            {
                "source": "1",
                "target": "11",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "involved in",
                "info": "Tesla, Inc. is involved in litigation related to its CEO Performance Award."
            },
            {
                "source": "1",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "involved in",
                "info": "Tesla, Inc. is involved in derivative actions filed by shareholders."
            },
            {
                "source": "2",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "regulates",
                "info": "The SEC regulates Tesla, Inc. as it is a public company."
            }
        ],
        "categories": [
            {
                "name": "Entities",
                "color": "lightgreen"
            },
            {
                "name": "Organizations",
                "color": "MediumSeaGreen"
            },
            {
                "name": "Financial Instruments",
                "color": "ForestGreen"
            },
            {
                "name": "Locations",
                "color": "PaleGreen"
            },
            {
                "name": "Financial Reports",
                "color": "SpringGreen"
            },
            {
                "name": "Legal Concepts",
                "color": "lime"
            },
            {
                "name": "Products",
                "color": "lightgreen"
            }
        ]
    },
    example2: {
        "nodes": [
            {
                "id": "1",
                "label": "Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A set with a law of composition that is associative, has a unit element, and every element has an inverse."
            },
            {
                "id": "2",
                "label": "Monoid",
                "color": "lightgreen",
                "shape": "box",
                "info": "A set with an associative law of composition and a unit element."
            },
            {
                "id": "3",
                "label": "Cyclic Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group generated by a single element."
            },
            {
                "id": "4",
                "label": "Abelian Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group where the law of composition is commutative."
            },
            {
                "id": "5",
                "label": "Normal Subgroup",
                "color": "lightgreen",
                "shape": "box",
                "info": "A subgroup that is invariant under conjugation by any element of the group."
            },
            {
                "id": "6",
                "label": "Factor Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group formed by the cosets of a normal subgroup."
            },
            {
                "id": "7",
                "label": "Permutation Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group whose elements are bijective transformations on a set."
            },
            {
                "id": "8",
                "label": "Symmetric Group",
                "color": "lightgreen",
                "shape": "box",
                "info": "The group of all permutations of a finite set."
            },
            {
                "id": "9",
                "label": "Direct Product",
                "color": "lightgreen",
                "shape": "box",
                "info": "A group formed by the Cartesian product of groups with componentwise operation."
            },
            {
                "id": "10",
                "label": "Mathematical Structure",
                "color": "lightgreen",
                "shape": "box",
                "info": "A general concept in mathematics that includes sets with operations."
            },
            {
                "id": "11",
                "label": "Mathematical Concept",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A general concept in mathematics."
            },
            {
                "id": "12",
                "label": "Law of Composition",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A rule that assigns to each pair of elements in a set a third element in the same set."
            },
            {
                "id": "13",
                "label": "Unit Element",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An element that acts as an identity for the law of composition."
            },
            {
                "id": "14",
                "label": "Inverse Element",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An element that, when combined with a given element under the law of composition, yields the unit element."
            },
            {
                "id": "15",
                "label": "Homomorphism",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A structure-preserving map between two algebraic structures."
            },
            {
                "id": "16",
                "label": "Isomorphism",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A bijective homomorphism."
            },
            {
                "id": "17",
                "label": "Kernel",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "The set of elements that map to the identity element under a homomorphism."
            },
            {
                "id": "18",
                "label": "Image",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "The set of all outputs of a function or homomorphism."
            },
            {
                "id": "19",
                "label": "Order of a Group",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "The number of elements in a group."
            },
            {
                "id": "20",
                "label": "Order of an Element",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "The smallest positive integer such that the element raised to that power is the identity element."
            },
            {
                "id": "21",
                "label": "Mathematician",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A person who specializes in the field of mathematics."
            },
            {
                "id": "22",
                "label": "N. H. Abel",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A mathematician known for his work in group theory, particularly abelian groups."
            },
            {
                "id": "23",
                "label": "E. Galois",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A mathematician known for his work in Galois theory and permutation groups."
            },
            {
                "id": "24",
                "label": "S. Lie",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A mathematician known for his work on transformation groups."
            },
            {
                "id": "25",
                "label": "H. Weber",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A mathematician known for his contributions to the axiomatic characterization of groups."
            },
            {
                "id": "26",
                "label": "Concept",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A general idea or understanding of something."
            }
        ],
        "edges": [
            {
                "source": "2",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A group is a monoid with the additional property that every element has an inverse."
            },
            {
                "source": "1",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A cyclic group is a specific type of group generated by a single element."
            },
            {
                "source": "1",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "An abelian group is a group where the law of composition is commutative."
            },
            {
                "source": "1",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A normal subgroup is a subgroup that is invariant under conjugation by any element of the group."
            },
            {
                "source": "1",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A factor group is formed by the cosets of a normal subgroup."
            },
            {
                "source": "1",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A permutation group is a group whose elements are bijective transformations on a set."
            },
            {
                "source": "1",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A direct product is a group formed by the Cartesian product of groups with componentwise operation."
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "A symmetric group is a specific type of permutation group that includes all permutations of a finite set."
            },
            {
                "source": "11",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Law of Composition is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Unit Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Inverse Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Homomorphism is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "16",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Isomorphism is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "17",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Kernel is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "18",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Image is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "19",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Order of a Group is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Order of an Element is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Concept is a fundamental mathematical concept."
            },
            {
                "source": "11",
                "target": "21",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Concept is a fundamental mathematical concept."
            },
            {
                "source": "10",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Mathematical Structure is a fundamental mathematical concept."
            },
            {
                "source": "21",
                "target": "22",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "N. H. Abel is a mathematician."
            },
            {
                "source": "21",
                "target": "23",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "E. Galois is a mathematician."
            },
            {
                "source": "21",
                "target": "24",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "S. Lie is a mathematician."
            },
            {
                "source": "21",
                "target": "25",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "H. Weber is a mathematician."
            },
            {
                "source": "26",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type_of",
                "info": "Concept is a global concept that encompasses all other concepts."
            },
            {
                "source": "1",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The law of composition is a fundamental operation in the definition of a group."
            },
            {
                "source": "1",
                "target": "13",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The unit element acts as an identity in the law of composition for groups."
            },
            {
                "source": "1",
                "target": "14",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "An inverse element is required for a set to be considered a group."
            },
            {
                "source": "1",
                "target": "15",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "A homomorphism is a structure-preserving map between groups."
            },
            {
                "source": "1",
                "target": "16",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "An isomorphism is a bijective homomorphism between groups."
            },
            {
                "source": "1",
                "target": "19",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The order of a group is the number of elements in the group."
            },
            {
                "source": "1",
                "target": "20",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The order of an element is the smallest positive integer such that the element raised to that power is the identity element."
            },
            {
                "source": "15",
                "target": "17",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The kernel of a homomorphism is a normal subgroup of the domain group."
            },
            {
                "source": "15",
                "target": "18",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "has",
                "info": "The image of a homomorphism is a subgroup of the codomain group."
            },
            {
                "source": "22",
                "target": "4",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "N. H. Abel is known for his work on abelian groups."
            },
            {
                "source": "23",
                "target": "7",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "E. Galois is known for his work on permutation groups and Galois theory."
            },
            {
                "source": "24",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "S. Lie contributed to the axiomatic characterization of groups."
            },
            {
                "source": "25",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "contributed to",
                "info": "H. Weber contributed to the axiomatic characterization of groups."
            }
        ],
        "categories": [
            {
                "name": "Mathematical Structures",
                "color": "lightgreen"
            },
            {
                "name": "Mathematical Concepts",
                "color": "MediumSeaGreen"
            },
            {
                "name": "Mathematicians",
                "color": "ForestGreen"
            },
            {
                "name": "Global Concepts",
                "color": "PaleGreen"
            }
        ]
    },
    example3: {
        "nodes": [
            {
                "id": "2",
                "label": "Technological Concept",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A broad category encompassing various technologies and methodologies."
            },
            {
                "id": "3",
                "label": "Genome Editing Technology",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A technology used to alter the genetic material of living organisms."
            },
            {
                "id": "4",
                "label": "CRISPR-Cas9",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A genome editing technology that uses a CRISPR-associated protein 9 to edit genes."
            },
            {
                "id": "5",
                "label": "Zinc-Finger Nucleases (ZFNs)",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A genome editing technology that uses engineered zinc-finger proteins to create double-strand breaks in DNA."
            },
            {
                "id": "6",
                "label": "Transcription Activator-Like Effector Nucleases (TALENs)",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A genome editing technology that uses transcription activator-like effectors to create double-strand breaks in DNA."
            },
            {
                "id": "7",
                "label": "DNA Repair Mechanism",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A biological process by which a cell identifies and corrects damage to the DNA molecules that encode its genome."
            },
            {
                "id": "8",
                "label": "Homologous Recombination (HR)",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A molecular mechanism for DNA repair that uses a homologous sequence as a template for repair."
            },
            {
                "id": "9",
                "label": "Non-Homologous End Joining (NHEJ)",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A molecular mechanism for DNA repair that directly ligates the ends of DNA without a homologous template."
            },
            {
                "id": "10",
                "label": "Application of Genome Editing",
                "color": "lime",
                "shape": "box",
                "info": "General applications of genome editing technologies."
            },
            {
                "id": "11",
                "label": "Gene Therapy",
                "color": "lime",
                "shape": "box",
                "info": "The use of genome editing technologies to treat or prevent diseases by correcting defective genes."
            },
            {
                "id": "12",
                "label": "Haematological Disorders",
                "color": "lime",
                "shape": "box",
                "info": "Disorders related to blood and its components, which can be treated using genome editing technologies."
            },
            {
                "id": "13",
                "label": "Research and Development Application",
                "color": "lightgreen",
                "shape": "box",
                "info": "Applications of technologies in research and development."
            },
            {
                "id": "14",
                "label": "CRISPR Interference (CRISPRi)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A technology that uses a catalytically inactive Cas9 to block transcription of target genes."
            },
            {
                "id": "15",
                "label": "CRISPR Activation (CRISPRa)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A technology that uses a modified Cas9 to activate transcription of target genes."
            }
        ],
        "edges": [
            {
                "source": "2",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Genome Editing Technology is a type of technological concept."
            },
            {
                "source": "2",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "DNA Repair Mechanism is a type of technological concept."
            },
            {
                "source": "2",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Application of Genome Editing is a type of technological concept."
            },
            {
                "source": "2",
                "target": "13",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Research and Development Application is a type of technological concept."
            },
            {
                "source": "3",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR-Cas9 is a type of genome editing technology."
            },
            {
                "source": "3",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Zinc-Finger Nucleases are a type of genome editing technology."
            },
            {
                "source": "3",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Transcription Activator-Like Effector Nucleases are a type of genome editing technology."
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Homologous Recombination is a type of DNA repair mechanism."
            },
            {
                "source": "7",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Non-Homologous End Joining is a type of DNA repair mechanism."
            },
            {
                "source": "10",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Gene Therapy is an application of genome editing."
            },
            {
                "source": "10",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Haematological Disorders are a type of application of genome editing."
            },
            {
                "source": "13",
                "target": "14",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR Interference is a research and development application of CRISPR-Cas9."
            },
            {
                "source": "13",
                "target": "15",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "CRISPR Activation is a research and development application of CRISPR-Cas9."
            },
            {
                "source": "4",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used for",
                "info": "CRISPR-Cas9 is used to correct mutations in β-thalassaemia, sickle-cell disease, Fanconi anaemia, Diamond-Blackfan anaemia, thrombocytopenia, and haemophilia."
            },
            {
                "source": "4",
                "target": "8",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "utilizes",
                "info": "CRISPR-Cas9 can utilize HDR for precise genome editing."
            },
            {
                "source": "4",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "utilizes",
                "info": "CRISPR-Cas9 can utilize NHEJ for genome editing."
            }
        ],
        "categories": [
            {
                "name": "Global Root",
                "color": "lightgreen"
            },
            {
                "name": "Conceptual Framework",
                "color": "MediumSeaGreen"
            },
            {
                "name": "Technological Concepts",
                "color": "ForestGreen"
            },
            {
                "name": "Genome Editing Technologies",
                "color": "PaleGreen"
            },
            {
                "name": "Molecular Mechanisms for DNA Repair",
                "color": "SpringGreen"
            },
            {
                "name": "Applications of Genome Editing",
                "color": "lime"
            },
            {
                "name": "Research and Development",
                "color": "lightgreen"
            },
            {
                "name": "Relationship Types",
                "color": "MediumSeaGreen"
            }
        ]
    },
    example4: {
        "nodes": [
            {
                "id": "1",
                "label": "Transformer",
                "color": "lightgreen",
                "shape": "box",
                "info": "A model architecture based solely on attention mechanisms, dispensing with recurrence and convolutions entirely."
            },
            {
                "id": "2",
                "label": "Recurrent Neural Network (RNN)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A type of neural network where connections between nodes form a directed graph along a temporal sequence."
            },
            {
                "id": "3",
                "label": "Convolutional Neural Network (CNN)",
                "color": "lightgreen",
                "shape": "box",
                "info": "A class of deep neural networks, most commonly applied to analyzing visual imagery."
            },
            {
                "id": "4",
                "label": "Encoder-Decoder Architecture",
                "color": "lightgreen",
                "shape": "box",
                "info": "A neural network design pattern for sequence-to-sequence tasks, consisting of an encoder to process the input and a decoder to generate the output."
            },
            {
                "id": "20",
                "label": "Neural Network Model",
                "color": "lightgreen",
                "shape": "box",
                "info": "A computational model inspired by the way biological neural networks in the human brain process information."
            },
            {
                "id": "5",
                "label": "Self-Attention",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An attention mechanism relating different positions of a single sequence to compute a representation of the sequence."
            },
            {
                "id": "6",
                "label": "Scaled Dot-Product Attention",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An attention mechanism where the dot products of the query with all keys are computed, divided by the square root of the dimension of the keys, and a softmax function is applied to obtain the weights on the values."
            },
            {
                "id": "7",
                "label": "Multi-Head Attention",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "An attention mechanism that allows the model to jointly attend to information from different representation subspaces at different positions."
            },
            {
                "id": "21",
                "label": "Attention Mechanism",
                "color": "MediumSeaGreen",
                "shape": "box",
                "info": "A process that allows a model to focus on specific parts of the input sequence when producing an output."
            },
            {
                "id": "8",
                "label": "Machine Translation",
                "color": "ForestGreen",
                "shape": "box",
                "info": "The task of automatically converting text from one language to another."
            },
            {
                "id": "9",
                "label": "English Constituency Parsing",
                "color": "ForestGreen",
                "shape": "box",
                "info": "The task of analyzing the syntactic structure of a sentence according to a constituency-based grammar."
            },
            {
                "id": "22",
                "label": "Task",
                "color": "ForestGreen",
                "shape": "box",
                "info": "A specific function or activity that a model is designed to perform."
            },
            {
                "id": "10",
                "label": "BLEU Score",
                "color": "PaleGreen",
                "shape": "box",
                "info": "A metric for evaluating a generated sentence to a reference sentence, used in machine translation."
            },
            {
                "id": "11",
                "label": "Dropout",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A regularization technique for reducing overfitting in neural networks by preventing complex co-adaptations on training data."
            },
            {
                "id": "12",
                "label": "Label Smoothing",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A technique used during training to make the model less confident, improving accuracy and BLEU score."
            },
            {
                "id": "23",
                "label": "Training Technique",
                "color": "SpringGreen",
                "shape": "box",
                "info": "A method or strategy used to improve the performance of a model during training."
            },
            {
                "id": "24",
                "label": "Concept",
                "color": "lime",
                "shape": "box",
                "info": "A general idea or understanding of something."
            }
        ],
        "edges": [
            {
                "source": "20",
                "target": "1",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "The Transformer is a type of neural network model."
            },
            {
                "source": "20",
                "target": "2",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Recurrent Neural Networks are a type of neural network model."
            },
            {
                "source": "20",
                "target": "3",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Convolutional Neural Networks are a type of neural network model."
            },
            {
                "source": "20",
                "target": "4",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Encoder-Decoder Architecture is a type of neural network model."
            },
            {
                "source": "21",
                "target": "5",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Self-Attention is a type of attention mechanism."
            },
            {
                "source": "21",
                "target": "6",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Scaled Dot-Product Attention is a type of attention mechanism."
            },
            {
                "source": "21",
                "target": "7",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Multi-Head Attention is a type of attention mechanism."
            },
            {
                "source": "22",
                "target": "8",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Machine Translation is a type of task."
            },
            {
                "source": "22",
                "target": "9",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "English Constituency Parsing is a type of task."
            },
            {
                "source": "23",
                "target": "11",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Dropout is a type of training technique."
            },
            {
                "source": "23",
                "target": "12",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Label Smoothing is a type of training technique."
            },
            {
                "source": "24",
                "target": "21",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Attention Mechanism is a type of concept."
            },
            {
                "source": "24",
                "target": "20",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Neural Network Model is a type of concept."
            },
            {
                "source": "24",
                "target": "22",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Task is a type of concept."
            },
            {
                "source": "24",
                "target": "23",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Training Technique is a type of concept."
            },
            {
                "source": "24",
                "target": "10",
                "type": "subtype",
                "color": "black",
                "arrowhead": "normal",
                "label": "type of",
                "info": "Metric is a type of concept."
            },
            {
                "source": "1",
                "target": "5",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "uses",
                "info": "The Transformer uses self-attention to compute representations of its input and output."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "achieves",
                "info": "The Transformer achieves a BLEU score of 28.4 on the WMT 2014 English-to-German translation task."
            },
            {
                "source": "1",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "achieves",
                "info": "The Transformer achieves a BLEU score of 41.8 on the WMT 2014 English-to-French translation task."
            },
            {
                "source": "1",
                "target": "8",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "applied_to",
                "info": "Machine Translation is a task where the Transformer has been applied successfully."
            },
            {
                "source": "1",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "applied_to",
                "info": "English Constituency Parsing is a task where the Transformer has been applied successfully."
            },
            {
                "source": "11",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used_in",
                "info": "Dropout is used in the training of the Transformer to prevent overfitting."
            },
            {
                "source": "12",
                "target": "1",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "used_in",
                "info": "Label Smoothing is used in the training of the Transformer to improve accuracy and BLEU score."
            }
        ],
        "categories": [
            {
                "name": "Neural Network Models",
                "color": "lightgreen"
            },
            {
                "name": "Attention Mechanisms",
                "color": "MediumSeaGreen"
            },
            {
                "name": "Tasks",
                "color": "ForestGreen"
            },
            {
                "name": "Metrics",
                "color": "PaleGreen"
            },
            {
                "name": "Training Techniques",
                "color": "SpringGreen"
            },
            {
                "name": "Concepts",
                "color": "lime"
            }
        ]
    },
    example5: {
        "nodes": [
            {
                "id": "4",
                "label": "normalize working as soon as you can",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A call to make early entry into the workforce normalized, challenging conventional academic timelines.",
                "references": [
                    "normalize working as soon as you can normalize working as soon as you can"
                ],
                "Type": "Work Principle"
            },
            {
                "id": "5",
                "label": "dropping out of high school",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "An educational decision to leave the traditional high school system before graduation.",
                "references": [
                    "dropping out of high school dropping out of high school"
                ],
                "Type": "Educational Decision"
            },
            {
                "id": "6",
                "label": "what I love",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A personal passion or interest that drives one\u2019s work and life choices.",
                "references": [
                    "what I love what I love"
                ],
                "Type": "Pursuing Passion"
            },
            {
                "id": "7",
                "label": "most productive years of life",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A time period characterized by peak creative and professional output.",
                "references": [
                    "most productive years of life"
                ],
                "Type": "Time Period"
            },
            {
                "id": "8",
                "label": "academic necessity mindset",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A conventional mindset that overvalues formal academic routes as the only pathway to success.",
                "references": [
                    "we must get rid of the academic necessity mindset. we must get rid of the academic necessity mindset."
                ],
                "Type": "Mindset"
            },
            {
                "id": "1100",
                "label": "Burnout",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A state of physical and emotional exhaustion as indicated by 'burning out is real btw.'",
                "references": [
                    "burning out is real btw. burning out is real btw."
                ],
                "Type": "Burnout Experience"
            },
            {
                "id": "1101",
                "label": "Recovery Process",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The process of recuperating from burnout as described by 'it took me ~3 years to recover fully,'",
                "references": [
                    "it took me ~3 years to recover fully"
                ],
                "Type": "Burnout Aspect"
            },
            {
                "id": "1102",
                "label": "Work Impairment",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A period of inability to work as indicated by 'could physically not work for 5months after it happened'.",
                "references": [
                    "could physically not work for 5months after it happened could physically not work for 5months after it happened"
                ],
                "Type": "Burnout Aspect"
            },
            {
                "id": "1103",
                "label": "Stigmatizing Belief",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A belief expressed at a young age that burnout was not for those willing to work, as indicated by 'when i was 18 i thought' ... 'didn't wanna work.'",
                "references": [
                    "when i was 18 i thought it was something for dummies who didn't wanna work."
                ],
                "Type": "Burnout Aspect"
            },
            {
                "id": "2010",
                "label": "Gabriel",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The tweet author who is implicitly referenced as the writer of the tweet.",
                "references": [
                    "today i'm joining @OpenAI as a research scientist"
                ],
                "Type": "High School Dropout Instance & Person"
            },
            {
                "id": "2011",
                "label": "High School in Sweden",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A secondary educational institution in Sweden that was attended but not completed by the tweet author.",
                "references": [
                    "5 yrs ago, i dropped out of high school in sweden"
                ],
                "Type": "Educational Institution"
            },
            {
                "id": "2012",
                "label": "Startup",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A newly established business venture that the tweet author joined.",
                "references": [
                    "to join a startup"
                ],
                "Type": "Organization"
            },
            {
                "id": "2013",
                "label": "Engineer",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A professional role in engineering, which the tweet author had close to zero experience in.",
                "references": [
                    "with close to zero experience as an engineer. today i'm joining @OpenAI as a research scientist"
                ],
                "Type": "Job Role"
            },
            {
                "id": "2014",
                "label": "OpenAI",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A leading AI research organization that the tweet author is joining.",
                "references": [
                    "today i'm joining @OpenAI"
                ],
                "Type": "Organization"
            },
            {
                "id": "2015",
                "label": "Research Scientist",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A role involving conducting research, which the tweet author will fulfill at OpenAI.",
                "references": [
                    "as a research scientist"
                ],
                "Type": "Job Role"
            },
            {
                "id": "2016",
                "label": "AGI",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Artificial General Intelligence, the ambitious technological goal the tweet author aims to build.",
                "references": [
                    "to build agi with sora"
                ],
                "Type": "Technology"
            },
            {
                "id": "2017",
                "label": "Sora",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A collaborator mentioned in the tweet context of building AGI.",
                "references": [
                    "with sora"
                ],
                "Type": "Organization"
            },
            {
                "id": "3004",
                "label": "Top Down Learning",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A rapid learning approach for diffusion models completed in 3 days in a top down manner.",
                "references": [
                    "it takes 3 days to learn diffusion models top down"
                ],
                "Type": "Learning Approach"
            },
            {
                "id": "3005",
                "label": "Bottom Up Learning",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A gradual learning approach for diffusion models requiring 6 years, situated within an academic context.",
                "references": [
                    "6 years before you can learn it bottom up (academia)"
                ],
                "Type": "Learning Approach"
            },
            {
                "id": "3006",
                "label": "Academia",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The academic environment in which traditional, prolonged learning approaches are often applied.",
                "references": [
                    "bottom up (academia)"
                ],
                "Type": "Educational Environment"
            },
            {
                "id": "3007",
                "label": "Diffusion Models",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A class of computational models that simulate the diffusion process.",
                "references": [
                    "learn diffusion models"
                ],
                "Type": "Diffusion Concept"
            },
            {
                "id": "3008",
                "label": "Mathematics of Diffusion Models",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The mathematical principles underlying diffusion models.",
                "references": [
                    "get into the math"
                ],
                "Type": "Prerequisite Knowledge"
            },
            {
                "id": "3009",
                "label": "Unstructured Teaching",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "An unscalable and unstructured traditional method of teaching diffusion models.",
                "references": [
                    "unscalable way to teach students since it's so unstructured"
                ],
                "Type": "Learning Approach"
            },
            {
                "id": "3010",
                "label": "Structured Learning Approach",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A modern, organized method for teaching diffusion models that overcomes traditional limitations.",
                "references": [
                    "but now you can but now you can\u2026"
                ],
                "Type": "Learning Approach"
            },
            {
                "id": "4017",
                "label": "Uni Instance",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A specific instance of a University that challenges the traditional monopoly on foundational knowledge.",
                "references": [
                    "universities don't have monopoly on foundational knowledge anymore,"
                ],
                "Type": "University"
            },
            {
                "id": "4018",
                "label": "Foundational Knowledge Instance",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A specific instance reflecting that foundational knowledge is no longer monopolized by universities.",
                "references": [
                    "monopoly on foundational knowledge anymore,"
                ],
                "Type": "Foundational Knowledge"
            },
            {
                "id": "4019",
                "label": "Diffusion Model Instance",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A specific instance of a Diffusion Model, highlighting its generative and denoising processes.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ],
                "Type": "Diffusion Model"
            },
            {
                "id": "4020",
                "label": "Math Intuition Instance",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A specific instance capturing the underlying mathematical intuitions behind diffusion models.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ],
                "Type": "Math Intuition"
            },
            {
                "id": "4022",
                "label": "Claude Instance",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A specific instance of the AI assistant Claude encountered during the learning journey.",
                "references": [
                    "with with claude: with with claude:"
                ],
                "Type": "Claude"
            },
            {
                "id": "5005",
                "label": "Burnout",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A state of extreme mental or physical exhaustion.",
                "references": [
                    "the worse the burnout"
                ],
                "Type": "Condition"
            },
            {
                "id": "5006",
                "label": "Stress Allergy",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A condition where one develops a hypersensitivity reaction that manifests as an allergy to stress.",
                "references": [
                    "you basically become allergic to stress afterwards which is horrible"
                ],
                "Type": "Condition"
            },
            {
                "id": "5007",
                "label": "Stress",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A state of mental or physical tension due to external factors.",
                "references": [
                    "to stress afterwards"
                ],
                "Type": "Condition"
            },
            {
                "id": "6012",
                "label": "Gabriel",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The individual who authored the tweet.",
                "references": [
                    "should post a longer thread about this at some point should post a longer thread about this at some point"
                ],
                "Type": "Person"
            },
            {
                "id": "6014",
                "label": "Work Burnout Experience",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A personal instance or experience of work burnout as expressed in the text.",
                "references": [
                    "wtf i feel like i can't work wtf i feel like i can't work"
                ],
                "Type": "Work Burnout"
            },
            {
                "id": "6015",
                "label": "Chronic Work Burnout",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A chronic state resulting from persistently pushing through burnout, as warned against in the text.",
                "references": [
                    "pushing through it for months"
                ],
                "Type": "Work Burnout Experience"
            },
            {
                "id": "7004",
                "label": "XAI",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "XAI represents the organization mentioned in the tweet.",
                "references": [
                    "if xai worked"
                ],
                "Type": "Organization"
            },
            {
                "id": "7006",
                "label": "Standard Work Hours",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Standard work hours regime indicated as 16 hours per day.",
                "references": [
                    "16h per day before,"
                ],
                "Type": "Work Schedule"
            },
            {
                "id": "7007",
                "label": "Extended Work Hours",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Extended work hours regime indicated as 96 hours per day.",
                "references": [
                    "96h per day 96h per day"
                ],
                "Type": "Work Schedule"
            },
            {
                "id": "7008",
                "label": "Datacenter",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A high-value datacenter whose absence necessitates extended work hours.",
                "references": [
                    "lack of a half trillion dollar datacenter"
                ],
                "Type": "Infrastructure"
            },
            {
                "id": "7012",
                "label": "XAI Work Schedule",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A specific work schedule employed by XAI, derived from the general work schedule but tailored for XAI.",
                "references": [
                    "they now need to work 96h per day to compensate"
                ],
                "Type": "Work Schedule"
            },
            {
                "id": "8004",
                "label": "Stack Overflow",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A popular Q&A website for developers.",
                "references": [
                    "stack overflow answers for the academic publishing criteria"
                ],
                "Type": "Website"
            },
            {
                "id": "8005",
                "label": "Academic Publishing Criteria",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Criteria required for academic publishing, used as benchmarks for visa applications.",
                "references": [
                    "academic publishing criteria to get my O1 visa haha"
                ],
                "Type": "Criteria"
            },
            {
                "id": "8006",
                "label": "O1 Visa",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A visa granted to individuals with extraordinary ability.",
                "references": [
                    "O1 visa haha. if you want to get into the US"
                ],
                "Type": "Visa"
            },
            {
                "id": "8007",
                "label": "US",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The United States as a country.",
                "references": [
                    "if you want to get into the US, get good lawyers"
                ],
                "Type": "Country"
            },
            {
                "id": "8008",
                "label": "Lawyer",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A legal professional specializing in immigration.",
                "references": [
                    "get good lawyers who understand what matters for the gov &amp; how to stand out"
                ],
                "Type": "Professional"
            },
            {
                "id": "8022",
                "label": "Governmental Matters",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Key aspects and priorities of government policies and functions that are crucial to understand.",
                "references": [
                    "what matters for the gov &amp; how to stand out"
                ],
                "Type": "Government"
            },
            {
                "id": "9006",
                "label": "Private",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Indicates that a like is only visible to the owner. This is a subtype of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "PrivacySetting"
            },
            {
                "id": "9007",
                "label": "Public",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Indicates that a like is visible to everyone. This is a subtype of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "PrivacySetting"
            },
            {
                "id": "9008",
                "label": "karpathys",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A specific user whose likes are public; a subtype of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "User"
            },
            {
                "id": "9009",
                "label": "GenericUser",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A generic user representing users with private likes; a subtype of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "User"
            },
            {
                "id": "9013",
                "label": "GabrielsLike",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A like generated by Gabriel; a subtype of Like.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "Like"
            }
        ],
        "edges": [
            {
                "source": "5",
                "target": "6",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Facilitates",
                "info": "dropping out of high school facilitates engaging in what I love.",
                "references": [
                    "dropping out of high school made me able to work on what I love"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "6",
                "target": "7",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Extends Duration",
                "info": "engaging in what I love extends the productive period.",
                "references": [
                    "for 6 more years in my most productive years of life"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "8",
                "target": "4",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Undermines",
                "info": "the academic necessity mindset undermines the normalization of early workforce entry.",
                "references": [
                    "we must get rid of the academic necessity mindset. normalize working as soon as you can"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "1100",
                "target": "1101",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Recovery Process.",
                "references": [
                    "it took me ~3 years to recover fully"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "1100",
                "target": "1102",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Work Impairment.",
                "references": [
                    "could physically not work for 5months after it happened could physically not work for 5months after it happened"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "1100",
                "target": "1103",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Stigmatizing Belief.",
                "references": [
                    "when i was 18 i thought it was something for dummies who didn't wanna work."
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2011",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Dropped Out Of",
                "info": "Gabriel dropped out of high school in Sweden.",
                "references": [
                    "5 yrs ago, i dropped out of high school in sweden"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Joined",
                "info": "Gabriel joined a startup.",
                "references": [
                    "to join a startup"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2014",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Joined",
                "info": "Gabriel joined @OpenAI.",
                "references": [
                    "today i'm joining @OpenAI"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Experience",
                "info": "Gabriel has close to zero experience as an engineer.",
                "references": [
                    "with close to zero experience as an engineer. today i'm joining @OpenAI as a research scientist"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Works As",
                "info": "Gabriel works as a research scientist at @OpenAI.",
                "references": [
                    "as a research scientist"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2016",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Builds",
                "info": "Gabriel is building AGI.",
                "references": [
                    "to build agi with sora"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "2017",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Gabriel collaborates with Sora on building AGI.",
                "references": [
                    "with sora"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "3010",
                "target": "3009",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Contrasts With",
                "info": "Structured Learning Approach contrasts with Unstructured Teaching.",
                "references": [
                    "but now you can"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "3007",
                "target": "3008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Requires Prerequisite",
                "info": "Diffusion Models requires Mathematics of Diffusion Models.",
                "references": [
                    "get into the math"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "3004",
                "target": "3005",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Contrasts With",
                "info": "Top Down Learning contrasts with Bottom Up Learning with rapid versus prolonged learning as indicated in the tweet.",
                "references": [
                    "it takes 3 days to learn diffusion models top down, 6 years before you can learn it bottom up (academia)"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "3005",
                "target": "3006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Occurs In",
                "info": "Bottom Up Learning takes place in Academia as per the tweet.",
                "references": [
                    "bottom up (academia)"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "3004",
                "target": "3007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Applies To",
                "info": "Top Down Learning is applied to learn Diffusion Models quickly, as stated by Gabriel.",
                "references": [
                    "learn diffusion models top down"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "4017",
                "target": "4018",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Provides Foundational Knowledge",
                "info": "Uni Instance provides foundational knowledge to the Foundational Knowledge Instance.",
                "references": [
                    "universities don't have monopoly on foundational knowledge anymore,"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "4019",
                "target": "4020",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Math Basis",
                "info": "Diffusion Model Instance is based on the Math Intuition Instance.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "5005",
                "target": "5006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Severity Influences Duration",
                "info": "The severity of burnout influences the duration of stress allergy.",
                "references": [
                    "the worse the burnout the longer you'll be"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "5007",
                "target": "5006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Co-occurs With",
                "info": "Stress co-occurs with Stress Allergy.",
                "references": [
                    "to stress afterwards which is horrible"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "6012",
                "target": "6014",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Gives Advice About",
                "info": "Gabriel gives advice about not persisting through burnout experience.",
                "references": [
                    "DON'T PUSH THROUGH IT DON'T PUSH THROUGH IT"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "6014",
                "target": "6015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Results In",
                "info": "Persisting through work burnout experience results in chronic work burnout.",
                "references": [
                    "pushing through it for months"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "7004",
                "target": "7012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Employs Work Schedule",
                "info": "Gabriel indicates that XAI employs a work schedule.",
                "references": [
                    "if xai worked 16h per day before, they now need to work"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "7012",
                "target": "7008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Compensates For",
                "info": "Gabriel indicates that the work schedule compensates for the missing datacenter.",
                "references": [
                    "to compensate for the lack of a half trillion dollar datacenter"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "7006",
                "target": "7007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Changed Work Hours From To",
                "info": "Gabriel highlights the change from standard to extended work hours.",
                "references": [
                    "16h per day before, they now need to work 96h per day"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "8005",
                "target": "8006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Leads To",
                "info": "Academic Publishing Criteria leads to obtaining an O1 Visa.",
                "references": [
                    "academic publishing criteria to get my O1 visa haha"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "8007",
                "target": "8008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Requires",
                "info": "US requires good lawyers for immigration.",
                "references": [
                    "if you want to get into the US, get good lawyers"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "8008",
                "target": "8022",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Understands",
                "info": "Lawyer understands governmental matters.",
                "references": [
                    "get good lawyers who understand what matters for the gov &amp; how to stand out"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "9008",
                "target": "9007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Like Privacy Setting",
                "info": "karpathys has a public like privacy setting.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "9009",
                "target": "9006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Has Like Privacy Setting",
                "info": "GenericUser has a private like privacy setting.",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "8004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Utilizes",
                "info": "Gabriel utilizes Stack Overflow as an information source.",
                "references": [
                    "i used stack overflow answers for the academic publishing criteria"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "8005",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Adheres To",
                "info": "Gabriel adheres to Academic Publishing Criteria.",
                "references": [
                    "i used stack overflow answers for the academic publishing criteria to get my O1 visa haha"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "9013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "User Generates Like",
                "info": "Gabriel generates a like (GabrielsLike).",
                "references": [
                    "everyones likes are private except karpathys"
                ],
                "Type": "Has A Relationship To"
            },
            {
                "source": "2010",
                "target": "4022",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Learns With",
                "info": "Gabriel Student learns with Claude Instance as part of his learning journey.",
                "references": [
                    "with with claude: with with claude:"
                ],
                "Type": "Has A Relationship To"
            }
        ],
        "categories": [
            {
                "name": "Entity",
                "color": "lightblue"
            },
            {
                "name": "Has A Relationship To",
                "color": "#ffffff"
            },
            {
                "name": "Work Principle",
                "color": "#1d1d22"
            },
            {
                "name": "Educational Decision",
                "color": "#45454a"
            },
            {
                "name": "Pursuing Passion",
                "color": "#ffffff"
            },
            {
                "name": "Time Period",
                "color": "#1d1d22"
            },
            {
                "name": "Mindset",
                "color": "#45454a"
            },
            {
                "name": "Burnout Experience",
                "color": "#ffffff"
            },
            {
                "name": "Burnout Aspect",
                "color": "#1d1d22"
            },
            {
                "name": "High School Dropout Instance & Person",
                "color": "#45454a"
            },
            {
                "name": "Educational Institution",
                "color": "#ffffff"
            },
            {
                "name": "Organization",
                "color": "#1d1d22"
            },
            {
                "name": "Job Role",
                "color": "#45454a"
            },
            {
                "name": "Technology",
                "color": "#ffffff"
            },
            {
                "name": "Learning Approach",
                "color": "#1d1d22"
            },
            {
                "name": "Educational Environment",
                "color": "#45454a"
            },
            {
                "name": "Prerequisite Knowledge",
                "color": "#ffffff"
            },
            {
                "name": "Diffusion Concept",
                "color": "#1d1d22"
            },
            {
                "name": "University",
                "color": "#45454a"
            },
            {
                "name": "Foundational Knowledge",
                "color": "#ffffff"
            },
            {
                "name": "Diffusion Model",
                "color": "#1d1d22"
            },
            {
                "name": "Math Intuition",
                "color": "#45454a"
            },
            {
                "name": "High School Dropout",
                "color": "#ffffff"
            },
            {
                "name": "Claude",
                "color": "#1d1d22"
            },
            {
                "name": "Condition",
                "color": "#45454a"
            },
            {
                "name": "Burnout",
                "color": "#ffffff"
            },
            {
                "name": "Work Burnout",
                "color": "#1d1d22"
            },
            {
                "name": "Person",
                "color": "#45454a"
            },
            {
                "name": "Work Burnout Experience",
                "color": "#ffffff"
            },
            {
                "name": "Work Schedule",
                "color": "#1d1d22"
            },
            {
                "name": "Schedule",
                "color": "#45454a"
            },
            {
                "name": "Organization",
                "color": "#ffffff"
            },
            {
                "name": "Infrastructure",
                "color": "#1d1d22"
            },
            {
                "name": "Website",
                "color": "#45454a"
            },
            {
                "name": "Criteria",
                "color": "#ffffff"
            },
            {
                "name": "Visa",
                "color": "#1d1d22"
            },
            {
                "name": "Country",
                "color": "#45454a"
            },
            {
                "name": "Government",
                "color": "#ffffff"
            },
            {
                "name": "Professional",
                "color": "#1d1d22"
            },
            {
                "name": "User",
                "color": "#45454a"
            },
            {
                "name": "Like",
                "color": "#ffffff"
            },
            {
                "name": "PrivacySetting",
                "color": "#1d1d22"
            }
        ]
    },
    example6:{
        "nodes": [
            {
                "id": "4",
                "label": "normalize working as soon as you can",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A call to make early entry into the workforce normalized, challenging conventional academic timelines.",
                "references": [
                    "normalize working as soon as you can normalize working as soon as you can"
                ]
            },
            {
                "id": "5",
                "label": "dropping out of high school",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "An educational decision to leave the traditional high school system before graduation.",
                "references": [
                    "dropping out of high school dropping out of high school"
                ]
            },
            {
                "id": "6",
                "label": "what I love",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A personal passion or interest that drives one\u2019s work and life choices.",
                "references": [
                    "what I love"
                ]
            },
            {
                "id": "7",
                "label": "most productive years of life",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A time period characterized by peak creative and professional output.",
                "references": [
                    "most productive years of life"
                ]
            },
            {
                "id": "8",
                "label": "academic necessity mindset",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "A conventional mindset that overvalues formal academic routes as the only pathway to success.",
                "references": [
                    "we must get rid of the academic necessity mindset. we must get rid of the academic necessity mindset."
                ]
            },
            {
                "id": "9",
                "label": "Work Principle",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept governing attitudes towards work.",
                "references": []
            },
            {
                "id": "10",
                "label": "Educational Decision",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for decisions regarding formal education.",
                "references": []
            },
            {
                "id": "11",
                "label": "Pursuing Passion",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A category for engaging in work that aligns with one's passions.",
                "references": []
            },
            {
                "id": "12",
                "label": "Time Period",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A measurable duration of time used to contextualize life stages.",
                "references": []
            },
            {
                "id": "13",
                "label": "Mindset",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing established ways of thinking or attitudes.",
                "references": []
            },
            {
                "id": "1100",
                "label": "Burnout",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A state of physical and emotional exhaustion as indicated by 'burning out is real btw.'",
                "references": [
                    "burning out is real btw. burning out is real btw."
                ]
            },
            {
                "id": "1101",
                "label": "Recovery Process",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "The process of recuperating from burnout as described by 'it took me ~3 years to recover fully,'",
                "references": [
                    "it took me ~3 years to recover fully"
                ]
            },
            {
                "id": "1102",
                "label": "Work Impairment",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A period of inability to work as indicated by 'could physically not work for 5months after it happened'.",
                "references": [
                    "could physically not work for 5months after it happened could physically not work for 5months after it happened"
                ]
            },
            {
                "id": "1103",
                "label": "Stigmatizing Belief",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A belief expressed at a young age that burnout was not for those willing to work, as indicated by 'when i was 18 i thought' ... 'didn't wanna work.'",
                "references": [
                    "when i was 18 i thought it was something for dummies who didn't wanna work."
                ]
            },
            {
                "id": "1104",
                "label": "Burnout Experience",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category representing the overall experience of burnout.",
                "references": []
            },
            {
                "id": "1105",
                "label": "Burnout Aspect",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category representing a component or aspect of burnout.",
                "references": []
            },
            {
                "id": "2010",
                "label": "Gabriel",
                "color": "#777777",
                "shape": "ellipse",
                "info": "The tweet author who is implicitly referenced as the writer of the tweet.",
                "references": [
                    "today i'm joining @OpenAI as a research scientist"
                ]
            },
            {
                "id": "2011",
                "label": "High School in Sweden",
                "color": "#626262",
                "shape": "ellipse",
                "info": "A secondary educational institution in Sweden that was attended but not completed by the tweet author.",
                "references": [
                    "5 yrs ago, i dropped out of high school in sweden"
                ]
            },
            {
                "id": "2012",
                "label": "Startup",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A newly established business venture that the tweet author joined.",
                "references": [
                    "to join a startup"
                ]
            },
            {
                "id": "2013",
                "label": "Engineer",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A professional role in engineering, which the tweet author had close to zero experience in.",
                "references": [
                    "with close to zero experience as an engineer. today i'm joining @OpenAI as a research scientist"
                ]
            },
            {
                "id": "2014",
                "label": "OpenAI",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A leading AI research organization that the tweet author is joining.",
                "references": [
                    "today i'm joining @OpenAI"
                ]
            },
            {
                "id": "2015",
                "label": "Research Scientist",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A role involving conducting research, which the tweet author will fulfill at OpenAI.",
                "references": [
                    "as a research scientist"
                ]
            },
            {
                "id": "2016",
                "label": "AGI",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "Artificial General Intelligence, the ambitious technological goal the tweet author aims to build.",
                "references": [
                    "to build agi with sora"
                ]
            },
            {
                "id": "2017",
                "label": "Sora",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A collaborator mentioned in the tweet context of building AGI.",
                "references": [
                    "with sora"
                ]
            },
            {
                "id": "2004",
                "label": "Person",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A human individual who performs actions.",
                "references": []
            },
            {
                "id": "2005",
                "label": "Educational Institution",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An organization that provides educational services.",
                "references": []
            },
            {
                "id": "2006",
                "label": "Organization",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An organized group such as a company or startup.",
                "references": []
            },
            {
                "id": "2007",
                "label": "Job Role",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A role or occupation held by a person.",
                "references": []
            },
            {
                "id": "2008",
                "label": "Technology",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A field or technological concept.",
                "references": []
            },
            {
                "id": "3004",
                "label": "Top Down Learning",
                "color": "#272727",
                "shape": "ellipse",
                "info": "A rapid learning approach for diffusion models completed in 3 days in a top down manner.",
                "references": [
                    "it takes 3 days to learn diffusion models top down"
                ]
            },
            {
                "id": "3005",
                "label": "Bottom Up Learning",
                "color": "#272727",
                "shape": "ellipse",
                "info": "A gradual learning approach for diffusion models requiring 6 years, situated within an academic context.",
                "references": [
                    "6 years before you can learn it bottom up (academia)"
                ]
            },
            {
                "id": "3006",
                "label": "Academia",
                "color": "#1f1f1f",
                "shape": "ellipse",
                "info": "The academic environment in which traditional, prolonged learning approaches are often applied.",
                "references": [
                    "bottom up (academia)"
                ]
            },
            {
                "id": "3007",
                "label": "Diffusion Models",
                "color": "#171717",
                "shape": "ellipse",
                "info": "A class of computational models that simulate the diffusion process.",
                "references": [
                    "learn diffusion models"
                ]
            },
            {
                "id": "3008",
                "label": "Mathematics of Diffusion Models",
                "color": "#0f0f0f",
                "shape": "ellipse",
                "info": "The mathematical principles underlying diffusion models.",
                "references": [
                    "get into the math"
                ]
            },
            {
                "id": "3009",
                "label": "Unstructured Teaching",
                "color": "#272727",
                "shape": "ellipse",
                "info": "An unscalable and unstructured traditional method of teaching diffusion models.",
                "references": [
                    "unscalable way to teach students since it's so unstructured"
                ]
            },
            {
                "id": "3010",
                "label": "Structured Learning Approach",
                "color": "#272727",
                "shape": "ellipse",
                "info": "A modern, organized method for teaching diffusion models that overcomes traditional limitations.",
                "references": [
                    "but now you can but now you can\u2026"
                ]
            },
            {
                "id": "3020",
                "label": "Learning Approach",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract classification representing various methods for learning diffusion models.",
                "references": []
            },
            {
                "id": "3021",
                "label": "Educational Environment",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract classification for environments in which learning takes place.",
                "references": []
            },
            {
                "id": "3022",
                "label": "Prerequisite Knowledge",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract classification for foundational concepts that are required before advanced learning.",
                "references": []
            },
            {
                "id": "3023",
                "label": "Diffusion Concept",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract classification representing the core ideas and phenomena of diffusion models.",
                "references": []
            },
            {
                "id": "4017",
                "label": "Uni Instance",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A specific instance of a University that challenges the traditional monopoly on foundational knowledge.",
                "references": [
                    "universities don't have monopoly on foundational knowledge anymore,"
                ]
            },
            {
                "id": "4018",
                "label": "Foundational Knowledge Instance",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "A specific instance reflecting that foundational knowledge is no longer monopolized by universities.",
                "references": [
                    "monopoly on foundational knowledge anymore,"
                ]
            },
            {
                "id": "4019",
                "label": "Diffusion Model Instance",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A specific instance of a Diffusion Model, highlighting its generative and denoising processes.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ]
            },
            {
                "id": "4020",
                "label": "Math Intuition Instance",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A specific instance capturing the underlying mathematical intuitions behind diffusion models.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ]
            },
            {
                "id": "4021",
                "label": "High School Dropout Instance",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "A specific instance representing an individual pursuing learning outside of traditional schooling as a high school dropout.",
                "references": [
                    "as a high school dropout as a high school dropout"
                ]
            },
            {
                "id": "4022",
                "label": "Claude Instance",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A specific instance of the AI assistant Claude encountered during the learning journey.",
                "references": [
                    "with with claude: with with claude:"
                ]
            },
            {
                "id": "4010",
                "label": "University",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An institution that provides education and foundational knowledge.",
                "references": []
            },
            {
                "id": "4011",
                "label": "Foundational Knowledge",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Basic and essential understanding that is traditionally provided by universities.",
                "references": []
            },
            {
                "id": "4012",
                "label": "Diffusion Model",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A class of algorithms used for generative modeling and denoising processes.",
                "references": []
            },
            {
                "id": "4013",
                "label": "Math Intuition",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "The underlying mathematical concepts and intuitions behind diffusion models.",
                "references": []
            },
            {
                "id": "4014",
                "label": "High School Dropout",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An individual who pursues learning outside of traditional high school education.",
                "references": []
            },
            {
                "id": "4015",
                "label": "Claude",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An AI assistant referenced as part of the learning process.",
                "references": []
            },
            {
                "id": "5005",
                "label": "Burnout",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A state of extreme mental or physical exhaustion.",
                "references": [
                    "the worse the burnout"
                ]
            },
            {
                "id": "5006",
                "label": "Stress Allergy",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A condition where one develops a hypersensitivity reaction that manifests as an allergy to stress.",
                "references": [
                    "you basically become allergic to stress afterwards which is horrible"
                ]
            },
            {
                "id": "5007",
                "label": "Stress",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A state of mental or physical tension due to external factors.",
                "references": [
                    "to stress afterwards"
                ]
            },
            {
                "id": "5004",
                "label": "Condition",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A state affecting mental or physical health.",
                "references": []
            },
            {
                "id": "6010",
                "label": "Burnout",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A state of emotional, physical, and mental exhaustion as referenced by the mention of mini burnouts.",
                "references": [
                    "come close to it or have mini burnouts."
                ]
            },
            {
                "id": "6011",
                "label": "Work Burnout",
                "color": "#777777",
                "shape": "ellipse",
                "info": "A form of burnout specifically related to work, representing a work-related manifestation of burnout.",
                "references": [
                    "or have mini burnouts. or have mini burnouts."
                ]
            },
            {
                "id": "6012",
                "label": "Gabriel",
                "color": "#626262",
                "shape": "ellipse",
                "info": "The individual who authored the tweet.",
                "references": [
                    "should post a longer thread about this at some point should post a longer thread about this at some point"
                ]
            },
            {
                "id": "6013",
                "label": "Person",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A human being, representing the abstract category of individuals.",
                "references": [
                    "cause seems common cause seems common"
                ]
            },
            {
                "id": "6014",
                "label": "Work Burnout Experience",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A personal instance or experience of work burnout as expressed in the text.",
                "references": [
                    "wtf i feel like i can't work wtf i feel like i can't work"
                ]
            },
            {
                "id": "6015",
                "label": "Chronic Work Burnout",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A chronic state resulting from persistently pushing through burnout, as warned against in the text.",
                "references": [
                    "pushing through it for months"
                ]
            },
            {
                "id": "7004",
                "label": "XAI",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "XAI represents the organization mentioned in the tweet.",
                "references": [
                    "if xai worked"
                ]
            },
            {
                "id": "7005",
                "label": "Work Schedule",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "A concept representing the general work schedule or pattern of work hours mentioned in the tweet.",
                "references": [
                    "worked 16h per day before,"
                ]
            },
            {
                "id": "7006",
                "label": "Standard Work Hours",
                "color": "#272727",
                "shape": "ellipse",
                "info": "Standard work hours regime indicated as 16 hours per day.",
                "references": [
                    "16h per day before,"
                ]
            },
            {
                "id": "7007",
                "label": "Extended Work Hours",
                "color": "#272727",
                "shape": "ellipse",
                "info": "Extended work hours regime indicated as 96 hours per day.",
                "references": [
                    "96h per day 96h per day"
                ]
            },
            {
                "id": "7008",
                "label": "Datacenter",
                "color": "#1f1f1f",
                "shape": "ellipse",
                "info": "A high-value datacenter whose absence necessitates extended work hours.",
                "references": [
                    "lack of a half trillion dollar datacenter"
                ]
            },
            {
                "id": "7012",
                "label": "XAI Work Schedule",
                "color": "#272727",
                "shape": "ellipse",
                "info": "A specific work schedule employed by XAI, derived from the general work schedule but tailored for XAI.",
                "references": [
                    "they now need to work 96h per day to compensate"
                ]
            },
            {
                "id": "7010",
                "label": "Organization",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A type representing organizations, to which XAI belongs as a subtype.",
                "references": []
            },
            {
                "id": "7011",
                "label": "Infrastructure",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A category for essential infrastructures, under which a datacenter is classified.",
                "references": []
            },
            {
                "id": "7009",
                "label": "Schedule",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A general category representing schedules under which an organization may operate.",
                "references": []
            },
            {
                "id": "8004",
                "label": "Stack Overflow",
                "color": "#171717",
                "shape": "ellipse",
                "info": "A popular Q&A website for developers.",
                "references": [
                    "stack overflow answers for the academic publishing criteria"
                ]
            },
            {
                "id": "8005",
                "label": "Academic Publishing Criteria",
                "color": "#0f0f0f",
                "shape": "ellipse",
                "info": "Criteria required for academic publishing, used as benchmarks for visa applications.",
                "references": [
                    "academic publishing criteria to get my O1 visa haha"
                ]
            },
            {
                "id": "8006",
                "label": "O1 Visa",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A visa granted to individuals with extraordinary ability.",
                "references": [
                    "O1 visa haha. if you want to get into the US"
                ]
            },
            {
                "id": "8007",
                "label": "US",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "The United States as a country.",
                "references": [
                    "if you want to get into the US, get good lawyers"
                ]
            },
            {
                "id": "8008",
                "label": "Lawyer",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A legal professional specializing in immigration.",
                "references": [
                    "get good lawyers who understand what matters for the gov &amp; how to stand out"
                ]
            },
            {
                "id": "8022",
                "label": "Governmental Matters",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Key aspects and priorities of government policies and functions that are crucial to understand.",
                "references": [
                    "what matters for the gov &amp; how to stand out"
                ]
            },
            {
                "id": "8010",
                "label": "Website",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A digital platform that hosts content.",
                "references": []
            },
            {
                "id": "8011",
                "label": "Criteria",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A standard or requirement for evaluation.",
                "references": []
            },
            {
                "id": "8012",
                "label": "Visa",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An endorsement on a passport that allows entry into a country.",
                "references": []
            },
            {
                "id": "8013",
                "label": "Country",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A nation with its own government.",
                "references": []
            },
            {
                "id": "8014",
                "label": "Government",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "The governing body or system of a nation.",
                "references": []
            },
            {
                "id": "8015",
                "label": "Professional",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A person engaged or qualified in a specific profession.",
                "references": []
            },
            {
                "id": "8016",
                "label": "Person",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An individual human being.",
                "references": []
            },
            {
                "id": "9004",
                "label": "User",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A user in the social network.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9005",
                "label": "Like",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A like representing an expression of appreciation for content.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9006",
                "label": "Private",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "Indicates that a like is only visible to the owner. This is a subtype of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9007",
                "label": "Public",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "Indicates that a like is visible to everyone. This is a subtype of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9008",
                "label": "karpathys",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A specific user whose likes are public; a subtype of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9009",
                "label": "GenericUser",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A generic user representing users with private likes; a subtype of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9010",
                "label": "ContentCreator",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A subtype of User that creates content.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9013",
                "label": "GabrielsLike",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A like generated by Gabriel; a subtype of Like.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "id": "9012",
                "label": "PrivacySetting",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Abstract type representing privacy settings for user likes.",
                "references": []
            }
        ],
        "edges": [
            {
                "source": "5",
                "target": "6",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Facilitates",
                "info": "dropping out of high school facilitates engaging in what I love.",
                "references": [
                    "dropping out of high school made me able to work on what I love"
                ]
            },
            {
                "source": "6",
                "target": "7",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Extends Duration",
                "info": "engaging in what I love extends the productive period.",
                "references": [
                    "for 6 more years in my most productive years of life"
                ]
            },
            {
                "source": "8",
                "target": "4",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Undermines",
                "info": "the academic necessity mindset undermines the normalization of early workforce entry.",
                "references": [
                    "we must get rid of the academic necessity mindset. normalize working as soon as you can"
                ]
            },
            {
                "source": "1100",
                "target": "1101",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Recovery Process.",
                "references": [
                    "it took me ~3 years to recover fully"
                ]
            },
            {
                "source": "1100",
                "target": "1102",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Work Impairment.",
                "references": [
                    "could physically not work for 5months after it happened could physically not work for 5months after it happened"
                ]
            },
            {
                "source": "1100",
                "target": "1103",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Aspect",
                "info": "Burnout has an aspect of Stigmatizing Belief.",
                "references": [
                    "when i was 18 i thought it was something for dummies who didn't wanna work."
                ]
            },
            {
                "source": "2010",
                "target": "2011",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Dropped Out Of",
                "info": "Gabriel dropped out of high school in Sweden.",
                "references": [
                    "5 yrs ago, i dropped out of high school in sweden"
                ]
            },
            {
                "source": "2010",
                "target": "2012",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Joined",
                "info": "Gabriel joined a startup.",
                "references": [
                    "to join a startup"
                ]
            },
            {
                "source": "2010",
                "target": "2014",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Joined",
                "info": "Gabriel joined @OpenAI.",
                "references": [
                    "today i'm joining @OpenAI"
                ]
            },
            {
                "source": "2010",
                "target": "2013",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Experience",
                "info": "Gabriel has close to zero experience as an engineer.",
                "references": [
                    "with close to zero experience as an engineer. today i'm joining @OpenAI as a research scientist"
                ]
            },
            {
                "source": "2010",
                "target": "2015",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Works As",
                "info": "Gabriel works as a research scientist at @OpenAI.",
                "references": [
                    "as a research scientist"
                ]
            },
            {
                "source": "2010",
                "target": "2016",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Builds",
                "info": "Gabriel is building AGI.",
                "references": [
                    "to build agi with sora"
                ]
            },
            {
                "source": "2010",
                "target": "2017",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Gabriel collaborates with Sora on building AGI.",
                "references": [
                    "with sora"
                ]
            },
            {
                "source": "3010",
                "target": "3009",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Contrasts With",
                "info": "Structured Learning Approach contrasts with Unstructured Teaching.",
                "references": [
                    "but now you can but now you can\u2026"
                ]
            },
            {
                "source": "3007",
                "target": "3008",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Requires Prerequisite",
                "info": "Diffusion Models requires Mathematics of Diffusion Models.",
                "references": [
                    "get into the math"
                ]
            },
            {
                "source": "3004",
                "target": "3005",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Contrasts With",
                "info": "Top Down Learning contrasts with Bottom Up Learning with rapid versus prolonged learning as indicated in the tweet.",
                "references": [
                    "it takes 3 days to learn diffusion models top down, 6 years before you can learn it bottom up (academia)"
                ]
            },
            {
                "source": "3005",
                "target": "3006",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Occurs In",
                "info": "Bottom Up Learning takes place in Academia as per the tweet.",
                "references": [
                    "bottom up (academia)"
                ]
            },
            {
                "source": "3004",
                "target": "3007",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Applies To",
                "info": "Top Down Learning is applied to learn Diffusion Models quickly, as stated by Gabriel.",
                "references": [
                    "learn diffusion models top down"
                ]
            },
            {
                "source": "4017",
                "target": "4018",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Provides Foundational Knowledge",
                "info": "Uni Instance provides foundational knowledge to the Foundational Knowledge Instance.",
                "references": [
                    "universities don't have monopoly on foundational knowledge anymore,"
                ]
            },
            {
                "source": "4019",
                "target": "4020",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Math Basis",
                "info": "Diffusion Model Instance is based on the Math Intuition Instance.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ]
            },
            {
                "source": "5005",
                "target": "5006",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Severity Influences Duration",
                "info": "The severity of burnout influences the duration of stress allergy.",
                "references": [
                    "the worse the burnout the longer you'll be"
                ]
            },
            {
                "source": "5007",
                "target": "5006",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Co-occurs With",
                "info": "Stress co-occurs with Stress Allergy.",
                "references": [
                    "to stress afterwards which is horrible"
                ]
            },
            {
                "source": "6012",
                "target": "6014",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Gives Advice About",
                "info": "Gabriel gives advice about not persisting through burnout experience.",
                "references": [
                    "DON'T PUSH THROUGH IT DON'T PUSH THROUGH IT"
                ]
            },
            {
                "source": "6014",
                "target": "6015",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Results In",
                "info": "Persisting through work burnout experience results in chronic work burnout.",
                "references": [
                    "pushing through it for months"
                ]
            },
            {
                "source": "7004",
                "target": "7012",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Employs Work Schedule",
                "info": "Gabriel indicates that XAI employs a work schedule.",
                "references": [
                    "if xai worked 16h per day before, they now need to work"
                ]
            },
            {
                "source": "7012",
                "target": "7008",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Compensates For",
                "info": "Gabriel indicates that the work schedule compensates for the missing datacenter.",
                "references": [
                    "to compensate for the lack of a half trillion dollar datacenter"
                ]
            },
            {
                "source": "7006",
                "target": "7007",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Changed Work Hours From To",
                "info": "Gabriel highlights the change from standard to extended work hours.",
                "references": [
                    "16h per day before, they now need to work 96h per day"
                ]
            },
            {
                "source": "8005",
                "target": "8006",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Leads To",
                "info": "Academic Publishing Criteria leads to obtaining an O1 Visa.",
                "references": [
                    "academic publishing criteria to get my O1 visa haha"
                ]
            },
            {
                "source": "8007",
                "target": "8008",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Requires",
                "info": "US requires good lawyers for immigration.",
                "references": [
                    "if you want to get into the US, get good lawyers"
                ]
            },
            {
                "source": "8008",
                "target": "8022",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Understands",
                "info": "Lawyer understands governmental matters.",
                "references": [
                    "get good lawyers who understand what matters for the gov &amp; how to stand out"
                ]
            },
            {
                "source": "9008",
                "target": "9007",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Like Privacy Setting",
                "info": "karpathys has a public like privacy setting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9009",
                "target": "9006",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Has Like Privacy Setting",
                "info": "GenericUser has a private like privacy setting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "2010",
                "target": "8004",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Utilizes",
                "info": "Gabriel utilizes Stack Overflow as an information source.",
                "references": [
                    "i used stack overflow answers for the academic publishing criteria"
                ]
            },
            {
                "source": "2010",
                "target": "8005",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Adheres To",
                "info": "Gabriel adheres to Academic Publishing Criteria.",
                "references": [
                    "i used stack overflow answers for the academic publishing criteria to get my O1 visa haha"
                ]
            },
            {
                "source": "2010",
                "target": "9013",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "User Generates Like",
                "info": "Gabriel generates a like (GabrielsLike).",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "2010",
                "target": "4022",
                "type": "other",
                "color": "#777777",
                "arrowhead": "diamond",
                "label": "Learns With",
                "info": "Gabriel Student learns with Claude Instance as part of his learning journey.",
                "references": [
                    "with with claude: with with claude:"
                ]
            },
            {
                "source": "9",
                "target": "4",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "normalize working as soon as you can is a type of Work Principle.",
                "references": [
                    "normalize working as soon as you can normalize working as soon as you can"
                ]
            },
            {
                "source": "10",
                "target": "5",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "dropping out of high school is a type of Educational Decision.",
                "references": [
                    "dropping out of high school dropping out of high school"
                ]
            },
            {
                "source": "11",
                "target": "6",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "what I love is a type of Pursuing Passion.",
                "references": [
                    "what I love what I love"
                ]
            },
            {
                "source": "12",
                "target": "7",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "most productive years of life is a type of Time Period.",
                "references": [
                    "most productive years of life"
                ]
            },
            {
                "source": "13",
                "target": "8",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "academic necessity mindset is a type of Mindset.",
                "references": [
                    "we must get rid of the academic necessity mindset. we must get rid of the academic necessity mindset."
                ]
            },
            {
                "source": "1104",
                "target": "1100",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Burnout is classified as a Burnout Experience.",
                "references": [
                    "burning out is real btw. burning out is real btw."
                ]
            },
            {
                "source": "1105",
                "target": "1101",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Recovery Process is classified as a Burnout Aspect.",
                "references": [
                    "it took me ~3 years to recover fully"
                ]
            },
            {
                "source": "1105",
                "target": "1102",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Work Impairment is classified as a Burnout Aspect.",
                "references": [
                    "could physically not work for 5months after it happened could physically not work for 5months after it happened"
                ]
            },
            {
                "source": "1105",
                "target": "1103",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Stigmatizing Belief is classified as a Burnout Aspect.",
                "references": [
                    "when i was 18 i thought it was something for dummies who didn't wanna work."
                ]
            },
            {
                "source": "2004",
                "target": "2010",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel is an instance of Person inferred from the tweet.",
                "references": [
                    "today i'm joining @OpenAI as a research scientist"
                ]
            },
            {
                "source": "2005",
                "target": "2011",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "High School in Sweden is an instance of Educational Institution.",
                "references": [
                    "5 yrs ago, i dropped out of high school in sweden"
                ]
            },
            {
                "source": "2006",
                "target": "2012",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Startup is an instance of Organization.",
                "references": [
                    "to join a startup"
                ]
            },
            {
                "source": "2007",
                "target": "2013",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Engineer is an instance of Job Role.",
                "references": [
                    "with close to zero experience as an engineer. today i'm joining @OpenAI as a research scientist"
                ]
            },
            {
                "source": "2006",
                "target": "2014",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "OpenAI is an instance of Organization.",
                "references": [
                    "today i'm joining @OpenAI"
                ]
            },
            {
                "source": "2007",
                "target": "2015",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Research Scientist is an instance of Job Role.",
                "references": [
                    "as a research scientist"
                ]
            },
            {
                "source": "2008",
                "target": "2016",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "AGI is an instance of Technology.",
                "references": [
                    "to build agi with sora"
                ]
            },
            {
                "source": "2006",
                "target": "2017",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Sora is an instance of Organization.",
                "references": [
                    "with sora"
                ]
            },
            {
                "source": "3020",
                "target": "3004",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Top Down Learning is a subtype of Learning Approach.",
                "references": [
                    "it takes 3 days to learn diffusion models top down"
                ]
            },
            {
                "source": "3020",
                "target": "3005",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Bottom Up Learning is a subtype of Learning Approach.",
                "references": [
                    "6 years before you can learn it bottom up (academia)"
                ]
            },
            {
                "source": "3020",
                "target": "3010",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Structured Learning Approach is a subtype of Learning Approach.",
                "references": [
                    "but now you can but now you can\u2026"
                ]
            },
            {
                "source": "3020",
                "target": "3009",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Unstructured Teaching is a subtype of Learning Approach.",
                "references": [
                    "unscalable way to teach students since it's so unstructured"
                ]
            },
            {
                "source": "3021",
                "target": "3006",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Academia is a subtype of Educational Environment.",
                "references": [
                    "bottom up (academia)"
                ]
            },
            {
                "source": "3023",
                "target": "3007",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Diffusion Models is a subtype of Diffusion Concept.",
                "references": [
                    "learn diffusion models"
                ]
            },
            {
                "source": "3022",
                "target": "3008",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Mathematics of Diffusion Models is a subtype of Prerequisite Knowledge.",
                "references": [
                    "get into the math"
                ]
            },
            {
                "source": "4010",
                "target": "4017",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Uni Instance is a subtype of the abstract University.",
                "references": [
                    "universities don't have monopoly on foundational knowledge anymore,"
                ]
            },
            {
                "source": "4011",
                "target": "4018",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Foundational Knowledge Instance is a subtype of the abstract Foundational Knowledge.",
                "references": [
                    "monopoly on foundational knowledge anymore,"
                ]
            },
            {
                "source": "4012",
                "target": "4019",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Diffusion Model Instance is a subtype of the abstract Diffusion Model.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ]
            },
            {
                "source": "4013",
                "target": "4020",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Math Intuition Instance is a subtype of the abstract Math Intuition.",
                "references": [
                    "all the math intuitions behind diffusion models"
                ]
            },
            {
                "source": "4014",
                "target": "4021",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "High School Dropout Instance is a subtype of the abstract High School Dropout.",
                "references": [
                    "as a high school dropout as a high school dropout"
                ]
            },
            {
                "source": "4015",
                "target": "4022",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Claude Instance is a subtype of the abstract Claude.",
                "references": [
                    "with with claude: with with claude:"
                ]
            },
            {
                "source": "5004",
                "target": "5005",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Burnout is a type of Condition.",
                "references": [
                    "the worse the burnout"
                ]
            },
            {
                "source": "5004",
                "target": "5006",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Stress Allergy is a type of Condition.",
                "references": [
                    "you basically become allergic to stress afterwards which is horrible"
                ]
            },
            {
                "source": "5004",
                "target": "5007",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Stress is a type of Condition.",
                "references": [
                    "to stress afterwards"
                ]
            },
            {
                "source": "6010",
                "target": "6011",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Work Burnout is classified as a subtype of Burnout.",
                "references": [
                    "or have mini burnouts. or have mini burnouts."
                ]
            },
            {
                "source": "6011",
                "target": "6014",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Work Burnout Experience is classified as a subtype of Work Burnout.",
                "references": [
                    "wtf i feel like i can't work wtf i feel like i can't work"
                ]
            },
            {
                "source": "6014",
                "target": "6015",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Chronic Work Burnout is classified as a subtype of Work Burnout Experience.",
                "references": [
                    "pushing through it for months"
                ]
            },
            {
                "source": "6013",
                "target": "6012",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel is classified as a specific instance of Person.",
                "references": [
                    "should post a longer thread about this at some point should post a longer thread about this at some point"
                ]
            },
            {
                "source": "7010",
                "target": "7004",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that XAI is a type of Organization.",
                "references": [
                    "if xai worked"
                ]
            },
            {
                "source": "7005",
                "target": "7006",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that Standard Work Hours is a subtype of Work Schedule.",
                "references": [
                    "16h per day before,"
                ]
            },
            {
                "source": "7005",
                "target": "7007",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that Extended Work Hours is a subtype of Work Schedule.",
                "references": [
                    "96h per day 96h per day"
                ]
            },
            {
                "source": "7009",
                "target": "7005",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that Work Schedule is a subtype of Schedule.",
                "references": [
                    "worked 16h per day before, they now need"
                ]
            },
            {
                "source": "7005",
                "target": "7012",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that XAI's Work Schedule is a subtype of Work Schedule.",
                "references": [
                    "they now need to work 96h per day to compensate"
                ]
            },
            {
                "source": "7011",
                "target": "7008",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel indicates that Datacenter is a subtype of Infrastructure.",
                "references": [
                    "lack of a half trillion dollar datacenter"
                ]
            },
            {
                "source": "8010",
                "target": "8004",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Stack Overflow is a type of Website.",
                "references": [
                    "stack overflow answers for the academic publishing criteria"
                ]
            },
            {
                "source": "8011",
                "target": "8005",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Academic Publishing Criteria is a type of Criteria.",
                "references": [
                    "academic publishing criteria to get my O1 visa haha"
                ]
            },
            {
                "source": "8012",
                "target": "8006",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "O1 Visa is a type of Visa.",
                "references": [
                    "O1 visa haha. if you want to get into the US"
                ]
            },
            {
                "source": "8013",
                "target": "8007",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "US is a type of Country.",
                "references": [
                    "if you want to get into the US, get good lawyers"
                ]
            },
            {
                "source": "8015",
                "target": "8008",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Lawyer is a type of Professional.",
                "references": [
                    "get good lawyers who understand what matters for the gov &amp; how to stand out"
                ]
            },
            {
                "source": "8014",
                "target": "8022",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Governmental Matters is a type of Government.",
                "references": [
                    "what matters for the gov &amp; how to stand out"
                ]
            },
            {
                "source": "9012",
                "target": "9006",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Private is a type of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9012",
                "target": "9007",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Public is a type of PrivacySetting.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9004",
                "target": "9008",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "karpathys is a type of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9004",
                "target": "9009",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "GenericUser is a type of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9004",
                "target": "9010",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "ContentCreator is a type of User.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "9005",
                "target": "9013",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "GabrielsLike is a type of Like.",
                "references": [
                    "everyones likes are private except karpathys"
                ]
            },
            {
                "source": "4021",
                "target": "2010",
                "type": "subtype",
                "color": "#777777",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Gabriel Student is a more specific instance of High School Dropout Instance, integrating his identity as a high school dropout into the broader experience.",
                "references": [
                    "as a high school dropout as a high school dropout"
                ]
            }
        ],
        "categories": [
            {
                "name": "Entity",
                "color": "#cccccc"
            }
        ]
    },

    example7: {
        "nodes": [
            {
                "id": "4",
                "label": "Deep Learning",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A method that employs layered neural networks and backpropagation, representing a new paradigm in software development.",
                "references": [
                    "I learned about deep learning about the same time everybody else did, and maybe slightly before that."
                ],
                "Type": "Algorithmic Approach"
            },
            {
                "id": "5",
                "label": "ImageNet Contest",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A 2012 contest that spurred advancements in deep learning research by challenging researchers to innovate.",
                "references": [
                    "several pockets of researchers were simultaneously trying to submit for ImageNet\u2014that big contest in 2012."
                ],
                "Type": "Research Event"
            },
            {
                "id": "6",
                "label": "GPU",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A Graphics Processing Unit critical for accelerating deep learning computations.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ],
                "Type": "Hardware & Hardware Component"
            },
            {
                "id": "8",
                "label": "Deep Neural Network",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Multi-layered neural network architecture that enable scalable and effective deep learning.",
                "references": [
                    "to learn how to program it for deep neural nets."
                ],
                "Type": "Neural Network"
            },
            {
                "id": "9",
                "label": "Backpropagation",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The training algorithm that iteratively adjusts neural network weights via error gradient descent.",
                "references": [
                    "you could backprop and learn it."
                ],
                "Type": "Algorithmic Approach"
            },
            {
                "id": "10",
                "label": "Universal Function Approximator",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The theoretical concept that sufficiently large deep models can approximate any function.",
                "references": [
                    "Imagine having a universal function approximator of any dimensionality."
                ],
                "Type": "Technological Concept"
            },
            {
                "id": "11",
                "label": "Software 2.0",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A paradigm shift in software development driven by data and deep learning approaches.",
                "references": [
                    "Since then, Andrej Karpathy has called it Software 2.0, but we recognized it from the start as a new way of doing software, even suggesting that maybe we needed to change computing altogether."
                ],
                "Type": "Software Paradigm"
            },
            {
                "id": "12",
                "label": "Accelerated Computing",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The transformation of computing performance through the integration of GPUs and deep learning.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ],
                "Type": "Computing Architecture & Organizational Practice"
            },
            {
                "id": "13",
                "label": "IBM System/360",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A pioneering computing system whose legacy influenced modern computing architectures.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ],
                "Type": "Computing Architecture"
            },
            {
                "id": "14",
                "label": "Quantum Computing",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A visionary concept hinting at future breakthroughs in computational paradigms.",
                "references": [
                    "It was almost as if we discovered \u201cquantum computing before quantum computing,\u201d if you will."
                ],
                "Type": "Computing Architecture"
            },
            {
                "id": "1005",
                "label": "CNN",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Convolutional Neural Network, referenced as an earlier dominant vision model and a precursor in model architectures.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ],
                "Type": "Vision Transformer"
            },
            {
                "id": "1007",
                "label": "Vision Transformer",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A transformer model adapted for visual tasks.",
                "references": [
                    "You can create a vision transformer, an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Transformer"
            },
            {
                "id": "1008",
                "label": "Audio Transformer",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A transformer model adapted for processing audio signals.",
                "references": [
                    "an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Transformer"
            },
            {
                "id": "1009",
                "label": "Text Transformer",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A transformer model specialized for textual data.",
                "references": [
                    "a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Transformer"
            },
            {
                "id": "1010",
                "label": "Multimodality",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The integration of multiple data modalities to improve model performance.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ],
                "Type": "Multimodality Mechanism"
            },
            {
                "id": "1011",
                "label": "Vision Neural Network",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A neural network specialized for processing visual information.",
                "references": [
                    "if you\u2019re training a neural network for vision and you\u2019ve only seen horses but never zebras,"
                ],
                "Type": "Model Architecture & Neural Network"
            },
            {
                "id": "1012",
                "label": "Model Architecture",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The overall design or structure that an AI model is built on.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ],
                "Type": "Model Architecture Base & Scaling AI"
            },
            {
                "id": "1013",
                "label": "Sensor Fusion",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The integration of sensory data from various sensors to improve perception.",
                "references": [
                    "We use this idea for cameras, radars, and lidars\u2014combining multiple sensor modalities so that perception encompasses the strengths of all of them."
                ],
                "Type": "Sensor Integration"
            },
            {
                "id": "1015",
                "label": "Integrated Multimodal Architecture",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A form of model architecture that seamlessly integrates multiple modalities for enhanced performance.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ],
                "Type": "Model Architecture Base & Multimodality Mechanism"
            },
            {
                "id": "1016",
                "label": "NextGen Model Architecture",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A forward-looking model design that integrates multimodality for advanced performance.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ],
                "Type": "Model Architecture Base"
            },
            {
                "id": "1017",
                "label": "Vision Deployment System",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A specialized system for deploying neural network-based vision systems.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ],
                "Type": "Deployment System Base & System"
            },
            {
                "id": "2010",
                "label": "World Model",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A representation of the external world derived from language.",
                "references": [
                    "derived this world model from language and can apply it to a very wide context."
                ],
                "Type": "Cognitive Construct"
            },
            {
                "id": "2011",
                "label": "Physics",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Scientific concept representing physical laws and phenomena derived from textual descriptions.",
                "references": [
                    "Physics has been described in words\u2014Newton\u2019s laws, for instance."
                ],
                "Type": "Physical Concept"
            },
            {
                "id": "2012",
                "label": "Reinforcement Learning Physical Feedback",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A training methodology where a robot receives simulated physical feedback via a physics simulation platform.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ],
                "Type": "Training Methodology"
            },
            {
                "id": "2013",
                "label": "Omniverse",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A physics-based simulation platform enabling digital twinning of robots that obey the laws of physics.",
                "references": [
                    "We created a system called Omniverse that obeys the laws of physics so we can give it a digital twin of an embodied robot."
                ],
                "Type": "Physics Simulation Platform"
            },
            {
                "id": "2014",
                "label": "Embodied Robot",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A robot integrated with a digital twin for simulation-based physical grounding.",
                "references": [
                    "digital twin of an embodied robot.  \nThat embodied language model would then receive"
                ],
                "Type": "Robot"
            },
            {
                "id": "2015",
                "label": "Ethical Truths",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Fundamental ethical principles for grounding AI in ethical reasoning.",
                "references": [
                    "grounded in ethical truths\u2014that\u2019s part of alignment."
                ],
                "Type": "Ethical Principle"
            },
            {
                "id": "2016",
                "label": "Alignment",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The process of ensuring AI systems operate according to ethical standards for safer outcomes.",
                "references": [
                    "that\u2019s part of alignment.  \nBoth are essential for creating safer chatbots."
                ],
                "Type": "Ethical Principle"
            },
            {
                "id": "3005",
                "label": "Symbolic Reasoning",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A method involving the application of explicit rules to supplement learned models, drawing from cultural principles and experiential knowledge.",
                "references": [
                    "I think that this kind of symbolic reasoning could augment these exhaustively learned models.  \nSo while I can\u2019t say for sure what the limits will be, it seems intuitive that we\u2019ll always need to supplement our learned models with aspects of intelligence derived from rules and experience."
                ],
                "Type": "AI Improvement Strategy"
            },
            {
                "id": "3007",
                "label": "Compute Scaling",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Incremental enhancements in computational resources and efficiency to improve AI performance.",
                "references": [
                    "evolving them slightly and scaling compute\u2014or will we need radical breakthroughs"
                ],
                "Type": "Scaling AI"
            },
            {
                "id": "3008",
                "label": "Radical Breakthroughs",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Innovative leaps in AI technology required when incremental improvements become insufficient due to diminishing returns.",
                "references": [
                    "or will we need radical breakthroughs and see diminishing returns soon"
                ],
                "Type": "Scaling AI"
            },
            {
                "id": "3009",
                "label": "Learned Models",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "AI models developed primarily through data-driven learning approaches.",
                "references": [
                    "augment these exhaustively learned models"
                ],
                "Type": "AI Model Type"
            },
            {
                "id": "4010",
                "label": "Human Intellect",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Represents the cognitive capacity of humans that can be augmented to achieve higher levels of understanding and performance.",
                "references": [
                    "one thing we're really excited about at Sana is the possibility to augment human intellect"
                ],
                "Type": "Cognitive Ability & Intelligence"
            },
            {
                "id": "4011",
                "label": "Augmentation",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The process of enhancing human capabilities through technological or methodological improvements.",
                "references": [
                    "If we could provide researchers with tools to run thousands of parallel experiments and be augmented by that rather than replaced"
                ],
                "Type": "Augmentation Process"
            },
            {
                "id": "4012",
                "label": "Superintelligence",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A state where certain systems or groups exhibit intelligence that is orders of magnitude greater than typical human levels.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence"
                ],
                "Type": "Cognitive Ability & Intelligence"
            },
            {
                "id": "4013",
                "label": "Democratized Intelligence",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A phenomenon where the generation and application of intelligence becomes accessible to a broad population.",
                "references": [
                    "I believe we\u2019re now democratizing intelligence\u2014the production of intelligence"
                ],
                "Type": "Cognitive Ability & Intelligence"
            },
            {
                "id": "4014",
                "label": "Human Language Programming",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The practice of using natural language constructs to write and instruct computer programs.",
                "references": [
                    "With ChatGPT and similar tools, the programming language of choice is human language"
                ],
                "Type": "Programming Paradigm"
            },
            {
                "id": "4015",
                "label": "Digital Divide",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The gap between populations in terms of access to and use of digital technology.",
                "references": [
                    "I\u2019m hopeful it will bridge the digital divide"
                ],
                "Type": "Access Gap"
            },
            {
                "id": "4016",
                "label": "The Computer",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Refers to the advanced computational machinery that has become broadly accessible, revolutionizing productivity and creativity.",
                "references": [
                    "This incredible, light-speed machine we call the computer is now accessible to everyone"
                ],
                "Type": "Computing Device"
            },
            {
                "id": "5004",
                "label": "AI-Driven Chip Design Process",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A specific chip design process at NVIDIA that leverages AI to explore and optimize complex chip designs. It is a subtype of the abstract Chip Design Process.",
                "references": [
                    "Well, our current generation of chips is so large and complex that all the employees in our company can\u2019t possibly design them from scratch.  \nSo we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities.  \nSometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount.  \nWe set these kinds of judgments and then let the AI explore all the corner cases and design permutations for us.  \nIt comes back with a design that no human could have done alone.  \nThat\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before.  \nThe complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe.  \nOnly an AI could figure out the best optimization for that."
                ],
                "Type": "Chip Design Process"
            },
            {
                "id": "5005",
                "label": "Design Space Exploration",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The phase where AI experiments with hundreds of thousands of design permutations to identify optimal trade-offs. It is a subtype of the Chip Design Component.",
                "references": [
                    "So we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities."
                ],
                "Type": "Chip Design Component"
            },
            {
                "id": "5006",
                "label": "Optimization Trade-Offs",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The selection process for choosing which design parameters to optimize based on priorities. It is a subtype of the Chip Design Component.",
                "references": [
                    "Sometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount."
                ],
                "Type": "Chip Design Component"
            },
            {
                "id": "5007",
                "label": "Hopper Generation Chip",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A chip design product that exemplifies innovative products produced by AI methodologies at NVIDIA. It is a subtype of Chip Product.",
                "references": [
                    "That\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before."
                ],
                "Type": "Chip Product"
            },
            {
                "id": "5008",
                "label": "Module Interconnection Complexity",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The intricate challenge of interconnecting a vast number of modules on a chip. It is a subtype of the Chip Design Challenge.",
                "references": [
                    "The complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe."
                ],
                "Type": "Chip Design Challenge"
            },
            {
                "id": "5026",
                "label": "Advanced Compute Acceleration",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The process of using AI to accelerate the development of advanced compute architectures at NVIDIA. It is a subtype of the Chip Design Component.",
                "references": [
                    "I think that's really fascinating as well\u2014how you\u2019re accelerating compute at NVIDIA by using AI to develop more advanced compute."
                ],
                "Type": "Chip Design Component"
            },
            {
                "id": "6005",
                "label": "Defensible Business",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A business structured to withstand competitive pressures in an AI-driven world.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ],
                "Type": "Business"
            },
            {
                "id": "6006",
                "label": "Domain Expertise",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "In-depth knowledge and expertise in a specific domain that can provide competitive advantage.",
                "references": [
                    "Last time we spoke, we discussed the value of deep domain expertise and integrating into workflows."
                ],
                "Type": "Competitive Advantage Factor"
            },
            {
                "id": "6007",
                "label": "Customer Challenges",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The complex problems and needs faced by customers that remain valuable over time.",
                "references": [
                    "Well, I don\u2019t think there\u2019s any evidence that understanding a domain or a specific set of customer challenges ever loses its value.  \nOur technology\u2014and the world\u2019s pool of educated people\u2014is growing.  \nToday\u2019s new college graduates can tackle problems that entire companies used to solve.  \nAll evidence suggests that deeply understanding your customer\u2019s challenges remains extremely valuable."
                ],
                "Type": "Competitive Advantage Factor"
            },
            {
                "id": "6008",
                "label": "Application Companies",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Businesses operating at the application layer facing commoditization challenges.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ],
                "Type": "Business"
            },
            {
                "id": "6012",
                "label": "Commoditization",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The process whereby products or services become undifferentiated and subject to intense price competition.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ],
                "Type": "Business Concept"
            },
            {
                "id": "7011",
                "label": "Enhancing Product Development",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Leveraging AI to fundamentally transform product design and development processes, as exemplified by innovations in chip manufacturing.",
                "references": [
                    "1. **Enhancing Product Development:** What can AI do to revolutionize the work we do in building our products?  \n   \u2014 For example, the way we design chips has completely changed."
                ],
                "Type": "AI Application Layer"
            },
            {
                "id": "7012",
                "label": "Enabling New Product Capabilities",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Utilizing AI to empower products with novel features and functionalities, transforming the way products perform and interact.",
                "references": [
                    "2. **Enabling New Product Capabilities:** What can AI enable us to do so that our products themselves become different?  \n   \u2014 In the past, building a graphics card meant designing programmable shaders and compilers. Now, you can\u2019t ship a GeForce GPU by itself because there\u2019s a \u201csupercomputer\u201d in the background learning to predict missing pixels through denoising and inference\u2014imagine inferring 8 to 16 pixels for every pixel rendered, like completing a jigsaw puzzle.  \n   \u2014 We update the algorithms continuously by downloading improvements as they become available, which not only revolutionizes the design of our GPUs but also how they produce images\u2014leading to processors that are far more energy efficient than Moore\u2019s law would predict."
                ],
                "Type": "AI Application Layer"
            },
            {
                "id": "7013",
                "label": "Augmenting the Company",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Integrating AI systems within the organization to enhance decision-making, operational efficiency, and strategic insight.",
                "references": [
                    "3. **Augmenting the Company:** Turning the entire company into an AI-augmented organization.  \n   \u2014 This means every employee is empowered by a system that continuously analyzes information, connects the dots, and even predicts changes in the market or supply chain\u2014signals that would be impossible for any one person to see."
                ],
                "Type": "AI Application Layer"
            },
            {
                "id": "8004",
                "label": "Joel Hellermark",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Joel Hellermark is a Swedish entrepreneur and the founder and CEO of Sana, an artificial intelligence (AI) company that focuses on organizing and personalizing knowledge within organizations.",
                "references": [
                    "**Joel:"
                ],
                "Type": "Speaker"
            },
            {
                "id": "8005",
                "label": "Jensen Huang",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Jensen Huang is a Taiwanese-American entrepreneur and the co-founder, president, and CEO of NVIDIA Corporation.",
                "references": [
                    "**Jensen:**"
                ],
                "Type": "Speaker"
            },
            {
                "id": "8006",
                "label": "Self-driving Cars",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Vehicles that use AI for autonomous driving with an emphasis on safety protocols.",
                "references": [
                    "In self-driving cars, for example, we dedicate as many resources to making the AI and the car safe as we do to making the car drive."
                ],
                "Type": "Technology"
            },
            {
                "id": "8007",
                "label": "Prompt Engineering",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "An emerging design approach to interfacing with large language models.",
                "references": [
                    "Take prompt engineering, for example\u2014it\u2019s already coming to life."
                ],
                "Type": "Technology"
            },
            {
                "id": "8008",
                "label": "Large Language Models",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Advanced language models evolving with complementary safety and integration technologies.",
                "references": [
                    "I think large language models will have to do the same\u2014incorporating technologies for building guardrails, alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed), and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ],
                "Type": "Technology"
            },
            {
                "id": "8009",
                "label": "Reinforcement Learning Human Feedback",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A technique where human feedback is used to refine AI behavior for safety and alignment.",
                "references": [
                    "alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed)"
                ],
                "Type": "Technology"
            },
            {
                "id": "8010",
                "label": "Vector Databases",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Databases optimized for storing vector representations to help reduce AI hallucinations.",
                "references": [
                    "and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ],
                "Type": "Technology"
            },
            {
                "id": "8011",
                "label": "Generative Adversarial Models",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A class of machine learning frameworks used for generative tasks.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ],
                "Type": "Technology"
            },
            {
                "id": "8012",
                "label": "Variational Autoencoders",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A type of autoencoder that learns a probability distribution over its inputs to generate new data.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ],
                "Type": "Technology"
            },
            {
                "id": "8013",
                "label": "Diffusion Models",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Models that generate data by reversing a diffusion process and gradually refining random noise.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ],
                "Type": "Technology"
            },
            {
                "id": "8014",
                "label": "Climate Research",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Research focused on understanding and addressing the challenges of climate change.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ],
                "Type": "Industry"
            },
            {
                "id": "8015",
                "label": "Digital Biology",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "A field that combines digital technologies with biological research for innovative solutions.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ],
                "Type": "Industry"
            },
            {
                "id": "8016",
                "label": "Web Design",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The practice of designing websites and digital interfaces that has evolved into an industry.",
                "references": [
                    "entire professions like web design"
                ],
                "Type": "Industry"
            },
            {
                "id": "8017",
                "label": "Programmatic Ads",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Automated advertising practices that have grown into a substantial industry.",
                "references": [
                    "entire professions like web design or programmatic ads\u2014jobs that didn\u2019t exist when I finished school 40 years ago?"
                ],
                "Type": "Industry"
            },
            {
                "id": "8028",
                "label": "Atlaz",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A company that turn unstructred data into Knowledge Graphs enabling enterprise GenAI adoption in regulated industries and high quality synthetic data generation.",
                "references": [],
                "Type": "Company"
            },
            {
                "id": "9004",
                "label": "Attracting Amazing People",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The process by which the company attracts exceptional talent by engaging with challenges that push the limits of conventional computing.",
                "references": [
                    "First, I wanted to create a company that naturally attracts amazing people because we\u2019re solving computing problems that are barely possible."
                ],
                "Type": "Company Objective"
            },
            {
                "id": "9005",
                "label": "Ambitious Computing Challenges",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "The pursuit of addressing computing problems that are nearly impossible for conventional systems.",
                "references": [
                    "If a problem could be solved by normal computers, we wouldn\u2019t bother with it."
                ],
                "Type": "Technical Ambition"
            },
            {
                "id": "9006",
                "label": "Flat Organization",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "An organizational structure with minimal hierarchy that speeds information flow and enhances empowerment.",
                "references": [
                    "To empower people, you want a flat organization rather than a pyramid (like the old military or the Roman Empire)."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "9007",
                "label": "Agility",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The ability of the organization to rapidly adapt and respond, a direct outcome of its flat structure and open information flow.",
                "references": [
                    "Because the company is so flat and everyone is empowered with information, we\u2019re extremely agile."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "9009",
                "label": "Direct Communication",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A leadership approach characterized by open, unmediated dialogue without private coaching sessions.",
                "references": [
                    "I don\u2019t take anyone aside or schedule one-on-one coaching sessions.  \nIf something\u2019s not right, I speak up."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "9010",
                "label": "Transparent Leadership",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A leadership style where decision processes and rationales are openly shared, fostering clarity and empowerment.",
                "references": [
                    "I spend a lot of time explaining my thought process in meetings\u2014reasoning through decisions, comparing and contrasting ideas\u2014which empowers employees because they see how leaders think."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "9011",
                "label": "Unified Collaboration",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "An organizational practice emphasizing collective teamwork in a shared physical space.",
                "references": [
                    "we\u2019re all sitting together\u2014it\u2019s like having one big office."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10004",
                "label": "Strategy",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Strategy isn\u2019t just words\u2014it\u2019s action.",
                "references": [
                    "First of all, strategy isn\u2019t just words\u2014it\u2019s action."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10005",
                "label": "Continuous Planning",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "An approach that emphasizes planning continuously in a dynamic environment.",
                "references": [
                    "Second, we don\u2019t have a periodic planning system.  \nThe world is a living, breathing thing, so we plan continuously.  \nThere\u2019s no fixed five-year plan, no one-year plan\u2014just what we\u2019re doing right now."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10006",
                "label": "First Principles Reasoning",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A method of reasoning that starts from foundational assumptions.",
                "references": [
                    "Almost everything the company pursues is reasoned through first principles."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10007",
                "label": "Good Leadership",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The act of creating an environment that empowers others to do their life\u2019s work.",
                "references": [
                    "That\u2019s the mission of leaders\u2014to create an environment that empowers others to do their life\u2019s work."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10008",
                "label": "Commodity Work",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Routine or commoditized tasks that detract from strategic initiatives.",
                "references": [
                    "The most important is to prevent people from having to do commodity work."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "10009",
                "label": "Transparency",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "The practice of openness that empowers and connects people.",
                "references": [
                    "Additionally, empowerment comes from transparency.  \nWhile many companies are very siloed, I encourage a culture of openness."
                ],
                "Type": "Organizational Practice"
            },
            {
                "id": "11004",
                "label": "Leadership Hierarchy",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "Represents the concept of a strict leadership hierarchy as emphasized in traditional playbooks.",
                "references": [
                    "Exactly. That\u2019s one thing I find intriguing because many traditional playbooks emphasize a strict leadership hierarchy."
                ],
                "Type": "Organizational Concept"
            },
            {
                "id": "11005",
                "label": "Individual Contributor",
                "color": "#1d1d22",
                "shape": "ellipse",
                "info": "Denotes a person whose expertise is critical but who may be excluded from key decision-making forums.",
                "references": [
                    "I\u2019ve always struggled with excluding the best individual contributors from key meetings."
                ],
                "Type": "Human Role"
            },
            {
                "id": "11006",
                "label": "Ground Truth",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Refers to the accurate, firsthand account of issues based on direct experience.",
                "references": [
                    "They should be there because they have the ground truth\u2014the firsthand experience with the problems."
                ],
                "Type": "Information"
            },
            {
                "id": "11007",
                "label": "Top Five Things",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A type of email update detailing an individual's top five observations, accomplishments, or personal news.",
                "references": [
                    "If you send an email called \u201cTop Five Things\u201d detailing your top five observations, accomplishments, or even personal news (like visiting a great restaurant or having a baby), I\u2019ll read it.  \nI end up reading about a hundred of these every morning."
                ],
                "Type": "Communication"
            },
            {
                "id": "11008",
                "label": "Spontaneous Update",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "An informal update shared on an ad hoc basis rather than through formal reporting channels.",
                "references": [
                    "I prefer spontaneous updates. I prefer spontaneous updates."
                ],
                "Type": "Communication"
            },
            {
                "id": "11009",
                "label": "Information Over Transmission",
                "color": "#ffffff",
                "shape": "ellipse",
                "info": "A conceptual approach that emphasizes the quality of information over quantity, cautioning against over-sharing.",
                "references": [
                    "I think of it as an \u201cIOT\u201d (information over transmission)."
                ],
                "Type": "Communication"
            }
        ],
        "edges": [
            {
                "source": "4",
                "target": "6",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Utilizes",
                "info": "Deep Learning utilizes GPU to achieve high performance.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8",
                "target": "9",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Uses Technique",
                "info": "Deep Neural Networks employ backpropagation for training.",
                "references": [
                    "you could backprop and learn it."
                ],
                "Type": "Relationship"
            },
            {
                "source": "4",
                "target": "11",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Leads To",
                "info": "Deep Learning led to the emergence of Software 2.0.",
                "references": [
                    "Since then, Andrej Karpathy has called it Software 2.0, but we recognized it from the start as a new way of doing software, even suggesting that maybe we needed to change computing altogether."
                ],
                "Type": "Relationship"
            },
            {
                "source": "5",
                "target": "4",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Catalyzes",
                "info": "The ImageNet Contest catalyzed advances in deep learning.",
                "references": [
                    "several pockets of researchers were simultaneously trying to submit for ImageNet\u2014that big contest in 2012."
                ],
                "Type": "Relationship"
            },
            {
                "source": "6",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "GPU enables accelerated computing.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ],
                "Type": "Relationship"
            },
            {
                "source": "12",
                "target": "13",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Modernizes",
                "info": "Accelerated Computing modernizes legacy architectures like IBM System/360.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ],
                "Type": "Relationship"
            },
            {
                "source": "12",
                "target": "14",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Foreshadows",
                "info": "Accelerated Computing foreshadows breakthrough paradigms akin to Quantum Computing.",
                "references": [
                    "It was almost as if we discovered \u201cquantum computing before quantum computing,\u201d if you will."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8",
                "target": "10",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Realizes",
                "info": "Deep Neural Networks realize the concept of a Universal Function Approximator.",
                "references": [
                    "Imagine having a universal function approximator of any dimensionality."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1013",
                "target": "1016",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Sensor Fusion contributes to the development of NextGen Model Architecture.",
                "references": [
                    "We use this idea for cameras, radars, and lidars\u2014combining multiple sensor modalities so that perception encompasses the strengths of all of them."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1011",
                "target": "1017",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Deployed In",
                "info": "Vision Neural Network is deployed in Vision Deployment System.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ],
                "Type": "Relationship"
            },
            {
                "source": "1005",
                "target": "1015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Precedes",
                "info": "CNN precedes Integrated Multimodal Architecture in evolution.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1007",
                "target": "1015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Vision Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "You can create a vision transformer, an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1008",
                "target": "1015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Audio Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1009",
                "target": "1015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Text Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "a text transformer\u2014you can build a transformer out of almost anything it seems."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1015",
                "target": "1010",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Integrated Multimodal Architecture benefits from Multimodality.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2011",
                "target": "2014",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Grounds in Physical Truths",
                "info": "Physics grounds the embodied robot with physical truths.",
                "references": [
                    "Physics has been described in words\u2014Newton\u2019s laws, for instance."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2015",
                "target": "2014",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Grounds in Ethical Truths",
                "info": "Ethical Truths ground the embodied robot ensuring ethical operations.",
                "references": [
                    "grounded in ethical truths\u2014that\u2019s part of alignment."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2014",
                "target": "2013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Receives Physical Feedback From",
                "info": "Embodied Robot receives simulated physical feedback from Omniverse.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2010",
                "target": "2011",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Informs",
                "info": "World Model informs Physics by providing conceptual grounding.",
                "references": [
                    "derived this world model from language and can apply it to a very wide context.  \nBut you also spoke about teaching these models physics.  \nDo you think that's needed, or could we derive it as these models become multimodal?  \nWill they be able to learn that from the training data as they get trained on video, for example?","**Jensen:**  \nYeah, I think you can.  \nLet\u2019s see.  \nYou can imagine that just about everything has been described in words.  \nPhysics has been described in words\u2014Newton\u2019s laws, for instance."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2012",
                "target": "2013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Delivers Through",
                "info": "Reinforcement Learning Physical Feedback is delivered through Omniverse.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ],
                "Type": "Relationship"
            },
            {
                "source": "2014",
                "target": "2016",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Receives Ethical Guidance From",
                "info": "Embodied Robot receives ethical guidance from Alignment.",
                "references": [
                    "that\u2019s part of alignment.  \nBoth are essential for creating safer chatbots."
                ],
                "Type": "Relationship"
            },
            {
                "source": "3005",
                "target": "3009",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Augments",
                "info": "Symbolic Reasoning augments Learned Models.",
                "references": [
                    "I think that this kind of symbolic reasoning could augment these exhaustively learned models.  \nSo while I can\u2019t say for sure what the limits will be, it seems intuitive that we\u2019ll always need to supplement our learned models with aspects of intelligence derived from rules and experience."
                ],
                "Type": "Relationship"
            },
            {
                "source": "3007",
                "target": "3008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Compute Scaling offers an alternative approach compared to Radical Breakthroughs.",
                "references": [
                    "evolving them slightly and scaling compute\u2014or will we need radical breakthroughs"
                ],
                "Type": "Relationship"
            },
            {
                "source": "4012",
                "target": "4010",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Coexists With",
                "info": "Superintelligence coexists with Human Intellect.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence.  \nRelative to me, these people can do things I can\u2019t imagine, yet we coexist quite harmoniously"
                ],
                "Type": "Relationship"
            },
            {
                "source": "4014",
                "target": "4015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Bridges",
                "info": "Human Language Programming bridges the Digital Divide by making programming accessible.",
                "references": [
                    "With ChatGPT and similar tools, the programming language of choice is human language.  \nYou can program in Swedish, I can program in English, and you can have it write a program to program another computer.  \nThis democratizes programming for the very first time in history.  \nI truly believe this will empower billions of people by giving them access to an instrument of value creation and productivity.  \nThis incredible, light-speed machine we call the computer is now accessible to everyone, and I\u2019m hopeful it will bridge the digital divide"
                ],
                "Type": "Relationship"
            },
            {
                "source": "4011",
                "target": "4010",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enhances",
                "info": "Augmentation enhances Human Intellect by improving cognitive performance.",
                "references": [
                    "If we could provide researchers with tools to run thousands of parallel experiments and be augmented by that rather than replaced"
                ],
                "Type": "Relationship"
            },
            {
                "source": "4014",
                "target": "4013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Empowers",
                "info": "Human Language Programming empowers Democratized Intelligence by expanding accessibility to the production of intelligence.",
                "references": [
                    "I truly believe this will empower billions of people by giving them access to an instrument of value creation and productivity"
                ],
                "Type": "Relationship"
            },
            {
                "source": "4016",
                "target": "4015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Bridges Access",
                "info": "The Computer bridges access gaps by making computing accessible to all.",
                "references": [
                    "This incredible, light-speed machine we call the computer is now accessible to everyone, and I\u2019m hopeful it will bridge the digital divide"
                ],
                "Type": "Relationship"
            },
            {
                "source": "5005",
                "target": "5004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Design Space Exploration contributes to the AI-Driven Chip Design Process.",
                "references": [
                    "So we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities."
                ],
                "Type": "Relationship"
            },
            {
                "source": "5006",
                "target": "5004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Optimization Trade-Offs contributes to the AI-Driven Chip Design Process.",
                "references": [
                    "Sometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount."
                ],
                "Type": "Relationship"
            },
            {
                "source": "5008",
                "target": "5004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Challenges",
                "info": "Module Interconnection Complexity challenges the AI-Driven Chip Design Process.",
                "references": [
                    "The complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe."
                ],
                "Type": "Relationship"
            },
            {
                "source": "5007",
                "target": "5004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Realizes",
                "info": "Hopper Generation Chip realizes the outcomes of the AI-Driven Chip Design Process.",
                "references": [
                    "That\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before."
                ],
                "Type": "Relationship"
            },
            {
                "source": "5026",
                "target": "5004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enhances",
                "info": "Advanced Compute Acceleration enhances the AI-Driven Chip Design Process.",
                "references": [
                    "I think that's really fascinating as well\u2014how you\u2019re accelerating compute at NVIDIA by using AI to develop more advanced compute."
                ],
                "Type": "Relationship"
            },
            {
                "source": "6005",
                "target": "6006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Relies On",
                "info": "Defensible Business relies on Domain Expertise.",
                "references": [
                    "Last time we spoke, we discussed the value of deep domain expertise and integrating into workflows."
                ],
                "Type": "Relationship"
            },
            {
                "source": "6005",
                "target": "6007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Relies On",
                "info": "Defensible Business relies on Customer Challenges.",
                "references": [
                    "Well, I don\u2019t think there\u2019s any evidence that understanding a domain or a specific set of customer challenges ever loses its value.  \nOur technology\u2014and the world\u2019s pool of educated people\u2014is growing.  \nToday\u2019s new college graduates can tackle problems that entire companies used to solve.  \nAll evidence suggests that deeply understanding your customer\u2019s challenges remains extremely valuable."
                ],
                "Type": "Relationship"
            },
            {
                "source": "6008",
                "target": "6012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Is Vulnerable To",
                "info": "Application Companies are vulnerable to commoditization.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ],
                "Type": "Relationship"
            },
            {
                "source": "7011",
                "target": "7012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Drives Innovation In",
                "info": "Enhancing Product Development drives innovation in enabling new product capabilities.",
                "references": [
                    "1. **Enhancing Product Development:** What can AI do to revolutionize the work we do in building our products?  \n   \u2014 For example, the way we design chips has completely changed.  \n2. **Enabling New Product Capabilities:** What can AI enable us to do so that our products themselves become different?  \n   \u2014 In the past, building a graphics card meant designing programmable shaders and compilers. Now, you can\u2019t ship a GeForce GPU by itself because there\u2019s a \u201csupercomputer\u201d in the background learning to predict missing pixels through denoising and inference\u2014imagine inferring 8 to 16 pixels for every pixel rendered, like completing a jigsaw puzzle.  \n   \u2014 We update the algorithms continuously by downloading improvements as they become available, which not only revolutionizes the design of our GPUs but also how they produce images\u2014leading to processors that are far more energy efficient than Moore\u2019s law would predict."
                ],
                "Type": "Relationship"
            },
            {
                "source": "7013",
                "target": "7011",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Empowers Through Insight",
                "info": "Augmenting the Company empowers improvements in product development through insight.",
                "references": [
                    "3. **Augmenting the Company:** Turning the entire company into an AI-augmented organization.  \n   \u2014 This means every employee is empowered by a system that continuously analyzes information, connects the dots, and even predicts changes in the market or supply chain\u2014signals that would be impossible for any one person to see."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8004",
                "target": "8005",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Participates In Conversation With",
                "info": "Establishes a conversational link between Joel and Jensen.",
                "references": [
                    "**Joel:**  \nYeah, exactly."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8007",
                "target": "8008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Connects Prompt Engineering with Large Language Models as integrated technologies.",
                "references": [
                    "Take prompt engineering, for example\u2014it\u2019s already coming to life."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8008",
                "target": "8009",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Highlights integration between Large Language Models and Reinforcement Learning Human Feedback for improved safety.",
                "references": [
                    "incorporating technologies for building guardrails, alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed)"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8008",
                "target": "8010",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Demonstrates integration between Large Language Models and Vector Databases for factual anchoring.",
                "references": [
                    "and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8011",
                "target": "8012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Shows integration between Generative Adversarial Models and Variational Autoencoders.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related,"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8012",
                "target": "8013",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Indicates integration between Variational Autoencoders and Diffusion Models.",
                "references": [
                    "variational autoencoders, and diffusion models were all related,"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8006",
                "target": "8008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Connects Self-driving Cars with Large Language Models to imply shared advanced safety practices.",
                "references": [
                    "In self-driving cars, for example, we dedicate as many resources to making the AI and the car safe as we do to making the car drive."
                ],
                "Type": "Relationship"
            },
            {
                "source": "8014",
                "target": "8015",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Establishes a collaborative relationship between Climate Research and Digital Biology within industry domains.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8016",
                "target": "8017",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Connects Web Design and Programmatic Ads as collaborative industry entities.",
                "references": [
                    "entire professions like web design or programmatic ads\u2014jobs that didn\u2019t exist when I finished school 40 years ago?"
                ],
                "Type": "Relationship"
            },
            {
                "source": "8004",
                "target": "8028",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Should Invest In",
                "info": "Joel should invest in Atlaz.",
                "references": [
                    ""
                ],
                "Type": "Relationship"
            },
            {
                "source": "9006",
                "target": "9007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Flat Organization enables Agility.",
                "references": [
                    "To empower people, you want a flat organization rather than a pyramid (like the old military or the Roman Empire).  \nIn a flat organization, information travels quickly.  \nThat means the first layer\u2014the most senior people\u2014needs to be carefully chosen, because they need the least management.  \nNone of my management team comes to me for career advice\u2014they\u2019ve already \u201cmade it\u201d and are doing great.  \nI have many people reporting to me, but I don\u2019t need to do one-on-ones or career coaching because everyone is an expert in their field.  \nWhen there\u2019s a strategic direction, I communicate it to everyone at the same time so we can all provide feedback and refine it together.  \nBecause the company is so flat and everyone is empowered with information, we\u2019re extremely agile."
                ],
                "Type": "Relationship"
            },
            {
                "source": "9005",
                "target": "9004",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Attracts",
                "info": "Ambitious Computing Challenges attracts Attracting Amazing People.",
                "references": [
                    "and we attract people who want to invent a new form of computing to solve these challenges."
                ],
                "Type": "Relationship"
            },
            {
                "source": "9009",
                "target": "9010",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Reinforces",
                "info": "Direct Communication reinforces Transparent Leadership.",
                "references": [
                    "I don\u2019t take anyone aside or schedule one-on-one coaching sessions.  \nIf something\u2019s not right, I speak up.  \nIt might come off as too direct sometimes, but if people understand I\u2019m only trying to be clear, it works.  \nI spend a lot of time explaining my thought process in meetings\u2014reasoning through decisions, comparing and contrasting ideas\u2014which empowers employees because they see how leaders think."
                ],
                "Type": "Relationship"
            },
            {
                "source": "9006",
                "target": "9011",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Facilitates",
                "info": "Flat Organization facilitates Unified Collaboration.",
                "references": [
                    "we\u2019re all sitting together\u2014it\u2019s like having one big office."
                ],
                "Type": "Relationship"
            },
            {
                "source": "10004",
                "target": "10005",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Guides",
                "info": "Strategy guides continuous planning as execution of strategy requires adaptable planning.",
                "references": [
                    "First of all, strategy isn\u2019t just words\u2014it\u2019s action.  \nIf the company has a strategy but people\u2019s \u201cTop Five Things\u201d don\u2019t align with it, then they\u2019re not really executing the strategy.  \nI get a sense of our direction by sporadically sampling everyone\u2019s updates rather than reading every single one every week.  \nSecond, we don\u2019t have a periodic planning system.  \nThe world is a living, breathing thing, so we plan continuously."
                ],
                "Type": "Relationship"
            },
            {
                "source": "10005",
                "target": "10006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Incorporates",
                "info": "Continuous Planning incorporates foundational reasoning for dynamic decision-making.",
                "references": [
                    "The world is a living, breathing thing, so we plan continuously.  \nThere\u2019s no fixed five-year plan, no one-year plan\u2014just what we\u2019re doing right now.","**Joel:**  \nThat\u2019s really exciting to hear.  \nI think one challenge when executing on first principles is trusting your intuition\u2014especially when it goes against the conventional playbook.  \nWhat made you trust your intuition on some of these things?","**Jensen:**  \nAlmost everything the company pursues is reasoned through first principles."
                ],
                "Type": "Relationship"
            },
            {
                "source": "10006",
                "target": "10007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Inspires",
                "info": "First Principles Reasoning inspires effective leadership through adaptability.",
                "references": [
                    "Almost everything the company pursues is reasoned through first principles.  \n*(Joel: \u201cYeah.\u201d)*  \nYou start with the foundational assumptions that led you to believe the computer had to change, that chip architecture needed to evolve, that software development had to be reimagined, or that data centers would transform.  \nFor example, a data center used to be just a place to store files.  \nIn the future, one data center might be a factory for producing intelligence\u2014where data is refined into this invaluable, invisible asset we call \u201cintelligence.\u201d  \nThat process will continuously drive innovation.  \nYou reason from these first principles and then pursue them with enough dedication and conviction\u2014even if it\u2019s really hard.  \nAnd if you\u2019re wrong, you simply change your mind.  \nModern leadership is all about adaptability:"
                ],
                "Type": "Relationship"
            },
            {
                "source": "10007",
                "target": "10008",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Prevents",
                "info": "Leadership prevents engagement in commodity work to keep focus on innovation.",
                "references": [
                    "The most important is to prevent people from having to do commodity work."
                ],
                "Type": "Relationship"
            },
            {
                "source": "11005",
                "target": "11007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Sends",
                "info": "Individual contributors send their \u2018Top Five Things\u2019 emails as a way to communicate top priorities.",
                "references": [
                    "If you send an email called \u201cTop Five Things\u201d detailing your top five observations, accomplishments, or even personal news (like visiting a great restaurant or having a baby), I\u2019ll read it.  \nI end up reading about a hundred of these every morning."
                ],
                "Type": "Relationship"
            },
            {
                "source": "11004",
                "target": "11005",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Excludes",
                "info": "A strict leadership hierarchy can inadvertently exclude key individual contributors.",
                "references": [
                    "I\u2019ve always struggled with excluding the best individual contributors from key meetings."
                ],
                "Type": "Relationship"
            },
            {
                "source": "11005",
                "target": "11006",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Possesses",
                "info": "Individual contributors possess ground truth derived from firsthand experience.",
                "references": [
                    "They should be there because they have the ground truth\u2014the firsthand experience with the problems."
                ],
                "Type": "Relationship"
            },
            {
                "source": "11008",
                "target": "11007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Is Underlying Idea Of",
                "info": "Spontaneous updates are an underlying idea of \u2018Top Five Things\u2019 emails.",
                "references": [
                    "I prefer spontaneous updates. I prefer spontaneous updates."
                ],
                "Type": "Relationship"
            },
            {
                "source": "11009",
                "target": "11007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Is Underlying Idea Of",
                "info": "An information over transmission approach is an underlying idea of \u2018Top Five Things\u2019 emails.",
                "references": [
                    "I think of it as an \u201cIOT\u201d (information over transmission)."
                ],
                "Type": "Relationship"
            },
            {
                "source": "6",
                "target": "1011",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Accelerates",
                "info": "GPU accelerates Vision Neural Network computations.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive because no one had really put GPUs to that use previously."
                ],
                "Type": "Relationship"
            },
            {
                "source": "9006",
                "target": "12",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Flat Organization has enabled Accelerated Computing for NVIDIA.",
                "references": [
                    "This structure has enabled us to build accelerated computing so fast."
                ],
                "Type": "Relationship"
            },
            {
                "source": "10007",
                "target": "10009",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Leadership enables transparency to foster a culture of openness.",
                "references": [
                    "Additionally, empowerment comes from transparency.  \nWhile many companies are very siloed, I encourage a culture of openness."
                ],
                "Type": "Relationship"
            },
            {
                "source": "1012",
                "target": "3007",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Model Architectures offers an alternative approach compared to Compute Scaling.",
                "references": [
                    "Do you think we can keep running with the existing model architectures\u2014evolving them slightly and scaling compute"
                ],
                "Type": "Relationship"
            },
            {
                "source": "3008",
                "target": "1012",
                "type": "other",
                "color": "black",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Radical Breakthroughs offers an alternative approach compared to Model Architectures.",
                "references": [
                    "see diminishing returns soon see diminishing returns soon"
                ],
                "Type": "Relationship"
            }
        ],
        "categories": [
            {
                "name": "Entity",
                "color": "lightblue"
            },
            {
                "name": "Relationship",
                "color": "#ffffff"
            },
            {
                "name": "Neural Network",
                "color": "#1d1d22"
            },
            {
                "name": "Model Architecture & Neural Network",
                "color": "#45454a"
            },
            {
                "name": "Research Event",
                "color": "#ffffff"
            },
            {
                "name": "Hardware & Hardware Component",
                "color": "#1d1d22"
            },
            {
                "name": "Software Paradigm",
                "color": "#45454a"
            },
            {
                "name": "Algorithmic Approach",
                "color": "#ffffff"
            },
            {
                "name": "Computing Architecture",
                "color": "#1d1d22"
            },
            {
                "name": "Computing Architecture & Organizational Practice",
                "color": "#45454a"
            },
            {
                "name": "Technological Concept",
                "color": "#ffffff"
            },
            {
                "name": "Transformer",
                "color": "#1d1d22"
            },
            {
                "name": "Vision Transformer",
                "color": "#45454a"
            },
            {
                "name": "Model Architecture",
                "color": "#ffffff"
            },
            {
                "name": "Deployment System Base & System",
                "color": "#1d1d22"
            },
            {
                "name": "Multimodality Mechanism",
                "color": "#45454a"
            },
            {
                "name": "Model Architecture Base & Multimodality Mechanism",
                "color": "#ffffff"
            },
            {
                "name": "Sensor Integration",
                "color": "#1d1d22"
            },
            {
                "name": "Model Architecture Base",
                "color": "#45454a"
            },
            {
                "name": "Model Architecture Base & Scaling AI",
                "color": "#ffffff"
            },
            {
                "name": "Cognitive Construct",
                "color": "#1d1d22"
            },
            {
                "name": "Physical Concept",
                "color": "#45454a"
            },
            {
                "name": "Training Methodology",
                "color": "#ffffff"
            },
            {
                "name": "Ethical Principle",
                "color": "#1d1d22"
            },
            {
                "name": "Robot",
                "color": "#45454a"
            },
            {
                "name": "Physics Simulation Platform",
                "color": "#ffffff"
            },
            {
                "name": "Scaling AI",
                "color": "#1d1d22"
            },
            {
                "name": "AI Improvement Strategy",
                "color": "#45454a"
            },
            {
                "name": "AI Model Type",
                "color": "#ffffff"
            },
            {
                "name": "Cognitive Ability & Intelligence",
                "color": "#1d1d22"
            },
            {
                "name": "Augmentation Process",
                "color": "#45454a"
            },
            {
                "name": "Programming Paradigm",
                "color": "#ffffff"
            },
            {
                "name": "Access Gap",
                "color": "#1d1d22"
            },
            {
                "name": "Computing Device",
                "color": "#45454a"
            },
            {
                "name": "Chip Design Process",
                "color": "#ffffff"
            },
            {
                "name": "Chip Design Component",
                "color": "#1d1d22"
            },
            {
                "name": "Chip Product",
                "color": "#45454a"
            },
            {
                "name": "Chip Design Challenge",
                "color": "#ffffff"
            },
            {
                "name": "Business",
                "color": "#1d1d22"
            },
            {
                "name": "Business Concept",
                "color": "#45454a"
            },
            {
                "name": "Competitive Advantage Factor",
                "color": "#ffffff"
            },
            {
                "name": "AI Application Layer",
                "color": "#1d1d22"
            },
            {
                "name": "Speaker",
                "color": "#45454a"
            },
            {
                "name": "Technology",
                "color": "#ffffff"
            },
            {
                "name": "Industry",
                "color": "#1d1d22"
            },
            {
                "name": "Company",
                "color": "#45454a"
            },
            {
                "name": "Organizational Practice",
                "color": "#ffffff"
            },
            {
                "name": "Technical Ambition",
                "color": "#1d1d22"
            },
            {
                "name": "Company Objective",
                "color": "#45454a"
            },
            {
                "name": "Organizational Concept",
                "color": "#ffffff"
            },
            {
                "name": "Human Role",
                "color": "#1d1d22"
            },
            {
                "name": "Information",
                "color": "#45454a"
            },
            {
                "name": "Communication",
                "color": "#ffffff"
            }
        ]
    },
    example8: {
        "nodes": [
            {
                "id": "4",
                "label": "Deep Learning",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A method that employs layered neural networks and backpropagation, representing a new paradigm in software development.",
                "references": [
                    "I learned about deep learning about the same time everybody else did, and maybe slightly before that."
                ]
            },
            {
                "id": "5",
                "label": "ImageNet Contest",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "A 2012 contest that spurred advancements in deep learning research by challenging researchers to innovate.",
                "references": [
                    "several pockets of researchers were simultaneously trying to submit for ImageNet\u2014that big contest in 2012."
                ]
            },
            {
                "id": "6",
                "label": "GPU",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A Graphics Processing Unit critical for accelerating deep learning computations.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ]
            },
            {
                "id": "7",
                "label": "Neural Network",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A computational model structured as a network of interconnected nodes.",
                "references": [
                    "though back then it was called \u201cneural networks.\u201d"
                ]
            },
            {
                "id": "8",
                "label": "Deep Neural Network",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Multi-layered neural network architecture that enable scalable and effective deep learning.",
                "references": [
                    "to learn how to program it for deep neural nets."
                ]
            },
            {
                "id": "9",
                "label": "Backpropagation",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "The training algorithm that iteratively adjusts neural network weights via error gradient descent.",
                "references": [
                    "you could backprop and learn it."
                ]
            },
            {
                "id": "10",
                "label": "Universal Function Approximator",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "The theoretical concept that sufficiently large deep models can approximate any function.",
                "references": [
                    "Imagine having a universal function approximator of any dimensionality."
                ]
            },
            {
                "id": "11",
                "label": "Software 2.0",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A paradigm shift in software development driven by data and deep learning approaches.",
                "references": [
                    "Since then, Andrej Karpathy has called it Software 2.0, but we recognized it from the start as a new way of doing software, even suggesting that maybe we needed to change computing altogether."
                ]
            },
            {
                "id": "12",
                "label": "Accelerated Computing",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "The transformation of computing performance through the integration of GPUs and deep learning.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ]
            },
            {
                "id": "13",
                "label": "IBM System/360",
                "color": "#777777",
                "shape": "ellipse",
                "info": "A pioneering computing system whose legacy influenced modern computing architectures.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ]
            },
            {
                "id": "14",
                "label": "Quantum Computing",
                "color": "#777777",
                "shape": "ellipse",
                "info": "A visionary concept hinting at future breakthroughs in computational paradigms.",
                "references": [
                    "It was almost as if we discovered \u201cquantum computing before quantum computing,\u201d if you will."
                ]
            },
            {
                "id": "102",
                "label": "Research Event",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for events that stimulate technological research.",
                "references": []
            },
            {
                "id": "103",
                "label": "Hardware Component",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category representing physical hardware components used in computing.",
                "references": []
            },
            {
                "id": "104",
                "label": "Software Paradigm",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for paradigms that define approaches to software development.",
                "references": []
            },
            {
                "id": "105",
                "label": "Algorithmic Approach",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for methods and algorithms used in technology.",
                "references": []
            },
            {
                "id": "106",
                "label": "Computing Architecture",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for systems and architectures in computing.",
                "references": []
            },
            {
                "id": "107",
                "label": "Technological Concept",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for overarching technological ideas.",
                "references": []
            },
            {
                "id": "1005",
                "label": "CNN",
                "color": "#626262",
                "shape": "ellipse",
                "info": "Convolutional Neural Network, referenced as an earlier dominant vision model and a precursor in model architectures.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ]
            },
            {
                "id": "1006",
                "label": "Transformer",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A model architecture that employs attention mechanisms to process various types of data.",
                "references": [
                    "Transformers\u2014and the work researchers have done to reformulate just about every problem, every type of data, into something that a transformer can learn\u2014have been revolutionary."
                ]
            },
            {
                "id": "1007",
                "label": "Vision Transformer",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A transformer model adapted for visual tasks.",
                "references": [
                    "You can create a vision transformer, an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "id": "1008",
                "label": "Audio Transformer",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A transformer model adapted for processing audio signals.",
                "references": [
                    "an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "id": "1009",
                "label": "Text Transformer",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A transformer model specialized for textual data.",
                "references": [
                    "a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "id": "1010",
                "label": "Multimodality",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "The integration of multiple data modalities to improve model performance.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ]
            },
            {
                "id": "1011",
                "label": "Vision Neural Network",
                "color": "#272727",
                "shape": "ellipse",
                "info": "A neural network specialized for processing visual information.",
                "references": [
                    "if you\u2019re training a neural network for vision and you\u2019ve only seen horses but never zebras,"
                ]
            },
            {
                "id": "1012",
                "label": "Model Architecture",
                "color": "#1f1f1f",
                "shape": "ellipse",
                "info": "The overall design or structure that an AI model is built on.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ]
            },
            {
                "id": "1013",
                "label": "Sensor Fusion",
                "color": "#171717",
                "shape": "ellipse",
                "info": "The integration of sensory data from various sensors to improve perception.",
                "references": [
                    "We use this idea for cameras, radars, and lidars\u2014combining multiple sensor modalities so that perception encompasses the strengths of all of them."
                ]
            },
            {
                "id": "1015",
                "label": "Integrated Multimodal Architecture",
                "color": "#0f0f0f",
                "shape": "ellipse",
                "info": "A form of model architecture that seamlessly integrates multiple modalities for enhanced performance.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ]
            },
            {
                "id": "1016",
                "label": "NextGen Model Architecture",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A forward-looking model design that integrates multimodality for advanced performance.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ]
            },
            {
                "id": "1017",
                "label": "Vision Deployment System",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "A specialized system for deploying neural network-based vision systems.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ]
            },
            {
                "id": "1030",
                "label": "Hardware",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A physical device used to perform computations.",
                "references": []
            },
            {
                "id": "1031",
                "label": "System",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A general abstract system that integrates various components.",
                "references": []
            },
            {
                "id": "1033",
                "label": "Multimodality Mechanism",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing mechanisms that integrate multiple data modalities.",
                "references": []
            },
            {
                "id": "1034",
                "label": "Sensor Integration",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing the integration of sensor data from various inputs.",
                "references": []
            },
            {
                "id": "1035",
                "label": "Model Architecture Base",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract representation of model architectures.",
                "references": []
            },
            {
                "id": "1036",
                "label": "Deployment System Base",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract representation of systems used for deployment.",
                "references": []
            },
            {
                "id": "2010",
                "label": "World Model",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A representation of the external world derived from language.",
                "references": [
                    "derived this world model from language and can apply it to a very wide context."
                ]
            },
            {
                "id": "2011",
                "label": "Physics",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Scientific concept representing physical laws and phenomena derived from textual descriptions.",
                "references": [
                    "Physics has been described in words\u2014Newton\u2019s laws, for instance."
                ]
            },
            {
                "id": "2012",
                "label": "Reinforcement Learning Physical Feedback",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "A training methodology where a robot receives simulated physical feedback via a physics simulation platform.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ]
            },
            {
                "id": "2013",
                "label": "Omniverse",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A physics-based simulation platform enabling digital twinning of robots that obey the laws of physics.",
                "references": [
                    "We created a system called Omniverse that obeys the laws of physics so we can give it a digital twin of an embodied robot."
                ]
            },
            {
                "id": "2014",
                "label": "Embodied Robot",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "A robot integrated with a digital twin for simulation-based physical grounding.",
                "references": [
                    "digital twin of an embodied robot.  \nThat embodied language model would then receive"
                ]
            },
            {
                "id": "2015",
                "label": "Ethical Truths",
                "color": "#777777",
                "shape": "ellipse",
                "info": "Fundamental ethical principles for grounding AI in ethical reasoning.",
                "references": [
                    "grounded in ethical truths\u2014that\u2019s part of alignment."
                ]
            },
            {
                "id": "2016",
                "label": "Alignment",
                "color": "#777777",
                "shape": "ellipse",
                "info": "The process of ensuring AI systems operate according to ethical standards for safer outcomes.",
                "references": [
                    "that\u2019s part of alignment.  \nBoth are essential for creating safer chatbots."
                ]
            },
            {
                "id": "2101",
                "label": "Cognitive Construct",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract representation derived from language describing mental models.",
                "references": []
            },
            {
                "id": "2102",
                "label": "Physical Concept",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A concept representing physical laws and phenomena.",
                "references": []
            },
            {
                "id": "2103",
                "label": "Training Methodology",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Methodologies for training AI systems, particularly with physical feedback.",
                "references": []
            },
            {
                "id": "2104",
                "label": "Ethical Principle",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "Principles that underpin ethical decision-making in AI.",
                "references": []
            },
            {
                "id": "2105",
                "label": "Robot",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A mechanical or digital entity capable of performing tasks autonomously.",
                "references": []
            },
            {
                "id": "2106",
                "label": "Physics Simulation Platform",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A platform that simulates physical environments for feedback and training.",
                "references": []
            },
            {
                "id": "3004",
                "label": "Scaling AI",
                "color": "#626262",
                "shape": "ellipse",
                "info": "Concept regarding the overall approach to enhancing AI capabilities, potentially through evolving existing model architectures, scaling compute, or requiring radical breakthroughs.",
                "references": [
                    "Because of the nature of the deep neural network—each layer isolated from the next and the back propagation approach so effective—you could imagine scaling this tremendously. "
                ]
            },
            {
                "id": "3005",
                "label": "Symbolic Reasoning",
                "color": "#626262",
                "shape": "ellipse",
                "info": "A method involving the application of explicit rules to supplement learned models, drawing from cultural principles and experiential knowledge.",
                "references": [
                    "I think that this kind of symbolic reasoning could augment these exhaustively learned models.  \nSo while I can\u2019t say for sure what the limits will be, it seems intuitive that we\u2019ll always need to supplement our learned models with aspects of intelligence derived from rules and experience."
                ]
            },
            {
                "id": "3007",
                "label": "Compute Scaling",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Incremental enhancements in computational resources and efficiency to improve AI performance.",
                "references": [
                    "evolving them slightly and scaling compute\u2014or will we need radical breakthroughs"
                ]
            },
            {
                "id": "3008",
                "label": "Radical Breakthroughs",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Innovative leaps in AI technology required when incremental improvements become insufficient due to diminishing returns.",
                "references": [
                    "or will we need radical breakthroughs and see diminishing returns soon"
                ]
            },
            {
                "id": "3009",
                "label": "Learned Models",
                "color": "#383838",
                "shape": "ellipse",
                "info": "AI models developed primarily through data-driven learning approaches.",
                "references": [
                    "augment these exhaustively learned models"
                ]
            },
            {
                "id": "3010",
                "label": "AI Improvement Strategy",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A category representing strategies aimed at enhancing AI capabilities, including incremental improvements and breakthrough innovations.",
                "references": []
            },
            {
                "id": "3011",
                "label": "AI Model Type",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A category representing the different types of AI models developed through learning from data.",
                "references": []
            },
            {
                "id": "4010",
                "label": "Human Intellect",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "Represents the cognitive capacity of humans that can be augmented to achieve higher levels of understanding and performance.",
                "references": [
                    "one thing we're really excited about at Sana is the possibility to augment human intellect"
                ]
            },
            {
                "id": "4011",
                "label": "Augmentation",
                "color": "#272727",
                "shape": "ellipse",
                "info": "The process of enhancing human capabilities through technological or methodological improvements.",
                "references": [
                    "If we could provide researchers with tools to run thousands of parallel experiments and be augmented by that rather than replaced"
                ]
            },
            {
                "id": "4012",
                "label": "Superintelligence",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "A state where certain systems or groups exhibit intelligence that is orders of magnitude greater than typical human levels.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence"
                ]
            },
            {
                "id": "4013",
                "label": "Democratized Intelligence",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "A phenomenon where the generation and application of intelligence becomes accessible to a broad population.",
                "references": [
                    "I believe we\u2019re now democratizing intelligence\u2014the production of intelligence"
                ]
            },
            {
                "id": "4014",
                "label": "Human Language Programming",
                "color": "#1f1f1f",
                "shape": "ellipse",
                "info": "The practice of using natural language constructs to write and instruct computer programs.",
                "references": [
                    "With ChatGPT and similar tools, the programming language of choice is human language"
                ]
            },
            {
                "id": "4015",
                "label": "Digital Divide",
                "color": "#171717",
                "shape": "ellipse",
                "info": "The gap between populations in terms of access to and use of digital technology.",
                "references": [
                    "I\u2019m hopeful it will bridge the digital divide"
                ]
            },
            {
                "id": "4016",
                "label": "The Computer",
                "color": "#0f0f0f",
                "shape": "ellipse",
                "info": "Refers to the advanced computational machinery that has become broadly accessible, revolutionizing productivity and creativity.",
                "references": [
                    "This incredible, light-speed machine we call the computer is now accessible to everyone"
                ]
            },
            {
                "id": "4101",
                "label": "Cognitive Ability",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing mental capacities and cognitive functions.",
                "references": []
            },
            {
                "id": "4102",
                "label": "Augmentation Process",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing processes that enhance or amplify existing abilities.",
                "references": []
            },
            {
                "id": "4103",
                "label": "Programming Paradigm",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing approaches to programming, including natural language based programming.",
                "references": []
            },
            {
                "id": "4104",
                "label": "Access Gap",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing disparities in access to digital technology.",
                "references": []
            },
            {
                "id": "4106",
                "label": "Intelligence",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing the capacity to learn, understand, and apply knowledge.",
                "references": []
            },
            {
                "id": "4107",
                "label": "Computing Device",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing physical or virtual machines that perform computation.",
                "references": []
            },
            {
                "id": "5004",
                "label": "AI-Driven Chip Design Process",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "A specific chip design process at NVIDIA that leverages AI to explore and optimize complex chip designs. It is a subtype of the abstract Chip Design Process.",
                "references": [
                    "Well, our current generation of chips is so large and complex that all the employees in our company can\u2019t possibly design them from scratch.  \nSo we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities.  \nSometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount.  \nWe set these kinds of judgments and then let the AI explore all the corner cases and design permutations for us.  \nIt comes back with a design that no human could have done alone.  \nThat\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before.  \nThe complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe.  \nOnly an AI could figure out the best optimization for that."
                ]
            },
            {
                "id": "5005",
                "label": "Design Space Exploration",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "The phase where AI experiments with hundreds of thousands of design permutations to identify optimal trade-offs. It is a subtype of the Chip Design Component.",
                "references": [
                    "So we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities."
                ]
            },
            {
                "id": "5006",
                "label": "Optimization Trade-Offs",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "The selection process for choosing which design parameters to optimize based on priorities. It is a subtype of the Chip Design Component.",
                "references": [
                    "Sometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount."
                ]
            },
            {
                "id": "5007",
                "label": "Hopper Generation Chip",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A chip design product that exemplifies innovative products produced by AI methodologies at NVIDIA. It is a subtype of Chip Product.",
                "references": [
                    "That\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before."
                ]
            },
            {
                "id": "5008",
                "label": "Module Interconnection Complexity",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "The intricate challenge of interconnecting a vast number of modules on a chip. It is a subtype of the Chip Design Challenge.",
                "references": [
                    "The complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe."
                ]
            },
            {
                "id": "5026",
                "label": "Advanced Compute Acceleration",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "The process of using AI to accelerate the development of advanced compute architectures at NVIDIA. It is a subtype of the Chip Design Component.",
                "references": [
                    "I think that's really fascinating as well\u2014how you\u2019re accelerating compute at NVIDIA by using AI to develop more advanced compute."
                ]
            },
            {
                "id": "5009",
                "label": "Chip Design Process",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract process encompassing various phases involved in designing chips.",
                "references": []
            },
            {
                "id": "5010",
                "label": "Chip Design Component",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract component contributing to the overall chip design process.",
                "references": []
            },
            {
                "id": "5011",
                "label": "Chip Product",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract representation of a finalized chip design product.",
                "references": []
            },
            {
                "id": "5012",
                "label": "Chip Design Challenge",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract challenge encountered during the chip design process.",
                "references": []
            },
            {
                "id": "6004",
                "label": "Business",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "Companies or ventures operating in the marketplace.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ]
            },
            {
                "id": "6005",
                "label": "Defensible Business",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "A business structured to withstand competitive pressures in an AI-driven world.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ]
            },
            {
                "id": "6006",
                "label": "Domain Expertise",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "In-depth knowledge and expertise in a specific domain that can provide competitive advantage.",
                "references": [
                    "Last time we spoke, we discussed the value of deep domain expertise and integrating into workflows."
                ]
            },
            {
                "id": "6007",
                "label": "Customer Challenges",
                "color": "#8c8c8c",
                "shape": "ellipse",
                "info": "The complex problems and needs faced by customers that remain valuable over time.",
                "references": [
                    "Well, I don\u2019t think there\u2019s any evidence that understanding a domain or a specific set of customer challenges ever loses its value.  \nOur technology\u2014and the world\u2019s pool of educated people\u2014is growing.  \nToday\u2019s new college graduates can tackle problems that entire companies used to solve.  \nAll evidence suggests that deeply understanding your customer\u2019s challenges remains extremely valuable."
                ]
            },
            {
                "id": "6008",
                "label": "Application Companies",
                "color": "#a1a1a1",
                "shape": "ellipse",
                "info": "Businesses operating at the application layer facing commoditization challenges.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ]
            },
            {
                "id": "6012",
                "label": "Commoditization",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "The process whereby products or services become undifferentiated and subject to intense price competition.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ]
            },
            {
                "id": "6009",
                "label": "Business Concept",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract category for business-related entities.",
                "references": []
            },
            {
                "id": "6010",
                "label": "Competitive Advantage Factor",
                "color": "#b6b6b6",
                "shape": "ellipse",
                "info": "An abstract category for factors that contribute to a business's sustained competitive advantage.",
                "references": []
            },
            {
                "id": "7010",
                "label": "AI Application Layer",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A layer where AI technologies are applied to create innovative solutions in product development, product capabilities, and organizational augmentation.",
                "references": [
                    "And what are you most excited about on the application layer?"
                ]
            },
            {
                "id": "7011",
                "label": "Enhancing Product Development",
                "color": "#777777",
                "shape": "ellipse",
                "info": "Leveraging AI to fundamentally transform product design and development processes, as exemplified by innovations in chip manufacturing.",
                "references": [
                    "1. **Enhancing Product Development:** What can AI do to revolutionize the work we do in building our products?  \n   \u2014 For example, the way we design chips has completely changed."
                ]
            },
            {
                "id": "7012",
                "label": "Enabling New Product Capabilities",
                "color": "#777777",
                "shape": "ellipse",
                "info": "Utilizing AI to empower products with novel features and functionalities, transforming the way products perform and interact.",
                "references": [
                    "2. **Enabling New Product Capabilities:** What can AI enable us to do so that our products themselves become different?  \n   \u2014 In the past, building a graphics card meant designing programmable shaders and compilers. Now, you can\u2019t ship a GeForce GPU by itself because there\u2019s a \u201csupercomputer\u201d in the background learning to predict missing pixels through denoising and inference\u2014imagine inferring 8 to 16 pixels for every pixel rendered, like completing a jigsaw puzzle.  \n   \u2014 We update the algorithms continuously by downloading improvements as they become available, which not only revolutionizes the design of our GPUs but also how they produce images\u2014leading to processors that are far more energy efficient than Moore\u2019s law would predict."
                ]
            },
            {
                "id": "7013",
                "label": "Augmenting the Company",
                "color": "#777777",
                "shape": "ellipse",
                "info": "Integrating AI systems within the organization to enhance decision-making, operational efficiency, and strategic insight.",
                "references": [
                    "3. **Augmenting the Company:** Turning the entire company into an AI-augmented organization.  \n   \u2014 This means every employee is empowered by a system that continuously analyzes information, connects the dots, and even predicts changes in the market or supply chain\u2014signals that would be impossible for any one person to see."
                ]
            },
            {
                "id": "8004",
                "label": "Joel Hellermark",
                "color": "#626262",
                "shape": "ellipse",
                "info": "Joel Hellermark is a Swedish entrepreneur and the founder and CEO of Sana, an artificial intelligence (AI) company that focuses on organizing and personalizing knowledge within organizations.",
                "references": [
                    "And what do you think is your most contrarian view on AI right now?"
                ]
            },
            {
                "id": "8005",
                "label": "Jensen Huang",
                "color": "#626262",
                "shape": "ellipse",
                "info": "Jensen Huang is a Taiwanese-American entrepreneur and the co-founder, president, and CEO of NVIDIA Corporation.",
                "references": [
                    "**Jensen:**  \nI don\u2019t think I have anything particularly contrarian."
                ]
            },
            {
                "id": "8006",
                "label": "Self-driving Cars",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Vehicles that use AI for autonomous driving with an emphasis on safety protocols.",
                "references": [
                    "In self-driving cars, for example, we dedicate as many resources to making the AI and the car safe as we do to making the car drive."
                ]
            },
            {
                "id": "8007",
                "label": "Prompt Engineering",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "An emerging design approach to interfacing with large language models.",
                "references": [
                    "Take prompt engineering, for example\u2014it\u2019s already coming to life."
                ]
            },
            {
                "id": "8008",
                "label": "Large Language Models",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Advanced language models evolving with complementary safety and integration technologies.",
                "references": [
                    "I think large language models will have to do the same\u2014incorporating technologies for building guardrails, alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed), and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ]
            },
            {
                "id": "8009",
                "label": "Reinforcement Learning Human Feedback",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A technique where human feedback is used to refine AI behavior for safety and alignment.",
                "references": [
                    "alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed)"
                ]
            },
            {
                "id": "8010",
                "label": "Vector Databases",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Databases optimized for storing vector representations to help reduce AI hallucinations.",
                "references": [
                    "and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ]
            },
            {
                "id": "8011",
                "label": "Generative Adversarial Models",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A class of machine learning frameworks used for generative tasks.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ]
            },
            {
                "id": "8012",
                "label": "Variational Autoencoders",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "A type of autoencoder that learns a probability distribution over its inputs to generate new data.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ]
            },
            {
                "id": "8013",
                "label": "Diffusion Models",
                "color": "#4d4d4d",
                "shape": "ellipse",
                "info": "Models that generate data by reversing a diffusion process and gradually refining random noise.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related, and breakthroughs in one area could lead to breakthroughs in another."
                ]
            },
            {
                "id": "8014",
                "label": "Climate Research",
                "color": "#383838",
                "shape": "ellipse",
                "info": "Research focused on understanding and addressing the challenges of climate change.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ]
            },
            {
                "id": "8015",
                "label": "Digital Biology",
                "color": "#383838",
                "shape": "ellipse",
                "info": "A field that combines digital technologies with biological research for innovative solutions.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ]
            },
            {
                "id": "8016",
                "label": "Web Design",
                "color": "#383838",
                "shape": "ellipse",
                "info": "The practice of designing websites and digital interfaces that has evolved into an industry.",
                "references": [
                    "entire professions like web design"
                ]
            },
            {
                "id": "8017",
                "label": "Programmatic Ads",
                "color": "#383838",
                "shape": "ellipse",
                "info": "Automated advertising practices that have grown into a substantial industry.",
                "references": [
                    "entire professions like web design or programmatic ads\u2014jobs that didn\u2019t exist when I finished school 40 years ago?"
                ]
            },
            {
                "id": "8018",
                "label": "Speaker",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A person who speaks in discussions or interviews.",
                "references": []
            },
            {
                "id": "8019",
                "label": "Technology",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A tool, system, or method related to technical domains.",
                "references": []
            },
            {
                "id": "8020",
                "label": "Industry",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A sector of economic activity encompassing related enterprises.",
                "references": []
            },

            {
                "id": "8027",
                "label": "Company",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An organization that produces goods or services.",
                "references": []
            },
            {
                "id": "8028",
                "label": "Atlaz",
                "color": "#2f2f2f",
                "shape": "ellipse",
                "info": "A company that turns unstructred data into Knowledge Graphs enabling high quality synthetic data generation and enterprise GenAI adoption in regulated industries.",
                "references": []
            },
            {
                "id": "9004",
                "label": "Attracting Amazing People",
                "color": "#272727",
                "shape": "ellipse",
                "info": "The process by which the company attracts exceptional talent by engaging with challenges that push the limits of conventional computing.",
                "references": [
                    "First, I wanted to create a company that naturally attracts amazing people because we\u2019re solving computing problems that are barely possible."
                ]
            },
            {
                "id": "9005",
                "label": "Ambitious Computing Challenges",
                "color": "#1f1f1f",
                "shape": "ellipse",
                "info": "The pursuit of addressing computing problems that are nearly impossible for conventional systems.",
                "references": [
                    "If a problem could be solved by normal computers, we wouldn\u2019t bother with it."
                ]
            },
            {
                "id": "9006",
                "label": "Flat Organization",
                "color": "#171717",
                "shape": "ellipse",
                "info": "An organizational structure with minimal hierarchy that speeds information flow and enhances empowerment.",
                "references": [
                    "To empower people, you want a flat organization rather than a pyramid (like the old military or the Roman Empire)."
                ]
            },
            {
                "id": "9007",
                "label": "Agility",
                "color": "#171717",
                "shape": "ellipse",
                "info": "The ability of the organization to rapidly adapt and respond, a direct outcome of its flat structure and open information flow.",
                "references": [
                    "Because the company is so flat and everyone is empowered with information, we\u2019re extremely agile."
                ]
            },
            {
                "id": "9009",
                "label": "Direct Communication",
                "color": "#171717",
                "shape": "ellipse",
                "info": "A leadership approach characterized by open, unmediated dialogue without private coaching sessions.",
                "references": [
                    "I don\u2019t take anyone aside or schedule one-on-one coaching sessions.  \nIf something\u2019s not right, I speak up."
                ]
            },
            {
                "id": "9010",
                "label": "Transparent Leadership",
                "color": "#171717",
                "shape": "ellipse",
                "info": "A leadership style where decision processes and rationales are openly shared, fostering clarity and empowerment.",
                "references": [
                    "I spend a lot of time explaining my thought process in meetings\u2014reasoning through decisions, comparing and contrasting ideas\u2014which empowers employees because they see how leaders think."
                ]
            },
            {
                "id": "9011",
                "label": "Unified Collaboration",
                "color": "#171717",
                "shape": "ellipse",
                "info": "An organizational practice emphasizing collective teamwork in a shared physical space.",
                "references": [
                    "we\u2019re all sitting together\u2014it\u2019s like having one big office."
                ]
            },
            {
                "id": "9020",
                "label": "Organizational Practice",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A conceptual category encompassing various practices and approaches utilized within an organization.",
                "references": []
            },
            {
                "id": "9021",
                "label": "Technical Ambition",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing the drive to tackle exceptionally challenging technical problems.",
                "references": []
            },
            {
                "id": "9022",
                "label": "Company Objective",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "A high-level strategic goal or vision that defines a company's purpose and intended outcomes.",
                "references": []
            },
            {
                "id": "10004",
                "label": "Strategy",
                "color": "#171717",
                "shape": "ellipse",
                "info": "Strategy isn\u2019t just words\u2014it\u2019s action.",
                "references": [
                    "First of all, strategy isn\u2019t just words\u2014it\u2019s action."
                ]
            },
            {
                "id": "10005",
                "label": "Continuous Planning",
                "color": "#171717",
                "shape": "ellipse",
                "info": "An approach that emphasizes planning continuously in a dynamic environment.",
                "references": [
                    "Second, we don\u2019t have a periodic planning system.  \nThe world is a living, breathing thing, so we plan continuously.  \nThere\u2019s no fixed five-year plan, no one-year plan\u2014just what we\u2019re doing right now."
                ]
            },
            {
                "id": "10006",
                "label": "First Principles Reasoning",
                "color": "#171717",
                "shape": "ellipse",
                "info": "A method of reasoning that starts from foundational assumptions.",
                "references": [
                    "Almost everything the company pursues is reasoned through first principles."
                ]
            },
            {
                "id": "10007",
                "label": "Good Leadership",
                "color": "#171717",
                "shape": "ellipse",
                "info": "The act of creating an environment that empowers others to do their life\u2019s work.",
                "references": [
                    "That\u2019s the mission of leaders\u2014to create an environment that empowers others to do their life\u2019s work."
                ]
            },
            {
                "id": "10008",
                "label": "Commodity Work",
                "color": "#171717",
                "shape": "ellipse",
                "info": "Routine or commoditized tasks that detract from strategic initiatives.",
                "references": [
                    "The most important is to prevent people from having to do commodity work."
                ]
            },
            {
                "id": "10009",
                "label": "Transparency",
                "color": "#171717",
                "shape": "ellipse",
                "info": "The practice of openness that empowers and connects people.",
                "references": [
                    "Additionally, empowerment comes from transparency.  \nWhile many companies are very siloed, I encourage a culture of openness."
                ]
            },
            {
                "id": "11004",
                "label": "Leadership Hierarchy",
                "color": "#0f0f0f",
                "shape": "ellipse",
                "info": "Represents the concept of a strict leadership hierarchy as emphasized in traditional playbooks.",
                "references": [
                    "Exactly. That\u2019s one thing I find intriguing because many traditional playbooks emphasize a strict leadership hierarchy."
                ]
            },
            {
                "id": "11005",
                "label": "Individual Contributor",
                "color": "#45454a",
                "shape": "ellipse",
                "info": "Denotes a person whose expertise is critical but who may be excluded from key decision-making forums.",
                "references": [
                    "I\u2019ve always struggled with excluding the best individual contributors from key meetings."
                ]
            },
            {
                "id": "11006",
                "label": "Ground Truth",
                "color": "#e0e0e0",
                "shape": "ellipse",
                "info": "Refers to the accurate, firsthand account of issues based on direct experience.",
                "references": [
                    "They should be there because they have the ground truth\u2014the firsthand experience with the problems."
                ]
            },
            {
                "id": "11007",
                "label": "Top Five Things",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A type of email update detailing an individual's top five observations, accomplishments, or personal news.",
                "references": [
                    "If you send an email called \u201cTop Five Things\u201d detailing your top five observations, accomplishments, or even personal news (like visiting a great restaurant or having a baby), I\u2019ll read it.  \nI end up reading about a hundred of these every morning."
                ]
            },
            {
                "id": "11008",
                "label": "Spontaneous Update",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "An informal update shared on an ad hoc basis rather than through formal reporting channels.",
                "references": [
                    "I prefer spontaneous updates. I prefer spontaneous updates."
                ]
            },
            {
                "id": "11009",
                "label": "Information Over Transmission",
                "color": "#d3d3d3",
                "shape": "ellipse",
                "info": "A conceptual approach that emphasizes the quality of information over quantity, cautioning against over-sharing.",
                "references": [
                    "I think of it as an \u201cIOT\u201d (information over transmission)."
                ]
            },
            {
                "id": "11010",
                "label": "Organizational Concept",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing structures and hierarchies within an organization.",
                "references": []
            },
            {
                "id": "11011",
                "label": "Human Role",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing roles held by individuals in an organization.",
                "references": []
            },
            {
                "id": "11012",
                "label": "Information",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing data, facts, and insights.",
                "references": []
            },
            {
                "id": "11013",
                "label": "Communication",
                "color": "#cccccc",
                "shape": "ellipse",
                "info": "An abstract concept representing the exchange of information among entities.",
                "references": []
            }
        ],
        "edges": [
            {
                "source": "4",
                "target": "6",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Utilizes",
                "info": "Deep Learning utilizes GPU to achieve high performance.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ]
            },
            {
                "source": "8",
                "target": "9",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Uses Technique",
                "info": "Deep Neural Networks employ backpropagation for training.",
                "references": [
                    "you could backprop and learn it."
                ]
            },
            {
                "source": "4",
                "target": "11",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Leads To",
                "info": "Deep Learning led to the emergence of Software 2.0.",
                "references": [
                    "Since then, Andrej Karpathy has called it Software 2.0, but we recognized it from the start as a new way of doing software, even suggesting that maybe we needed to change computing altogether."
                ]
            },
            {
                "source": "5",
                "target": "4",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Catalyzes",
                "info": "The ImageNet Contest catalyzed advances in deep learning.",
                "references": [
                    "several pockets of researchers were simultaneously trying to submit for ImageNet\u2014that big contest in 2012."
                ]
            },
            {
                "source": "6",
                "target": "12",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "GPU enables accelerated computing.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ]
            },
            {
                "source": "12",
                "target": "13",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Modernizes",
                "info": "Accelerated Computing modernizes legacy architectures like IBM System/360.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ]
            },
            {
                "source": "12",
                "target": "14",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Foreshadows",
                "info": "Accelerated Computing foreshadows breakthrough paradigms akin to Quantum Computing.",
                "references": [
                    "It was almost as if we discovered \u201cquantum computing before quantum computing,\u201d if you will."
                ]
            },
            {
                "source": "8",
                "target": "10",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Realizes",
                "info": "Deep Neural Networks realize the concept of a Universal Function Approximator.",
                "references": [
                    "Imagine having a universal function approximator of any dimensionality."
                ]
            },
            {
                "source": "1013",
                "target": "1016",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Sensor Fusion contributes to the development of NextGen Model Architecture.",
                "references": [
                    "We use this idea for cameras, radars, and lidars\u2014combining multiple sensor modalities so that perception encompasses the strengths of all of them."
                ]
            },
            {
                "source": "1011",
                "target": "1017",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Deployed In",
                "info": "Vision Neural Network is deployed in Vision Deployment System.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ]
            },
            {
                "source": "1005",
                "target": "1015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Precedes",
                "info": "CNN precedes Integrated Multimodal Architecture in evolution.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ]
            },
            {
                "source": "1007",
                "target": "1015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Vision Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "You can create a vision transformer, an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1008",
                "target": "1015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Audio Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1009",
                "target": "1015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Text Transformer benefits from Integrated Multimodal Architecture.",
                "references": [
                    "a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1015",
                "target": "1010",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Benefits From",
                "info": "Integrated Multimodal Architecture benefits from Multimodality.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ]
            },
            {
                "source": "2011",
                "target": "2014",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Grounds in Physical Truths",
                "info": "Physics grounds the embodied robot with physical truths.",
                "references": [
                    "Physics has been described in words\u2014Newton\u2019s laws, for instance."
                ]
            },
            {
                "source": "2015",
                "target": "2014",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Grounds in Ethical Truths",
                "info": "Ethical Truths ground the embodied robot ensuring ethical operations.",
                "references": [
                    "grounded in ethical truths\u2014that\u2019s part of alignment."
                ]
            },
            {
                "source": "2014",
                "target": "2013",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Receives Physical Feedback From",
                "info": "Embodied Robot receives simulated physical feedback from Omniverse.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ]
            },
            {
                "source": "2010",
                "target": "2011",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Informs",
                "info": "World Model informs Physics by providing conceptual grounding.",
                "references": [
                    "derived this world model from language and can apply it to a very wide context.  \nBut you also spoke about teaching these models physics.  \nDo you think that's needed, or could we derive it as these models become multimodal?  \nWill they be able to learn that from the training data as they get trained on video, for example?", "**Jensen:**  \nYeah, I think you can.  \nLet\u2019s see.  \nYou can imagine that just about everything has been described in words.  \nPhysics has been described in words\u2014Newton\u2019s laws, for instance."
                ]
            },
            {
                "source": "2012",
                "target": "2013",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Delivers Through",
                "info": "Reinforcement Learning Physical Feedback is delivered through Omniverse.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ]
            },
            {
                "source": "2014",
                "target": "2016",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Receives Ethical Guidance From",
                "info": "Embodied Robot receives ethical guidance from Alignment.",
                "references": [
                    "that\u2019s part of alignment.  \nBoth are essential for creating safer chatbots."
                ]
            },
            {
                "source": "3005",
                "target": "3009",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Augments",
                "info": "Symbolic Reasoning augments Learned Models.",
                "references": [
                    "I think that this kind of symbolic reasoning could augment these exhaustively learned models.  \nSo while I can\u2019t say for sure what the limits will be, it seems intuitive that we\u2019ll always need to supplement our learned models with aspects of intelligence derived from rules and experience."
                ]
            },
            {
                "source": "3007",
                "target": "3008",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Compute Scaling offers an alternative approach compared to Radical Breakthroughs.",
                "references": [
                    "evolving them slightly and scaling compute\u2014or will we need radical breakthroughs"
                ]
            },
            {
                "source": "4012",
                "target": "4010",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Coexists With",
                "info": "Superintelligence coexists with Human Intellect.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence.  \nRelative to me, these people can do things I can\u2019t imagine, yet we coexist quite harmoniously"
                ]
            },
            {
                "source": "4014",
                "target": "4015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Bridges",
                "info": "Human Language Programming bridges the Digital Divide by making programming accessible.",
                "references": [
                    "With ChatGPT and similar tools, the programming language of choice is human language.  \nYou can program in Swedish, I can program in English, and you can have it write a program to program another computer.  \nThis democratizes programming for the very first time in history.  \nI truly believe this will empower billions of people by giving them access to an instrument of value creation and productivity.  \nThis incredible, light-speed machine we call the computer is now accessible to everyone, and I\u2019m hopeful it will bridge the digital divide"
                ]
            },
            {
                "source": "4011",
                "target": "4010",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enhances",
                "info": "Augmentation enhances Human Intellect by improving cognitive performance.",
                "references": [
                    "If we could provide researchers with tools to run thousands of parallel experiments and be augmented by that rather than replaced"
                ]
            },
            {
                "source": "4014",
                "target": "4013",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Empowers",
                "info": "Human Language Programming empowers Democratized Intelligence by expanding accessibility to the production of intelligence.",
                "references": [
                    "I truly believe this will empower billions of people by giving them access to an instrument of value creation and productivity"
                ]
            },
            {
                "source": "4016",
                "target": "4015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Bridges Access",
                "info": "The Computer bridges access gaps by making computing accessible to all.",
                "references": [
                    "This incredible, light-speed machine we call the computer is now accessible to everyone, and I\u2019m hopeful it will bridge the digital divide"
                ]
            },
            {
                "source": "5005",
                "target": "5004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Design Space Exploration contributes to the AI-Driven Chip Design Process.",
                "references": [
                    "So we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities."
                ]
            },
            {
                "source": "5006",
                "target": "5004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Contributes To",
                "info": "Optimization Trade-Offs contributes to the AI-Driven Chip Design Process.",
                "references": [
                    "Sometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount."
                ]
            },
            {
                "source": "5008",
                "target": "5004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Challenges",
                "info": "Module Interconnection Complexity challenges the AI-Driven Chip Design Process.",
                "references": [
                    "The complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe."
                ]
            },
            {
                "source": "5007",
                "target": "5004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Realizes",
                "info": "Hopper Generation Chip realizes the outcomes of the AI-Driven Chip Design Process.",
                "references": [
                    "That\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before."
                ]
            },
            {
                "source": "5026",
                "target": "5004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enhances",
                "info": "Advanced Compute Acceleration enhances the AI-Driven Chip Design Process.",
                "references": [
                    "I think that's really fascinating as well\u2014how you\u2019re accelerating compute at NVIDIA by using AI to develop more advanced compute."
                ]
            },
            {
                "source": "6005",
                "target": "6006",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Relies On",
                "info": "Defensible Business relies on Domain Expertise.",
                "references": [
                    "Last time we spoke, we discussed the value of deep domain expertise and integrating into workflows."
                ]
            },
            {
                "source": "6005",
                "target": "6007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Relies On",
                "info": "Defensible Business relies on Customer Challenges.",
                "references": [
                    "Well, I don\u2019t think there\u2019s any evidence that understanding a domain or a specific set of customer challenges ever loses its value.  \nOur technology\u2014and the world\u2019s pool of educated people\u2014is growing.  \nToday\u2019s new college graduates can tackle problems that entire companies used to solve.  \nAll evidence suggests that deeply understanding your customer\u2019s challenges remains extremely valuable."
                ]
            },
            {
                "source": "6008",
                "target": "6012",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Is Vulnerable To",
                "info": "Application Companies are vulnerable to commoditization.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ]
            },
            {
                "source": "7011",
                "target": "7012",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Drives Innovation In",
                "info": "Enhancing Product Development drives innovation in enabling new product capabilities.",
                "references": [
                    "1. **Enhancing Product Development:** What can AI do to revolutionize the work we do in building our products?  \n   \u2014 For example, the way we design chips has completely changed.  \n2. **Enabling New Product Capabilities:** What can AI enable us to do so that our products themselves become different?  \n   \u2014 In the past, building a graphics card meant designing programmable shaders and compilers. Now, you can\u2019t ship a GeForce GPU by itself because there\u2019s a \u201csupercomputer\u201d in the background learning to predict missing pixels through denoising and inference\u2014imagine inferring 8 to 16 pixels for every pixel rendered, like completing a jigsaw puzzle.  \n   \u2014 We update the algorithms continuously by downloading improvements as they become available, which not only revolutionizes the design of our GPUs but also how they produce images\u2014leading to processors that are far more energy efficient than Moore\u2019s law would predict."
                ]
            },
            {
                "source": "7013",
                "target": "7011",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Empowers Through Insight",
                "info": "Augmenting the Company empowers improvements in product development through insight.",
                "references": [
                    "3. **Augmenting the Company:** Turning the entire company into an AI-augmented organization.  \n   \u2014 This means every employee is empowered by a system that continuously analyzes information, connects the dots, and even predicts changes in the market or supply chain\u2014signals that would be impossible for any one person to see."
                ]
            },
            {
                "source": "8004",
                "target": "8005",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Participates In Conversation With",
                "info": "Establishes a conversational link between Joel and Jensen.",
                "references": [
                    "**Joel:**  \nYeah, exactly."
                ]
            },
            {
                "source": "8007",
                "target": "8008",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Connects Prompt Engineering with Large Language Models as integrated technologies.",
                "references": [
                    "Take prompt engineering, for example\u2014it\u2019s already coming to life."
                ]
            },
            {
                "source": "8008",
                "target": "8009",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Highlights integration between Large Language Models and Reinforcement Learning Human Feedback for improved safety.",
                "references": [
                    "incorporating technologies for building guardrails, alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed)"
                ]
            },
            {
                "source": "8008",
                "target": "8010",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Demonstrates integration between Large Language Models and Vector Databases for factual anchoring.",
                "references": [
                    "and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ]
            },
            {
                "source": "8011",
                "target": "8012",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Shows integration between Generative Adversarial Models and Variational Autoencoders.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related,"
                ]
            },
            {
                "source": "8012",
                "target": "8013",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Indicates integration between Variational Autoencoders and Diffusion Models.",
                "references": [
                    "variational autoencoders, and diffusion models were all related,"
                ]
            },
            {
                "source": "8006",
                "target": "8008",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Integrates With",
                "info": "Connects Self-driving Cars with Large Language Models to imply shared advanced safety practices.",
                "references": [
                    "In self-driving cars, for example, we dedicate as many resources to making the AI and the car safe as we do to making the car drive."
                ]
            },
            {
                "source": "8014",
                "target": "8015",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Establishes a collaborative relationship between Climate Research and Digital Biology within industry domains.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ]
            },
            {
                "source": "8016",
                "target": "8017",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Collaborates With",
                "info": "Connects Web Design and Programmatic Ads as collaborative industry entities.",
                "references": [
                    "entire professions like web design or programmatic ads\u2014jobs that didn\u2019t exist when I finished school 40 years ago?"
                ]
            },
            {
                "source": "8004",
                "target": "8028",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Should Invest In",
                "info": "Joel should invest in Atlaz.",
                "references": [
                    ""
                ]
            },
            {
                "source": "9006",
                "target": "9007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Flat Organization enables Agility.",
                "references": [
                    "To empower people, you want a flat organization rather than a pyramid (like the old military or the Roman Empire).  \nIn a flat organization, information travels quickly.  \nThat means the first layer\u2014the most senior people\u2014needs to be carefully chosen, because they need the least management.  \nNone of my management team comes to me for career advice\u2014they\u2019ve already \u201cmade it\u201d and are doing great.  \nI have many people reporting to me, but I don\u2019t need to do one-on-ones or career coaching because everyone is an expert in their field.  \nWhen there\u2019s a strategic direction, I communicate it to everyone at the same time so we can all provide feedback and refine it together.  \nBecause the company is so flat and everyone is empowered with information, we\u2019re extremely agile."
                ]
            },
            {
                "source": "9005",
                "target": "9004",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Attracts",
                "info": "Ambitious Computing Challenges attracts Attracting Amazing People.",
                "references": [
                    "and we attract people who want to invent a new form of computing to solve these challenges."
                ]
            },
            {
                "source": "9009",
                "target": "9010",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Reinforces",
                "info": "Direct Communication reinforces Transparent Leadership.",
                "references": [
                    "I don\u2019t take anyone aside or schedule one-on-one coaching sessions.  \nIf something\u2019s not right, I speak up.  \nIt might come off as too direct sometimes, but if people understand I\u2019m only trying to be clear, it works.  \nI spend a lot of time explaining my thought process in meetings\u2014reasoning through decisions, comparing and contrasting ideas\u2014which empowers employees because they see how leaders think."
                ]
            },
            {
                "source": "9006",
                "target": "9011",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Facilitates",
                "info": "Flat Organization facilitates Unified Collaboration.",
                "references": [
                    "we\u2019re all sitting together\u2014it\u2019s like having one big office."
                ]
            },
            {
                "source": "10004",
                "target": "10005",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Guides",
                "info": "Strategy guides continuous planning as execution of strategy requires adaptable planning.",
                "references": [
                    "First of all, strategy isn\u2019t just words\u2014it\u2019s action.  \nIf the company has a strategy but people\u2019s \u201cTop Five Things\u201d don\u2019t align with it, then they\u2019re not really executing the strategy.  \nI get a sense of our direction by sporadically sampling everyone\u2019s updates rather than reading every single one every week.  \nSecond, we don\u2019t have a periodic planning system.  \nThe world is a living, breathing thing, so we plan continuously."
                ]
            },
            {
                "source": "10005",
                "target": "10006",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Incorporates",
                "info": "Continuous Planning incorporates foundational reasoning for dynamic decision-making.",
                "references": [
                    "The world is a living, breathing thing, so we plan continuously.  \nThere\u2019s no fixed five-year plan, no one-year plan\u2014just what we\u2019re doing right now.","**Joel:**  \nThat\u2019s really exciting to hear.  \nI think one challenge when executing on first principles is trusting your intuition\u2014especially when it goes against the conventional playbook.  \nWhat made you trust your intuition on some of these things?", "**Jensen:**  \nAlmost everything the company pursues is reasoned through first principles."
                ]
            },
            {
                "source": "10006",
                "target": "10007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Inspires",
                "info": "First Principles Reasoning inspires effective leadership through adaptability.",
                "references": [
                    "Almost everything the company pursues is reasoned through first principles.  \n*(Joel: \u201cYeah.\u201d)*  \nYou start with the foundational assumptions that led you to believe the computer had to change, that chip architecture needed to evolve, that software development had to be reimagined, or that data centers would transform.  \nFor example, a data center used to be just a place to store files.  \nIn the future, one data center might be a factory for producing intelligence\u2014where data is refined into this invaluable, invisible asset we call \u201cintelligence.\u201d  \nThat process will continuously drive innovation.  \nYou reason from these first principles and then pursue them with enough dedication and conviction\u2014even if it\u2019s really hard.  \nAnd if you\u2019re wrong, you simply change your mind.  \nModern leadership is all about adaptability:"
                ]
            },
            {
                "source": "10007",
                "target": "10008",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Prevents",
                "info": "Leadership prevents engagement in commodity work to keep focus on innovation.",
                "references": [
                    "The most important is to prevent people from having to do commodity work."
                ]
            },
            {
                "source": "11005",
                "target": "11007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Sends",
                "info": "Individual contributors send their \u2018Top Five Things\u2019 emails as a way to communicate top priorities.",
                "references": [
                    "If you send an email called \u201cTop Five Things\u201d detailing your top five observations, accomplishments, or even personal news (like visiting a great restaurant or having a baby), I\u2019ll read it.  \nI end up reading about a hundred of these every morning."
                ]
            },
            {
                "source": "11004",
                "target": "11005",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Excludes",
                "info": "A strict leadership hierarchy can inadvertently exclude key individual contributors.",
                "references": [
                    "I\u2019ve always struggled with excluding the best individual contributors from key meetings."
                ]
            },
            {
                "source": "11005",
                "target": "11006",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Possesses",
                "info": "Individual contributors possess ground truth derived from firsthand experience.",
                "references": [
                    "They should be there because they have the ground truth\u2014the firsthand experience with the problems."
                ]
            },
            {
                "source": "11008",
                "target": "11007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Is Underlying Idea Of",
                "info": "Spontaneous updates are an underlying idea of \u2018Top Five Things\u2019 emails.",
                "references": [
                    "I prefer spontaneous updates. I prefer spontaneous updates."
                ]
            },
            {
                "source": "11009",
                "target": "11007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Is Underlying Idea Of",
                "info": "An information over transmission approach is an underlying idea of \u2018Top Five Things\u2019 emails.",
                "references": [
                    "I think of it as an \u201cIOT\u201d (information over transmission)."
                ]
            },
            {
                "source": "6",
                "target": "1011",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Accelerates",
                "info": "GPU accelerates Vision Neural Network computations.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive because no one had really put GPUs to that use previously."
                ]
            },
            {
                "source": "9006",
                "target": "12",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Flat Organization has enabled Accelerated Computing for NVIDIA.",
                "references": [
                    "This structure has enabled us to build accelerated computing so fast."
                ]
            },
            {
                "source": "10007",
                "target": "10009",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Enables",
                "info": "Leadership enables transparency to foster a culture of openness.",
                "references": [
                    "Additionally, empowerment comes from transparency.  \nWhile many companies are very siloed, I encourage a culture of openness."
                ]
            },
            {
                "source": "1012",
                "target": "3007",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Model Architectures offers an alternative approach compared to Compute Scaling.",
                "references": [
                    "Do you think we can keep running with the existing model architectures\u2014evolving them slightly and scaling compute"
                ]
            },
            {
                "source": "3008",
                "target": "1012",
                "type": "other",
                "color": "#cccccc",
                "arrowhead": "diamond",
                "label": "Competes With",
                "info": "Radical Breakthroughs offers an alternative approach compared to Model Architectures.",
                "references": [
                    "see diminishing returns soon see diminishing returns soon"
                ]
            },
            {
                "source": "7",
                "target": "8",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Deep Neural Networks is a subtype of Neural Networks.",
                "references": [
                    "to learn how to program it for deep neural nets."
                ]
            },
            {
                "source": "105",
                "target": "4",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Deep Learning is classified as an Algorithmic Approach.",
                "references": [
                    "I learned about deep learning about the same time everybody else did, and maybe slightly before that."
                ]
            },
            {
                "source": "102",
                "target": "5",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "ImageNet Contest is classified as a Research Event.",
                "references": [
                    "several pockets of researchers were simultaneously trying to submit for ImageNet\u2014that big contest in 2012."
                ]
            },
            {
                "source": "103",
                "target": "6",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "GPU is classified as a Hardware Component.",
                "references": [
                    "access to the latest GPU (the GeForce GTX 580 had just come out)"
                ]
            },
            {
                "source": "105",
                "target": "7",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Neural Networks is classified as an Algorithmic Approach.",
                "references": [
                    "though back then it was called \u201cneural networks.\u201d"
                ]
            },
            {
                "source": "105",
                "target": "9",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Backpropagation is classified as an Algorithmic Approach.",
                "references": [
                    "you could backprop and learn it."
                ]
            },
            {
                "source": "107",
                "target": "10",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Universal Function Approximator is classified as a Technological Concept.",
                "references": [
                    "Imagine having a universal function approximator of any dimensionality."
                ]
            },
            {
                "source": "104",
                "target": "11",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Software 2.0 is classified as a Software Paradigm.",
                "references": [
                    "Since then, Andrej Karpathy has called it Software 2.0, but we recognized it from the start as a new way of doing software, even suggesting that maybe we needed to change computing altogether."
                ]
            },
            {
                "source": "106",
                "target": "12",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Accelerated Computing is classified as a Computing Architecture.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ]
            },
            {
                "source": "106",
                "target": "13",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "IBM System/360 is classified as a Computing Architecture.",
                "references": [
                    "for the first time in 60 years since IBM\u2019s System/360, accelerated computing using GPUs and deep learning really reinvented computers."
                ]
            },
            {
                "source": "106",
                "target": "14",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Quantum Computing is classified as a Computing Architecture.",
                "references": [
                    "It was almost as if we discovered \u201cquantum computing before quantum computing,\u201d if you will."
                ]
            },
            {
                "source": "1006",
                "target": "1007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Vision Transformer is a type of Transformer.",
                "references": [
                    "You can create a vision transformer, an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1006",
                "target": "1008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Audio Transformer is a type of Transformer.",
                "references": [
                    "an audio transformer, a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1006",
                "target": "1009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Text Transformer is a type of Transformer.",
                "references": [
                    "a text transformer\u2014you can build a transformer out of almost anything it seems."
                ]
            },
            {
                "source": "1012",
                "target": "1006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Transformer is a type of Model Architecture.",
                "references": [
                    "Transformers\u2014and the work researchers have done to reformulate just about every problem, every type of data, into something that a transformer can learn\u2014have been revolutionary."
                ]
            },
            {
                "source": "1012",
                "target": "1011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Vision Neural Network is a type of Model Architecture.",
                "references": [
                    "if you\u2019re training a neural network for vision and you\u2019ve only seen horses but never zebras,"
                ]
            },
            {
                "source": "1035",
                "target": "1012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Model Architecture is a type of Model Architecture Base.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ]
            },
            {
                "source": "1035",
                "target": "1016",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "NextGen Model Architecture is a type of Model Architecture Base.",
                "references": [
                    "Now that we have transformer models expressed in ways that can handle multimodality, I think the next generation of AI models is going to be more performant, safer, more robust, and capable of doing more things."
                ]
            },
            {
                "source": "1035",
                "target": "1015",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Integrated Multimodal Architecture is a type of Model Architecture Base.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ]
            },
            {
                "source": "1031",
                "target": "1017",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Vision Deployment System is a type of System.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ]
            },
            {
                "source": "1034",
                "target": "1013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Sensor Fusion is directly a type of Sensor Integration.",
                "references": [
                    "We use this idea for cameras, radars, and lidars\u2014combining multiple sensor modalities so that perception encompasses the strengths of all of them."
                ]
            },
            {
                "source": "1036",
                "target": "1017",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Vision Deployment System is directly a type of Deployment System Base.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive"
                ]
            },
            {
                "source": "1033",
                "target": "1015",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Integrated Multimodal Architecture is directly a type of Multimodality Mechanism.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ]
            },
            {
                "source": "1033",
                "target": "1010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Multimodality is directly a type of Multimodality Mechanism.",
                "references": [
                    "Multimodality is really important for very clear reasons: higher performance."
                ]
            },
            {
                "source": "2101",
                "target": "2010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "World Model is a subtype of Cognitive Construct.",
                "references": [
                    "derived this world model from language and can apply it to a very wide context."
                ]
            },
            {
                "source": "2102",
                "target": "2011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Physics is a subtype of Physical Concept.",
                "references": [
                    "Physics has been described in words\u2014Newton\u2019s laws, for instance."
                ]
            },
            {
                "source": "2103",
                "target": "2012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Reinforcement Learning Physical Feedback is a subtype of Training Methodology.",
                "references": [
                    "That embodied language model would then receive Reinforcement Learning Physical Feedback\u2014simulated physics feedback\u2014through Omniverse."
                ]
            },
            {
                "source": "2106",
                "target": "2013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Omniverse is a subtype of Physics Simulation Platform.",
                "references": [
                    "We created a system called Omniverse that obeys the laws of physics so we can give it a digital twin of an embodied robot."
                ]
            },
            {
                "source": "2105",
                "target": "2014",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Embodied Robot is a subtype of Robot.",
                "references": [
                    "digital twin of an embodied robot.  \nThat embodied language model would then receive"
                ]
            },
            {
                "source": "2104",
                "target": "2015",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Ethical Truths is a subtype of Ethical Principle.",
                "references": [
                    "grounded in ethical truths\u2014that\u2019s part of alignment."
                ]
            },
            {
                "source": "2104",
                "target": "2016",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Alignment is a subtype of Ethical Principle.",
                "references": [
                    "that\u2019s part of alignment.  \nBoth are essential for creating safer chatbots."
                ]
            },
            {
                "source": "3010",
                "target": "3004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Scaling AI is a subtype of AI Improvement Strategy.",
                "references": [
                   
                ]
            },
            {
                "source": "3010",
                "target": "3005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Symbolic Reasoning is a subtype of AI Improvement Strategy.",
                "references": [
                    "I think that this kind of symbolic reasoning could augment these exhaustively learned models.  \nSo while I can\u2019t say for sure what the limits will be, it seems intuitive that we\u2019ll always need to supplement our learned models with aspects of intelligence derived from rules and experience."
                ]
            },
            {
                "source": "3004",
                "target": "3007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Compute Scaling is a subtype of Scaling AI.",
                "references": [
                    "evolving them slightly and scaling compute\u2014or will we need radical breakthroughs"
                ]
            },
            {
                "source": "3004",
                "target": "3008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Radical Breakthroughs is a subtype of Scaling AI.",
                "references": [
                    "or will we need radical breakthroughs and see diminishing returns soon"
                ]
            },
            {
                "source": "3011",
                "target": "3009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Learned Models is a subtype of AI Model Type.",
                "references": [
                    "augment these exhaustively learned models"
                ]
            },
            {
                "source": "4106",
                "target": "4010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Human Intellect is a subtype of Intelligence.",
                "references": [
                    "one thing we're really excited about at Sana is the possibility to augment human intellect"
                ]
            },
            {
                "source": "4101",
                "target": "4010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Human Intellect is directly a subtype of Cognitive Ability.",
                "references": [
                    "one thing we're really excited about at Sana is the possibility to augment human intellect"
                ]
            },
            {
                "source": "4106",
                "target": "4012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Superintelligence is a subtype of Intelligence.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence"
                ]
            },
            {
                "source": "4101",
                "target": "4012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Superintelligence is directly a subtype of Cognitive Ability.",
                "references": [
                    "I already live in an environment surrounded by what I\u2019d call superintelligence"
                ]
            },
            {
                "source": "4106",
                "target": "4013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Democratized Intelligence is a subtype of Intelligence.",
                "references": [
                    "I believe we\u2019re now democratizing intelligence\u2014the production of intelligence"
                ]
            },
            {
                "source": "4101",
                "target": "4013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Democratized Intelligence is directly a subtype of Cognitive Ability.",
                "references": [
                    "I believe we\u2019re now democratizing intelligence\u2014the production of intelligence"
                ]
            },
            {
                "source": "4102",
                "target": "4011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Augmentation is a subtype of the Augmentation Process.",
                "references": [
                    "If we could provide researchers with tools to run thousands of parallel experiments and be augmented by that rather than replaced"
                ]
            },
            {
                "source": "4103",
                "target": "4014",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Human Language Programming is a subtype of Programming Paradigm.",
                "references": [
                    "With ChatGPT and similar tools, the programming language of choice is human language"
                ]
            },
            {
                "source": "4104",
                "target": "4015",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Digital Divide is a subtype of Access Gap.",
                "references": [
                    "I\u2019m hopeful it will bridge the digital divide"
                ]
            },
            {
                "source": "4107",
                "target": "4016",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "The Computer is a subtype of Computing Device.",
                "references": [
                    "This incredible, light-speed machine we call the computer is now accessible to everyone"
                ]
            },
            {
                "source": "5009",
                "target": "5004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "AI-Driven Chip Design Process is a subtype of Chip Design Process.",
                "references": [
                    "Well, our current generation of chips is so large and complex that all the employees in our company can\u2019t possibly design them from scratch.  \nSo we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities.  \nSometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount.  \nWe set these kinds of judgments and then let the AI explore all the corner cases and design permutations for us.  \nIt comes back with a design that no human could have done alone.  \nThat\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before.  \nThe complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe.  \nOnly an AI could figure out the best optimization for that."
                ]
            },
            {
                "source": "5010",
                "target": "5005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Design Space Exploration is a subtype of Chip Design Component.",
                "references": [
                    "So we use AI to help explore the design space\u2014experimenting with hundreds of thousands of permutations to find the design with the best trade-offs.  \nThere\u2019s no perfect optimization; there\u2019s just an optimization that\u2019s best given a set of priorities."
                ]
            },
            {
                "source": "5010",
                "target": "5006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Optimization Trade-Offs is a subtype of Chip Design Component.",
                "references": [
                    "Sometimes we might choose to optimize for speed\u2014if it\u2019s a critical path that must be instantiated thousands of times.  \nOther times, energy efficiency is paramount."
                ]
            },
            {
                "source": "5011",
                "target": "5007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Hopper Generation Chip is a subtype of Chip Product.",
                "references": [
                    "That\u2019s quite amazing\u2014our latest Hopper generation, for example, includes designs that no human has ever conceived before."
                ]
            },
            {
                "source": "5012",
                "target": "5008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Module Interconnection Complexity is a subtype of Chip Design Challenge.",
                "references": [
                    "The complexity of connecting thousands of modules on a chip is such that the number of possible combinations far exceeds the atoms in the universe."
                ]
            },
            {
                "source": "5010",
                "target": "5026",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Advanced Compute Acceleration is a subtype of Chip Design Component.",
                "references": [
                    "I think that's really fascinating as well\u2014how you\u2019re accelerating compute at NVIDIA by using AI to develop more advanced compute."
                ]
            },
            {
                "source": "6009",
                "target": "6004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Business is a type of Business Concept.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ]
            },
            {
                "source": "6004",
                "target": "6005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Defensible Business is a type of Business.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ]
            },
            {
                "source": "6010",
                "target": "6006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Domain Expertise is a type of Competitive Advantage Factor.",
                "references": [
                    "Last time we spoke, we discussed the value of deep domain expertise and integrating into workflows."
                ]
            },
            {
                "source": "6010",
                "target": "6007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Customer Challenges is a type of Competitive Advantage Factor.",
                "references": [
                    "Well, I don\u2019t think there\u2019s any evidence that understanding a domain or a specific set of customer challenges ever loses its value.  \nOur technology\u2014and the world\u2019s pool of educated people\u2014is growing.  \nToday\u2019s new college graduates can tackle problems that entire companies used to solve.  \nAll evidence suggests that deeply understanding your customer\u2019s challenges remains extremely valuable."
                ]
            },
            {
                "source": "6009",
                "target": "6010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Competitive Advantage Factor is a type of Business Concept.",
                "references": [
                    "One other question I was really intrigued by: How should we think about defensibility?  \nLast time we spoke, we discussed the value of deep domain expertise and integrating into workflows.  \nBut for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply.  \nHow should we think about building a defensible business?"
                ]
            },
            {
                "source": "6004",
                "target": "6008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Application Companies is a type of Business.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ]
            },
            {
                "source": "6009",
                "target": "6012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Commoditization is a type of Business Concept.",
                "references": [
                    "But for companies operating at the application layer, as these models become increasingly commoditized and require less data, many historical moats don\u2019t seem to apply."
                ]
            },
            {
                "source": "7010",
                "target": "7011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Enhancing Product Development is a subtype of AI Application Layer.",
                "references": [
                    "1. **Enhancing Product Development:** What can AI do to revolutionize the work we do in building our products?  \n   \u2014 For example, the way we design chips has completely changed."
                ]
            },
            {
                "source": "7010",
                "target": "7012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Enabling New Product Capabilities is a subtype of AI Application Layer.",
                "references": [
                    "2. **Enabling New Product Capabilities:** What can AI enable us to do so that our products themselves become different?  \n   \u2014 In the past, building a graphics card meant designing programmable shaders and compilers. Now, you can\u2019t ship a GeForce GPU by itself because there\u2019s a \u201csupercomputer\u201d in the background learning to predict missing pixels through denoising and inference\u2014imagine inferring 8 to 16 pixels for every pixel rendered, like completing a jigsaw puzzle.  \n   \u2014 We update the algorithms continuously by downloading improvements as they become available, which not only revolutionizes the design of our GPUs but also how they produce images\u2014leading to processors that are far more energy efficient than Moore\u2019s law would predict."
                ]
            },
            {
                "source": "7010",
                "target": "7013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Augmenting the Company is a subtype of AI Application Layer.",
                "references": [
                    "3. **Augmenting the Company:** Turning the entire company into an AI-augmented organization.  \n   \u2014 This means every employee is empowered by a system that continuously analyzes information, connects the dots, and even predicts changes in the market or supply chain\u2014signals that would be impossible for any one person to see."
                ]
            },
            {
                "source": "8018",
                "target": "8004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Designates Joel as a subtype of Speaker.",
                "references": [
                    "**Joel:**  \nAnd what do you think is your most contrarian view on AI right now?"
                ]
            },
            {
                "source": "8018",
                "target": "8005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Designates Jensen as a subtype of Speaker.",
                "references": [
                    "**Jensen:**  \nI don\u2019t think I have anything particularly contrarian."
                ]
            },
            {
                "source": "8019",
                "target": "8006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Self-driving Cars as a subtype of Technology.",
                "references": [
                    "In self-driving cars, for example, we dedicate as many resources to making the AI and the car safe as we do to making the car drive."
                ]
            },
            {
                "source": "8019",
                "target": "8007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Prompt Engineering as a subtype of Technology.",
                "references": [
                    "Take prompt engineering, for example\u2014it\u2019s already coming to life."
                ]
            },
            {
                "source": "8019",
                "target": "8008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Large Language Models as a subtype of Technology.",
                "references": [
                    "I think large language models will have to do the same\u2014incorporating technologies for building guardrails, alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed), and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ]
            },
            {
                "source": "8019",
                "target": "8009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Reinforcement Learning Human Feedback as a subtype of Technology.",
                "references": [
                    "alignment (such as Reinforcement Learning Human Feedback and even physical feedback as we discussed)"
                ]
            },
            {
                "source": "8019",
                "target": "8010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Vector Databases as a subtype of Technology.",
                "references": [
                    "and even vector databases to reduce hallucinations by anchoring outputs with facts."
                ]
            },
            {
                "source": "8019",
                "target": "8011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Generative Adversarial Models as a subtype of Technology.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related,"
                ]
            },
            {
                "source": "8019",
                "target": "8012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Variational Autoencoders as a subtype of Technology.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related,"
                ]
            },
            {
                "source": "8019",
                "target": "8013",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Diffusion Models as a subtype of Technology.",
                "references": [
                    "our early work with generative adversarial models, variational autoencoders, and diffusion models were all related,"
                ]
            },
            {
                "source": "8020",
                "target": "8014",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Climate Research as a subtype of Industry.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ]
            },
            {
                "source": "8020",
                "target": "8015",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Digital Biology as a subtype of Industry.",
                "references": [
                    "from self-driving cars to climate research to digital biology"
                ]
            },
            {
                "source": "8020",
                "target": "8016",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Web Design as a subtype of Industry.",
                "references": [
                    "entire professions like web design"
                ]
            },
            {
                "source": "8020",
                "target": "8017",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Assigns Programmatic Ads as a subtype of Industry.",
                "references": [
                    "programmatic ads programmatic ads"
                ]
            },
            {
                "source": "8027",
                "target": "8028",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Atlaz is a type of Company.",
                "references": [
                    ""
                ]
            },
            {
                "source": "9022",
                "target": "9004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Attracting Amazing People' as a type of 'Company Objective'.",
                "references": [
                    "First, I wanted to create a company that naturally attracts amazing people because we\u2019re solving computing problems that are barely possible."
                ]
            },
            {
                "source": "9021",
                "target": "9005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Ambitious Computing Challenges' as a type of 'Technical Ambition'.",
                "references": [
                    "If a problem could be solved by normal computers, we wouldn\u2019t bother with it."
                ]
            },
            {
                "source": "9020",
                "target": "9006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Flat Organization' as a type of 'Organizational Practice'.",
                "references": [
                    "To empower people, you want a flat organization rather than a pyramid (like the old military or the Roman Empire)."
                ]
            },
            {
                "source": "9020",
                "target": "9007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Agility' as a type of 'Organizational Practice'.",
                "references": [
                    "Because the company is so flat and everyone is empowered with information, we\u2019re extremely agile."
                ]
            },
            {
                "source": "9020",
                "target": "9009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Direct Communication' as a type of 'Organizational Practice'.",
                "references": [
                    "I don\u2019t take anyone aside or schedule one-on-one coaching sessions.  \nIf something\u2019s not right, I speak up."
                ]
            },
            {
                "source": "9020",
                "target": "9010",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Transparent Leadership' as a type of 'Organizational Practice'.",
                "references": [
                    "I spend a lot of time explaining my thought process in meetings\u2014reasoning through decisions, comparing and contrasting ideas\u2014which empowers employees because they see how leaders think."
                ]
            },
            {
                "source": "9020",
                "target": "9011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Unified Collaboration' as a type of 'Organizational Practice'.",
                "references": [
                    "we\u2019re all sitting together\u2014it\u2019s like having one big office."
                ]
            },
            {
                "source": "11010",
                "target": "11004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "LeadershipHierarchy is defined as a subtype of OrganizationalConcept.",
                "references": [
                    "Exactly. That\u2019s one thing I find intriguing because many traditional playbooks emphasize a strict leadership hierarchy."
                ]
            },
            {
                "source": "11011",
                "target": "11005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "IndividualContributor is defined as a subtype of HumanRole.",
                "references": [
                    "I\u2019ve always struggled with excluding the best individual contributors from key meetings."
                ]
            },
            {
                "source": "11012",
                "target": "11006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "GroundTruth is identified as a subtype of Information.",
                "references": [
                    "They should be there because they have the ground truth\u2014the firsthand experience with the problems."
                ]
            },
            {
                "source": "11013",
                "target": "11007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "TopFiveThings is defined as a subtype of Communication.",
                "references": [
                    "If you send an email called \u201cTop Five Things\u201d detailing your top five observations, accomplishments, or even personal news (like visiting a great restaurant or having a baby), I\u2019ll read it.  \nI end up reading about a hundred of these every morning."
                ]
            },
            {
                "source": "11013",
                "target": "11008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Spontaneous Update is defined as a subtype of Communication.",
                "references": [
                    "I prefer spontaneous updates. I prefer spontaneous updates."
                ]
            },
            {
                "source": "11013",
                "target": "11009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Information Over Transmission is defined as a subtype of Communication.",
                "references": [
                    "I think of it as an \u201cIOT\u201d (information over transmission)."
                ]
            },
            {
                "source": "1030",
                "target": "6",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "GPU is directly a type of Hardware.",
                "references": [
                    "I remember reading the paper from Ilya Sutskever and how they had started deploying it, and it was incredibly impressive because no one had really put GPUs to that use previously."
                ]
            },
            {
                "source": "9020",
                "target": "12",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Classify 'Accelerated Computing' as a type of 'Organizational Practice'.",
                "references": [
                    "This structure has enabled us to build accelerated computing so fast."
                ]
            },
            {
                "source": "1007",
                "target": "1005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "CNN is directly a type of Neural Network.",
                "references": [
                    "Back then it was CNNs; now we\u2019re seeing increasing bets on multimodality."
                ]
            },
            {
                "source": "7",
                "target": "1011",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Vision Neural Network is directly a type of Neural Network.",
                "references": [
                    "if you\u2019re training a neural network for vision and you\u2019ve only seen horses but never zebras,"
                ]
            },
            {
                "source": "9020",
                "target": "10004",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Strategy is categorized as a form of organizational practice.",
                "references": [
                    "First of all, strategy isn\u2019t just words\u2014it\u2019s action."
                ]
            },
            {
                "source": "9020",
                "target": "10005",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Continuous Planning is a form of organizational practice.",
                "references": [
                    "Second, we don\u2019t have a periodic planning system.  \nThe world is a living, breathing thing, so we plan continuously.  \nThere\u2019s no fixed five-year plan, no one-year plan\u2014just what we\u2019re doing right now."
                ]
            },
            {
                "source": "9020",
                "target": "10006",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "First Principles Reasoning is a form of organizational practice.",
                "references": [
                    "Almost everything the company pursues is reasoned through first principles."
                ]
            },
            {
                "source": "9020",
                "target": "10007",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Leadership is a form of organizational practice.",
                "references": [
                    "That\u2019s the mission of leaders\u2014to create an environment that empowers others to do their life\u2019s work."
                ]
            },
            {
                "source": "9020",
                "target": "10008",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Commodity Work is a recognized aspect of organizational practice to be avoided.",
                "references": [
                    "The most important is to prevent people from having to do commodity work."
                ]
            },
            {
                "source": "9020",
                "target": "10009",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Transparency is a form of organizational practice that enables empowerment.",
                "references": [
                    "Additionally, empowerment comes from transparency.  \nWhile many companies are very siloed, I encourage a culture of openness."
                ]
            },
            {
                "source": "3004",
                "target": "1012",
                "type": "subtype",
                "color": "#cccccc",
                "arrowhead": "normal",
                "label": "Is A Type Of",
                "info": "Model Architectures is a subtype of Scaling AI.",
                "references": [
                    "Do you think we can keep running with the existing model architectures\u2014evolving them slightly and scaling compute"
                ]
            }
        ],
        "categories": [
            {
                "name": "Entity",
                "color": "#cccccc"
            }
        ]
    }
};