// src/components/common/Header.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../../utils/firebase';

const Header = ({ user, onSignOut }) => {
  const location = useLocation();
  const linkBaseClasses = "px-4 py-2 rounded-md text-sm font-medium transition";
  
  // Set nav link backgrounds to white with a subtle hover effect.
  const activeClasses = "bg-white text-gray-700";
  const defaultClasses = "bg-white text-gray-700 hover:bg-gray-100";
  const isActive = (path) => location.pathname === path;

  // Hide header when path starts with /species
  const hideHeader = location.pathname.startsWith('/species');
  if (hideHeader) return null;

  return (
    <header className="bg-white shrink-0 relative">
      {/* Center the nav links */}
      <div className="w-full px-6 py-4 flex items-center justify-center">
        <nav className="flex space-x-4 items-center">
        
         
          {user && (

            <>
              <Link
                to="/builder"
                className={`${linkBaseClasses} ${isActive('/builder') ? activeClasses : defaultClasses}`}
              >
                Builder
              </Link>
            {/*
              <Link
                to="/exp-builder"
                className={`${linkBaseClasses} ${isActive('/exp-builder') ? activeClasses : defaultClasses}`}
              >
                Chat
              </Link>
            */}
            </>
          )}
          
          {/*
        
          <Link
            to="/docs"
            className={`${linkBaseClasses} ${isActive('/docs') ? activeClasses : defaultClasses}`}
          >
            Docs
          </Link>
          */}
          <Link
            to="/about"
            className={`${linkBaseClasses} ${isActive('/about') ? activeClasses : defaultClasses}`}
          >
            About
          </Link>
          {user ? (
            <>
              <Link
                to="/manage-account"
                className={`${linkBaseClasses} ${isActive('/manage-account') ? activeClasses : defaultClasses}`}
              >
                Account
              </Link>
              <button onClick={onSignOut} className={`${linkBaseClasses} ${defaultClasses}`}>
                Logout
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className={`${linkBaseClasses} ${isActive('/login') ? activeClasses : defaultClasses}`}
            >
              Login
            </Link>
          )}
        </nav>
      </div>
      {/* Position the logo to the left */}
      <div className="absolute left-6 top-1/2 transform -translate-y-1/2">
        <Link to="/home">
          <img
            src="/logo.png"
            alt="Logo"
            className="h-auto w-auto mr-3 mix-blend-multiply"
            style={{ maxHeight: '40px' }}
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;