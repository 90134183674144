// src/utils/highlightMatches.js

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Single-term highlighting:
 *   highlightMatches(text, query) 
 */
export function highlightMatches(text, query) {
    // Convert < and > to HTML entities
    let safe = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    
    // If no query, return safe text as-is (no \n -> <br/> here!)
    if (!query.trim()) {
      return safe;
    }
  
    // Escape special regex chars in query
    const escaped = query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    const regex = new RegExp(`(${escaped})`, 'gi');
  
    // Wrap matches in <mark>
    let replaced = safe.replace(regex, '<mark class="bg-green-300">$1</mark>');
    
    // Return replaced text (no .replace(/\n/g, '<br/>'))
    return replaced;
  }
  // utils/highlightMatches.js (example)

export function highlightMultipleMatches(originalText, queries) {
  if (!queries || queries.length === 0) {
    return originalText;
  }

  // Sort queries by length descending if you want longer queries highlighted first
  // (optional but can help avoid nested highlight issues)
  const sortedQueries = [...queries].sort((a, b) => {
    return (b.query.length - a.query.length)
  })

  let result = originalText;

  for (const q of sortedQueries) {
    // 1) Escape regex special chars
    const escaped = q.query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // 2) Replace all runs of whitespace with \s+
    //    This means "Temporal monitoring" -> "Temporal\s+monitoring"
    const spaced = escaped.replace(/\s+/g, "\\s+");

    // 3) Build the regex. 
    //    - Use 'i' for case-insensitive if desired
    //    - Use 'g' for multiple matches
    //    - \s should already match newlines, so we don’t strictly need the "s" (dotAll) flag
    const regex = new RegExp(spaced, "gi");

    // 4) The <mark> to inject. We highlight the entire matched substring ($&)
    const replacement = `<mark style="background-color: ${q.color};" class="${q.className}">$&</mark>`;

    // 5) Perform the replacement
    result = result.replace(regex, replacement);
  }

  return result;
}

  
  /**
   * Multi-term highlighting:
   *   highlightMultipleMatches(html, queries)
   *   queries = [ { query: "some text", color: "#ffe599" }, ... ]
   */
  export function highlightMultipleMatches2(html, queries) {
    if (!Array.isArray(queries)) return html;
    
    let highlightedHtml = html;
    queries.forEach((query) => {
      if (typeof query !== 'string') {
        console.warn("Skipping query because it's not a string:", query);
        return;
      }
      
      const trimmedQuery = query.trim();
      if (!trimmedQuery) {
        console.warn("Skipping query because it's empty:", query);
        return;
      }
      
      try {
        // Escape the query for regex use.
        const regex = new RegExp(escapeRegExp(trimmedQuery), 'gi');
        highlightedHtml = highlightedHtml.replace(regex, (match) => `<mark>${match}</mark>`);
      } catch (error) {
        console.error("Error creating regex for query:", query, error);
      }
    });
    
    return highlightedHtml;
  }