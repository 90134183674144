import { Link } from "react-router-dom"
import { Card, CardContent } from "../ui/card.tsx"
import { Species } from "../../data/mockSpecies.ts"

interface SpeciesCardProps {
  species: Species
}

export const SpeciesCard = ({ species }: SpeciesCardProps) => {
  return (
    <Link to={`/species/${species.id}`}>
      <Card className="hover:shadow-lg transition-shadow cursor-pointer">
        <CardContent className="p-6">
          {species.image && (
            <img
              src={species.image}
              alt={species.name}
              className="w-full h-40 object-cover mb-4 rounded"
            />
          )}
          <h3 className="text-xl font-semibold mb-1">{species.name}</h3>
          <p className="text-gray-600 italic">{species.latinName}</p>
        </CardContent>
      </Card>
    </Link>
  )
}