// src/pages/GabrielPage.jsx

import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import CurveView from '../components/graph/CurveView';
import { cachedExamples } from '../cached_examples';
import { highlightMatches, highlightMultipleMatches2 } from '../utils/highlightMatches';
import { FaSearch } from 'react-icons/fa';

const GabrielPage = () => {
  // Define 11 slider positions:
  // Positions 0–3 are “real” states; positions 4–10 are locked.
  const [stateIndex, setStateIndex] = useState(0);

  // Define labels for all positions.
  // (You can customize these labels if needed.)
  const stateLabels = [
    "",      // 0: example5 with all white nodes
    "",      // 1: example5 default
    "",      // 2: example6 with all white nodes
    "",      // 3: example6 default
    "",      // 4: locked
    "",      // 5: locked
    "",      // 6: locked
    "",      // 7: locked
    "",      // 8: locked
    "",      // 9: locked
    "",      // 10: locked
  ];

  // This state will hold any slider message (e.g. "Locked")
  const [sliderMessage, setSliderMessage] = useState("");

  // For convenience, grab the two examples from cachedExamples.
  const example5Data = cachedExamples.example5;
  const example6Data = cachedExamples.example6;

  // Use the “real” states (0–3) for our graph data.
  // (States 0–1 use example5; states 2–3 use example6.)
  const currentGraphData = stateIndex < 2 ? example5Data : example6Data;

  // Determine whether to override node colors (even states: 0 and 2).
  const forceWhite = stateIndex % 2 === 0;

  // Clone current graph data and override node (and cluster) colors if forceWhite.
  const modifiedGraphData = useMemo(() => {
    if (!currentGraphData) return null;
    if (!forceWhite) return currentGraphData; // No changes needed
    // Clone the data so as not to modify the original.
    const newData = JSON.parse(JSON.stringify(currentGraphData));
    if (newData.nodes) {
      newData.nodes = newData.nodes.map(node => ({ ...node, color: "#ffffff" }));
    }
    if (newData.clusters) {
      newData.clusters = newData.clusters.map(cluster => ({ ...cluster, color: "#ffffff" }));
    }
    return newData;
  }, [currentGraphData, forceWhite]);

  // Other states (element selection, search, etc.) remain unchanged.
  const [selectedElementData, setSelectedElementData] = useState(null);
  const [traceRefs, setTraceRefs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sourceText, setSourceText] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);

  const sourceContainerRef = useRef(null);
  const graphContainerRef = useRef(null);
  const [sliderStyle, setSliderStyle] = useState({
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'none'
  });

  // Load source text (gabriel.txt) on mount.
  useEffect(() => {
    fetch('/gabriel.txt')
      .then((res) => res.text())
      .then((text) => {
        console.log("gabriel.txt loaded:", text);
        setSourceText(text);
      })
      .catch((err) => console.error('Error loading gabriel.txt:', err));
  }, []);

  // Position slider overlay relative to the graph container.
  useEffect(() => {
    const updateSliderPosition = () => {
      if (graphContainerRef.current) {
        const rect = graphContainerRef.current.getBoundingClientRect();
        if (rect.bottom <= 0 || rect.top >= window.innerHeight) {
          setSliderStyle(prev => ({ ...prev, display: 'none' }));
          return;
        }
        const sliderHeight = 50;  // approximate slider height
        const top = rect.top < 0 ? 0 : rect.top;
        const sliderWidth = 256;  // assumed slider width (e.g. Tailwind’s w-64)
        const left = rect.left + rect.width / 2 - sliderWidth / 2;
        setSliderStyle({
          position: 'fixed',
          top: top,
          left: left,
          display: 'block'
        });
      }
    };
    updateSliderPosition();
    window.addEventListener('scroll', updateSliderPosition);
    window.addEventListener('resize', updateSliderPosition);
    return () => {
      window.removeEventListener('scroll', updateSliderPosition);
      window.removeEventListener('resize', updateSliderPosition);
    };
  }, []);

  // Update the slider’s value.
  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    if (newValue >= 4) { // If a locked state is selected…
      setSliderMessage("Låst :)");
    } else {
      setSliderMessage("");
      setStateIndex(newValue);
    }
  };

  // Set graph height (e.g. window height minus 80px).
  const graphHeight = window.innerHeight - 80;

  // Toggle search input visibility.
  const toggleSearchInput = () => {
    setShowSearchInput(prev => !prev);
  };

  // Example "Trace" handler.
  const handleTrace = () => {
    if (!selectedElementData || !selectedElementData.references) return;
    const validRefs = selectedElementData.references.filter(
      (ref) => typeof ref === 'string' && ref.trim() !== ''
    );
    console.log("handleTrace validRefs:", validRefs);
    setTraceRefs([...validRefs]);
  };

  // Compute highlighted HTML (using utility functions).
  const highlightedHTML = highlightMatches(sourceText, searchTerm);
  const filteredTraceRefs = traceRefs.filter(
    (ref) => typeof ref === 'string' && ref.trim() !== ''
  );
  let finalHighlightedHTML = highlightedHTML;
  try {
    finalHighlightedHTML = highlightMultipleMatches2(highlightedHTML, filteredTraceRefs);
  } catch (err) {
    console.error("Error in highlightMultipleMatches2:", err);
  }

  // Scroll the first <mark> element (if any) into view.
  useLayoutEffect(() => {
    if (filteredTraceRefs.length > 0 && sourceContainerRef.current) {
      const markElement = sourceContainerRef.current.querySelector('mark');
      if (markElement) {
        markElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [filteredTraceRefs, selectedElementData]);

  return (
    <div className="min-h-screen flex flex-col overflow-auto">
      {/* Main content: Graph view with overlays */}
      <div className="relative flex flex-grow">
        {/* Graph Container */}
        <div
          ref={graphContainerRef}
          className="w-full relative"
          style={{ height: graphHeight, overflowY: 'auto' }}
        >
          {modifiedGraphData ? (
            <CurveView
              graphData={modifiedGraphData}
              height={graphHeight}
              onElementSelect={(data) => {
                setSelectedElementData(data);
                setTraceRefs([]);
              }}
            />
          ) : (
            <p className="text-center text-gray-500 mt-10">No graph data available.</p>
          )}
        </div>
        
        {/* Slider overlay: Positioned using computed sliderStyle */}
        <div style={sliderStyle} className="pointer-events-auto z-50 p-2">
          <div className="flex flex-col items-center bg-white bg-opacity-90 rounded-lg shadow-md px-4 py-2">
            <div className="mb-2 text-sm font-semibold text-success">
              Connectivity Level
            </div>
            <input
              type="range"
              min="0"
              max="10"  // Total positions: 0–10
              step="1"
              value={stateIndex}
              onChange={handleSliderChange}
              className="w-64 h-2 appearance-none rounded-full"
              style={{
                background: 'linear-gradient(to right, #ffffff 0%, #1d1d22 100%)',
                accentColor: '#1d1d22',
              }}
            />
            <div className="mt-2 text-sm font-semibold text-success">
              {stateLabels[stateIndex]}
            </div>
            {/* Display slider message when a locked state is attempted */}
            {sliderMessage && (
              <div className="mt-1 text-sm" style={{ color: "#1d1d22" }}>
                {sliderMessage}
              </div>
            )}
          </div>
        </div>
        
        {/* Right-top overlay: Element details and source text */}
        {selectedElementData && (
          <div className="absolute top-2 right-2 z-20 flex flex-col gap-2 w-64">
            {/* Element Details */}
            <div className="border border-white p-2 rounded bg-white shadow-md">
              <h2 className="text-lg font-semibold mb-1">Element</h2>
              <p><strong>Name:</strong> {selectedElementData.label}</p>
              {selectedElementData.Type && <p><strong>Type:</strong> {selectedElementData.Type}</p>}
              <p><strong>Info:</strong> {selectedElementData.info}</p>
              {selectedElementData.references && selectedElementData.references.length > 0 && (
                <>
                  <strong>References:</strong>
                  <ul className="list-disc pl-5 text-sm text-gray-700">
                    {selectedElementData.references.map((ref, i) => (
                      <li key={i}>{ref}</li>
                    ))}
                  </ul>
                  <button
                    onClick={handleTrace}
                    className="mt-2 px-3 py-1 bg-gray-200 rounded hover:bg-gray-300 text-sm font-semibold"
                  >
                    Trace
                  </button>
                </>
              )}
            </div>
            {/* Source Text Overlay */}
            <div className="border border-white rounded bg-white p-2 text-sm text-gray-800">
              <div className="relative mb-2 flex justify-end">
                {showSearchInput ? (
                  <input
                    type="text"
                    className="w-full bg-white rounded p-2 border border-white focus:outline-none focus:border-success focus:ring-0"
                    placeholder="Type a term to find..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                ) : (
                  <button
                    onClick={toggleSearchInput}
                    className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow"
                    title="Search in text"
                  >
                    <FaSearch className="text-success" size={16} />
                  </button>
                )}
              </div>
              <div
                ref={sourceContainerRef}
                className="overflow-auto"
                style={{ whiteSpace: 'pre-wrap', height: '25vh' }}
                dangerouslySetInnerHTML={{ __html: finalHighlightedHTML }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GabrielPage;