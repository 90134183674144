import React from 'react';
import PropTypes from 'prop-types';

const ExampleSelector = ({ examples, onSelectExample }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {examples.map((example, index) => (
        <div
          key={index}
          className="px-3 py-1 rounded cursor-pointer bg-white hover:bg-successHover hover:text-white transition-colors"
          onClick={() => onSelectExample(example.text)}
        >
          <strong>{example.label}</strong>
        </div>
      ))}
    </div>
  );
};

ExampleSelector.propTypes = {
  examples: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectExample: PropTypes.func.isRequired,
};

export default ExampleSelector;