// src/features/species/pages/Upload.tsx

import React, { useState } from "react"
import { toast } from "sonner"
import { StepIndicator } from "../components/upload/StepIndicator.tsx"
import { UploadForm } from "../components/upload/UploadForm.tsx"
import { ReviewForm } from "../components/upload/ReviewForm.tsx"
import { ConfirmationMessage } from "../components/upload/ConfirmationMessage.tsx"

interface FinalChunk {
  data?: Array<{
    Name: string
    DataPoint: number | boolean
    Source: string
    Motivation: string
  }>
  markdown?: string
  pdf_key?: string
  status?: string
}

type Step = "upload" | "check" | "confirmation"

export default function UploadPage() {
  const [currentStep, setCurrentStep] = useState<Step>("upload")
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [extractedData, setExtractedData] = useState<FinalChunk | null>(null)

  // Instead of a simple string for species name, we store an object with both ID and name:
  const [selectedSpecies, setSelectedSpecies] = useState<{
    id: string
    name: string
  }>({ id: "", name: "" })

  const [streamStatus, setStreamStatus] = useState("")
  const [isExtracting, setIsExtracting] = useState(false)

  console.log("[UploadPage] step =", currentStep, "extractedData =", extractedData)

  /**
   * 1) Fetch a presigned URL
   * 2) Upload the file to S3
   * 3) Return the S3 key if successful
   */
  async function handleDirectS3Upload(file: File): Promise<string> {
    const filename = encodeURIComponent(file.name)
    const presignedUrl = `https://atlaz-api.com/species/api/generate-presigned-url?filename=${filename}`
    console.log("[handleDirectS3Upload] Requesting presigned URL from:", presignedUrl)

    const presignedResp = await fetch(presignedUrl)
    if (!presignedResp.ok) {
      const errorText = await presignedResp.text()
      console.error("[handleDirectS3Upload] Failed to get presigned URL. Response text:", errorText)
      throw new Error("Failed to get presigned URL")
    }

    const { url, fields, key } = await presignedResp.json()
    console.log("[handleDirectS3Upload] Presigned JSON:", { url, fields, key })

    const formData = new FormData()
    Object.entries(fields).forEach(([fieldName, fieldValue]) => {
      formData.append(fieldName, fieldValue as string)
    })
    formData.append("file", file)

    console.log("[handleDirectS3Upload] Uploading file to S3 presigned URL...")
    const uploadRes = await fetch(url, {
      method: "POST",
      body: formData,
    })
    if (!uploadRes.ok) {
      const errorText = await uploadRes.text()
      console.error("[handleDirectS3Upload] S3 upload failed. Response text:", errorText)
      throw new Error("S3 upload failed with status " + uploadRes.status)
    }

    console.log("[handleDirectS3Upload] S3 upload successful. Returning key:", key)
    return key
  }

  /**
   * Trigger the PDF extraction process:
   * 1) Upload the file to S3
   * 2) Stream extraction with the pdf_key + species_name
   */
  async function handleExtractData() {
    if (!selectedFile) {
      toast.error("No PDF file selected!")
      return
    }
    // Check both id and name to confirm a species was selected
    if (!selectedSpecies.id || !selectedSpecies.name) {
      toast.error("No species selected!")
      return
    }

    setIsExtracting(true)
    setStreamStatus("Starting extraction...")

    try {
      // Step 1: Upload to S3
      console.log("[handleExtractData] Step 1: Upload file to S3.")
      const pdfKey = await handleDirectS3Upload(selectedFile)

      // Step 2: Start streaming extraction
      console.log("[handleExtractData] Step 2: Begin streaming extraction with speciesName =", selectedSpecies.name)
      const extractionForm = new FormData()
      extractionForm.append("pdf_key", pdfKey)
      extractionForm.append("species_name", selectedSpecies.name) // <-- We send the name here

      console.log("[handleExtractData] POST /species/api/stream-extract-pdf-progress-key with:")
      extractionForm.forEach((val, key) => {
        console.log("   ", key, "=", val)
      })

      const response = await fetch(
        "https://atlaz-api.com/species/api/stream-extract-pdf-progress-key",
        {
          method: "POST",
          body: extractionForm,
        }
      )
      console.log("[handleExtractData] Extraction response:", response.status, response.statusText)
      if (!response.ok) {
        const errText = await response.text()
        console.error("[handleExtractData] Extraction error text:", errText)
        throw new Error(`HTTP ${response.status} - ${response.statusText}`)
      }

      const reader = response.body?.getReader()
      if (!reader) {
        throw new Error("No body returned or ReadableStream not supported.")
      }

      const decoder = new TextDecoder()
      let buffer = ""

      while (true) {
        const { value, done } = await reader.read()
        if (done) break

        const textChunk = decoder.decode(value, { stream: true })
        buffer += textChunk

        const lines = buffer.split("\n")
        buffer = lines.pop() || ""

        for (const line of lines) {
          const trimmed = line.trim()
          if (!trimmed) continue

          console.log("[handleExtractData] Raw line:", trimmed)
          try {
            const chunk = JSON.parse(trimmed)
            console.log("[handleExtractData] Parsed chunk:", chunk)

            if (chunk.status === "still_processing") {
              setStreamStatus("Extraction still in progress...")
            } else if (chunk.status === "error") {
              toast.error(chunk.detail || "Server error during extraction.")
              console.error("[handleExtractData] chunk.error detail:", chunk.detail)
            } else if (chunk.status === "completed" || chunk.data) {
              // Final chunk with extracted data
              console.log("[handleExtractData] Extraction completed. Setting final chunk.")
              setExtractedData(chunk)
              setCurrentStep("check")
            }
          } catch (parseErr) {
            console.warn("[handleExtractData] JSON parse error for line:", trimmed, parseErr)
          }
        }
      }
    } catch (err: any) {
      console.error("[handleExtractData] Outer catch error:", err)
      toast.error(err.message || "Extraction failed.")
    } finally {
      setIsExtracting(false)
      console.log("[handleExtractData] Extraction process ended.")
    }
  }

  /** Called after data is successfully saved to DB in ReviewForm. */
  function handleDataSaved() {
    console.log("[handleDataSaved] Data saved. Moving to 'confirmation' step.")
    setCurrentStep("confirmation")
  }

  function handleReset() {
    console.log("[handleReset] Resetting upload workflow.")
    setCurrentStep("upload")
    setSelectedFile(null)
    setExtractedData(null)
    setStreamStatus("")
    // Reset the object
    setSelectedSpecies({ id: "", name: "" })
  }

  return (
    <div className="p-6 space-y-8">
      <StepIndicator currentStep={currentStep} />

      {/* Show a little spinner or status if extracting */}
      {isExtracting && (
        <div className="flex items-center space-x-2 text-gray-600">
          <svg
            className="animate-spin h-5 w-5 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            />
          </svg>
          <span>Ongoing Extraction — {streamStatus}</span>
        </div>
      )}

      {currentStep === "upload" && (
        <UploadForm
          // Provide function that sets your File object
          onFileSelect={setSelectedFile}

          // Provide function that starts the extraction process
          onExtractData={handleExtractData}

          // Provide the currently selected file, for display
          selectedFile={selectedFile}

          /**
           * We update our "selectedSpecies" object from inside UploadForm.
           * The "SpeciesSelect" inside UploadForm will call this with { id, name }.
           */
          onSpeciesSelect={(speciesObj) => {
            // speciesObj => { id: "4", name: "Klockgentiana" }, for example
            setSelectedSpecies(speciesObj)
          }}
        />
      )}

      {currentStep === "check" && extractedData && (
        <ReviewForm
          extractedData={extractedData}
          selectedFile={selectedFile}
          /** Pass only the .name if the ReviewForm only needs the name */
          speciesName={selectedSpecies.id}
          onConfirm={handleDataSaved}
        />
      )}
      {currentStep === "check" && !extractedData && (
        <div className="bg-white p-4 text-red-600">
          <strong>[Warning]</strong> currentStep=check but extractedData is null/undefined
        </div>
      )}

      {currentStep === "confirmation" && (
        <ConfirmationMessage onReset={handleReset} />
      )}
    </div>
  )
}