// src/App.jsx
import React from 'react';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utils/firebase';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import BuilderPage from './pages/BuilderPage';
import DocsPage from './pages/DocsPage';
import JoelHeader from './components/common/JoelHeader';
import Home from './pages/Home';
import JoelPage from './pages/JoelPage';
import About from './pages/About';
import LoginPage from './pages/LoginPage';
import ExpBuilderPage from './pages/ExpBuilderPage';
import ManageAccount from './pages/ManageAccount';
import SpeciesLoginPage from './pages/SpeciesLoginPage';
import SpeciesPortal from './features/species/SpeciesPortal';
// New import for GabrielPage
import GabrielPage from './pages/GabrielPage';

const ProtectedRoute = ({ user, children }) => user ? children : <Navigate to="/login" replace />;
const ProtectedSpeciesRoute = ({ user, children }) => user ? children : <Navigate to="/species-login" replace />;

const AppContent = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading ...
      </div>
    );
  }
  const isJoelPage =
    location.pathname === '/joel' || location.pathname === '/home/joel';


  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Conditionally render the header */}
      {isJoelPage ? (
        <JoelHeader />
      ) : (
        <Header user={user} onSignOut={() => auth.signOut()} />
      )}

      <main className="flex-grow">
        <Routes>
          {/* Standard routes */}
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route 
            path="/home/joel" 
            element={<JoelPage />
            } 
          />



          <Route 
            path="/joel" 
            element={
                <JoelPage />
            } 
          />

          <Route 
            path="/gabriel" 
            element={
                <GabrielPage />
            } 
          />
          <Route path="/ycombinator" element={<BuilderPage />} />
    
         {/*  <Route path="/docs" element={<DocsPage />} />*/}
          <Route path="/login" element={user ? <Navigate to="/builder" replace /> : <LoginPage />} />


          <Route path="/" element={<Navigate to="/home" replace />} />

          {/* Protected standard routes */}
          <Route path="/manage-account" element={<ProtectedRoute user={user}><ManageAccount /></ProtectedRoute>} />
          
          <Route path="/builder" element={<ProtectedRoute user={user}><BuilderPage /></ProtectedRoute>} />
          
          <Route path="/exp-builder" element={<ProtectedRoute user={user}><ExpBuilderPage /></ProtectedRoute>} />
          

          {/* Species routes */}
          <Route path="/species-login" element={user ? <Navigate to="/species" replace /> : <SpeciesLoginPage />} />
          <Route path="/species/*" element={<ProtectedSpeciesRoute user={user}><SpeciesPortal /></ProtectedSpeciesRoute>} />
          
          <Route path="*" element={<Navigate to="/home" replace />} />

          
        </Routes>
      </main>

      <Footer />

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;