// src/features/species/components/upload/ReviewForm.tsx

import React, { useState } from "react"
import { flushSync } from "react-dom"  // <-- Ensure React 18+ is installed
import { Button } from "../ui/button.tsx"
import { Edit, Save, Info, Trash } from "lucide-react"
import { PDFViewer } from "../features/PDFViewer.tsx"
import { saveExtractedData } from "../../lib/api.ts"

interface TableRowData {
  Name: string
  DataPoint: number | boolean | null
  Source: string
  Motivation: string
}

interface FinalChunk {
  data?: TableRowData[]
  markdown?: string
  pdf_key?: string
  status?: string
}

interface ReviewFormProps {
  extractedData: FinalChunk | null
  selectedFile: File
  speciesName: string
  onConfirm: () => void
}

const nameMapping: Record<string, string> = {
  Extant_Populations: "Extant Populations",
  Extinct_Populations: "Extinct Populations",
  Population_Sizes_Lower_Bound: "Population Sizes (Lower Bound)",
  Population_Sizes_Upper_Bound: "Population Sizes (Upper Bound)",
  Population_Less_Than_5000_Individuals: "Populations - Less Than 5000 Individuals",
  Population_More_Than_5000_Individuals: "Populations - More Than 5000 Individuals",
  Phylogenetic_Geographic_Studies: "Phylogenetic/Geographic Studies",
  Population_Genetic_Studies: "Population Genetic Studies",
  DNA_based_Temporal_Monitoring: "DNA-based Temporal Monitoring",
}

export const ReviewForm = ({
  extractedData,
  selectedFile,
  speciesName,
  onConfirm,
}: ReviewFormProps) => {
  const safeData = Array.isArray(extractedData?.data) ? extractedData.data : []
  const safeMarkdown = extractedData?.markdown ?? ""

  const [tableData, setTableData] = useState<TableRowData[]>(safeData)
  const [editIndexes, setEditIndexes] = useState<Record<number, boolean>>({})

  // For PDF highlight
  const [sourceIndex, setSourceIndex] = useState<number | null>(null)
  const [selectedSourceText, setSelectedSourceText] = useState<string | null>(null)
  const [highlightVersion, setHighlightVersion] = useState(0)

  function getDataPointByName(name: string): number | boolean | null {
    const row = tableData.find((r) => r.Name === name)
    return row ? row.DataPoint : null
  }

  async function handleSaveExtractedData() {
    try {
      const pdfKey = extractedData?.pdf_key ?? ""

      const Extant_Populations = parseNumberOrNull(getDataPointByName("Extant_Populations"))
      const Extinct_Populations = parseNumberOrNull(getDataPointByName("Extinct_Populations"))
      const Population_Sizes_Lower_Bound = parseNumberOrNull(getDataPointByName("Population_Sizes_Lower_Bound"))
      const Population_Sizes_Upper_Bound = parseNumberOrNull(getDataPointByName("Population_Sizes_Upper_Bound"))
      const Population_Less_Than_5000_Individuals = parseNumberOrNull(getDataPointByName("Population_Less_Than_5000_Individuals"))
      const Population_More_Than_5000_Individuals = parseNumberOrNull(getDataPointByName("Population_More_Than_5000_Individuals"))

      const Phylogenetic_Geographic_Studies = parseBoolOrNull(getDataPointByName("Phylogenetic/Geographic_Studies"))
      const Population_Genetic_Studies = parseBoolOrNull(getDataPointByName("Population_Genetic_Studies"))
      const DNA_based_Temporal_Monitoring = parseBoolOrNull(getDataPointByName("DNA_based_Temporal_Monitoring"))

      const payload = {
        pdf_key: pdfKey,
        species_name: speciesName,
        Extant_Populations,
        Extinct_Populations,
        Population_Sizes_Lower_Bound,
        Population_Sizes_Upper_Bound,
        Population_Less_Than_5000_Individuals,
        Population_More_Than_5000_Individuals,
        Phylogenetic_Geographic_Studies,
        Population_Genetic_Studies,
        DNA_based_Temporal_Monitoring,
      }

      console.log("[ReviewForm] about to save =>", payload)
      const result = await saveExtractedData(payload)
      console.log("[ReviewForm] Save success:", result)

      onConfirm()
    } catch (err) {
      console.error("[ReviewForm] handleSaveExtractedData error:", err)
      alert("Failed to save data. See console for details.")
    }
  }

  function parseNumberOrNull(val: number | boolean | null): number | null {
    if (val === null) return null
    if (typeof val === "boolean") return null
    return val
  }

  function parseBoolOrNull(val: number | boolean | null): boolean | null {
    if (val === null) return null
    if (typeof val === "number") return null
    return val
  }

  const handleDataPointChange = (rowIndex: number, newValue: string) => {
    setTableData((prev) => {
      const updated = [...prev]
      const currentValue = updated[rowIndex].DataPoint

      if (typeof currentValue === "boolean" || currentValue == null) {
        if (!newValue.trim()) {
          updated[rowIndex].DataPoint = null
        } else {
          updated[rowIndex].DataPoint = newValue.toLowerCase() === "yes"
        }
      } else {
        if (!newValue.trim()) {
          updated[rowIndex].DataPoint = null
        } else {
          const numericValue = Number(newValue)
          updated[rowIndex].DataPoint = isNaN(numericValue) ? null : numericValue
        }
      }
      return updated
    })
  }

  const handleEditClick = (rowIndex: number) => {
    setEditIndexes((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }))
  }

  /**
   * Handle info click with flushSync:
   * - We do two separate render passes so PDFViewer sees highlightText go “null -> newText”.
   */
  const handleInfoClick = (rowIndex: number) => {
    const newSource = tableData[rowIndex].Source

    // 1) Force a full render pass that clears highlight
    flushSync(() => {
      setSelectedSourceText(null)
      setSourceIndex(null)
      setHighlightVersion((prev) => prev + 1)
    })

    // 2) Force a second render pass that sets new highlight
    flushSync(() => {
      setSelectedSourceText(newSource)
      setSourceIndex(rowIndex)
      setHighlightVersion((prev) => prev + 1)
    })
  }

  const handleDeleteClick = (rowIndex: number) => {
    setTableData((prev) => prev.filter((_, idx) => idx !== rowIndex))

    if (sourceIndex === rowIndex) {
      setSourceIndex(null)
      setSelectedSourceText(null)
    } else if (sourceIndex !== null && rowIndex < sourceIndex) {
      setSourceIndex((old) => (old != null ? old - 1 : null))
    }

    setEditIndexes((prev) => {
      const updated = { ...prev }
      delete updated[rowIndex]
      const newEdit: Record<number, boolean> = {}
      Object.keys(updated).forEach((k) => {
        const idx = Number(k)
        newEdit[idx > rowIndex ? idx - 1 : idx] = updated[k]
      })
      return newEdit
    })
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        
        {/* LEFT: The Extracted Data Table */}
        <div>
          <h2 className="text-lg font-medium mb-4">Review Extracted Data</h2>
          {tableData.length > 0 ? (
            <table className="w-full text-sm border-collapse">
              <thead>
                <tr className="bg-gray-50 border-b">
                  <th className="p-2 text-left">Name</th>
                  <th className="p-2 text-left">DataPoint</th>
                  <th className="p-2 text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIdx) => {
                  const isEditing = !!editIndexes[rowIdx]
                  const isBoolean = typeof row.DataPoint === "boolean"
                  const value = row.DataPoint

                  return (
                    <tr
                      key={rowIdx}
                      className={`border-b hover:bg-gray-50 ${
                        sourceIndex === rowIdx ? "bg-yellow-100" : ""
                      }`}
                    >
                      <td className="p-2">
                        {nameMapping[row.Name] || row.Name}
                      </td>
                      <td className="p-2">
                        {isEditing ? (
                          isBoolean ? (
                            <select
                              value={value === true ? "yes" : value === false ? "no" : ""}
                              onChange={(e) => handleDataPointChange(rowIdx, e.target.value)}
                              className="border p-1 rounded"
                            >
                              <option value="">(none)</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          ) : (
                            <input
                              type="number"
                              value={value ?? ""}
                              onChange={(e) => handleDataPointChange(rowIdx, e.target.value)}
                              className="w-full p-1 border rounded"
                            />
                          )
                        ) : (
                          isBoolean
                            ? value === true
                              ? "Yes"
                              : value === false
                                ? "No"
                                : "—"
                            : value ?? "—"
                        )}
                      </td>
                      <td className="p-2 text-right flex justify-end">
                        <button
                          onClick={() => handleEditClick(rowIdx)}
                          className="mr-2 text-gray-600 hover:text-gray-900"
                          aria-label={isEditing ? "Save" : "Edit"}
                        >
                          {isEditing ? <Save className="h-4 w-4" /> : <Edit className="h-4 w-4" />}
                        </button>
                        <button
                          onClick={() => handleInfoClick(rowIdx)}
                          className="mr-2 text-gray-600 hover:text-gray-900"
                          aria-label="Info"
                        >
                          <Info className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(rowIdx)}
                          className="text-red-600 hover:text-red-800"
                          aria-label="Delete"
                        >
                          <Trash className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-600 italic">No rows found in the extraction.</p>
          )}

          {sourceIndex !== null && tableData[sourceIndex] && (
            <div className="mt-4 p-4 border rounded bg-gray-100">
              <h4 className="text-md font-semibold mb-2">
                Motivation for "
                {nameMapping[tableData[sourceIndex].Name] || tableData[sourceIndex].Name}
                "
              </h4>
              <p className="text-gray-700 mb-4">
                {tableData[sourceIndex].Motivation || "(No motivation text)"}
              </p>

              <h4 className="text-md font-semibold mb-2">
                Source for "
                {nameMapping[tableData[sourceIndex].Name] || tableData[sourceIndex].Name}
                "
              </h4>
              <p className="text-gray-700">
                {tableData[sourceIndex].Source || "(No source text)"}
              </p>
            </div>
          )}
        </div>

        {/* RIGHT: PDF Viewer */}
        <div>
          <h3 className="text-lg font-medium mb-4">PDF Preview</h3>
          <PDFViewer
            markdown={safeMarkdown}
            highlightText={selectedSourceText}
            highlightVersion={highlightVersion}
          />
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <Button
          onClick={handleSaveExtractedData}
          disabled={Object.values(editIndexes).some(Boolean)}
        >
          Confirm Data
        </Button>
      </div>
    </div>
  )
}
