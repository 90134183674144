// src/pages/BuilderPage.jsx
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { auth } from '../utils/firebase';
import { cachedExamples } from '../cached_examples';
import { highlightMatches } from '../utils/highlightMatches';
import useExampleData from '../hooks/useExampleData';
import CurveView from '../components/graph/CurveView';
// Import the builder subcomponents
import ExampleSelector from '../components/builder/ExampleSelector';
import GraphGenerator from '../components/builder/GraphGenerator';
import SourceDataPanel from '../components/builder/SourceDataPanel';

const BuilderPage = () => {
  // Shared state
  const [inputText, setInputText] = useState('');
  const [customizationText, setCustomizationText] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCustomization, setShowCustomization] = useState(false);
  const [status, setStatus] = useState('');
  const [selectedElementData, setSelectedElementData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // Get example data from hook
  const {
    exampleData1,
    exampleData2,
    exampleData3,
    exampleData4,
    exampleData5,
    exampleData6,
  } = useExampleData();

  const examples = [
    { label: 'Tesla Q.Report', text: exampleData1 },
    { label: 'Abstract Algebra', text: exampleData2 },
    { label: 'CRISPR-Cas9', text: exampleData3 },
    { label: 'Attention', text: exampleData4 },
    // Optionally add more examples here…
  ];

  const handleSubmit = async (text) => {
    const textToProcess = text !== undefined ? text : inputText;
    if (!textToProcess.trim()) {
      toast.error('Please enter some source data');
      return;
    }
    setSelectedElementData(null);
    // Check cached examples using textToProcess
    if (textToProcess.trim() === exampleData1.trim()) {
      setGraphData(cachedExamples.example1);
      setStatus('');
      return;
    }
    if (textToProcess.trim() === exampleData2.trim()) {
      setGraphData(cachedExamples.example2);
      setStatus('');
      return;
    }
    if (textToProcess.trim() === exampleData3.trim()) {
      setGraphData(cachedExamples.example3);
      setStatus('');
      return;
    }
    if (textToProcess.trim() === exampleData4.trim()) {
      setGraphData(cachedExamples.example4);
      setStatus('');
      return;
    }
    if (textToProcess.trim() === exampleData5.trim()) {
      setGraphData(cachedExamples.example5);
      setStatus('');
      return;
    }
    if (textToProcess.trim() === exampleData6.trim()) {
      setGraphData(cachedExamples.example8);
      setStatus('');
      return;
    }

    setIsProcessing(true);
    setStatus('Processing...');
    setGraphData(null);

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch('https://atlaz-api.com/raspberry-preview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ text: textToProcess, customization: customizationText }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop();

        for (const line of lines) {
          if (line.trim()) {
            try {
              const chunk = JSON.parse(line);
              if (chunk.status === 'still_processing') {
                setStatus('Still processing...');
              } else if (chunk.status === 'completed' && chunk.graph) {
                const { nodes, edges, categories } = chunk.graph;
                setGraphData({
                  nodes: nodes || [],
                  edges: edges || [],
                  categories: categories || [],
                });
                setStatus('Completed');
              }
            } catch (err) {
              console.error('Error parsing JSON chunk:', err, 'Line:', line);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate graph');
      setStatus('Error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * Modified handleExampleClick: It sets the input text and then immediately
   * calls handleSubmit with the example text so that the graph is generated right away.
   */
  const handleExampleClick = (exampleText) => {
    setInputText(exampleText);
    handleSubmit(exampleText);
  };

  // Compute the highlighted HTML from the input text and search term.
  const highlightedHTML = highlightMatches(inputText, searchTerm);

  return (
    <div className="min-h-screen w-full overflow-y-auto p-4">
      {/* Centered Heading Above the merged panel */}
      <div className="max-w-7xl mx-auto mb-4">
        <h1 className="text-2xl font-bold text-center">Build a knowledge graph</h1>
      </div>

      {/* Merged Panel Container (auto-height now) */}
      <div className="max-w-7xl mx-auto bg-inputBackground rounded p-4 flex gap-6">
        {/* Left column: instructions, examples, and graph generator */}
        <div className="w-1/2 flex flex-col gap-4">
          <p className="text-gray-700 leading-relaxed">
            Provide your text and generate the graph. Context size is 15–40 pages depending on text density.
          </p>
          <ExampleSelector examples={examples} onSelectExample={handleExampleClick} />
          <GraphGenerator
            isProcessing={isProcessing}
            status={status}
            onSubmit={() => handleSubmit()}
            showCustomization={showCustomization}
            onToggleCustomization={() => setShowCustomization((prev) => !prev)}
            customizationText={customizationText}
            onCustomizationChange={(e) => setCustomizationText(e.target.value)}
            graphData={graphData}
            onElementSelect={setSelectedElementData}
          />
        </div>

        {/* Right column: source data panel – wrapped in a div that hides its h2 title */}
        <div className="w-1/2 flex flex-col gap-4 custom-source">
          <SourceDataPanel
            inputText={inputText}
            onInputChange={(e) => setInputText(e.target.value)}
            searchTerm={searchTerm}
            onSearchTermChange={(e) => setSearchTerm(e.target.value)}
            isSearchVisible={isSearchVisible}
            onToggleSearch={() => setIsSearchVisible((prev) => !prev)}
            highlightedHTML={highlightedHTML}
          />
        </div>
      </div>

      {/* Full-width Graph Section BELOW the merged panel */}
      {graphData && (
        <div className="max-w-7xl mx-auto mt-6">
            <div className="h-full relative">
              <CurveView
                graphData={graphData}
                onElementSelect={setSelectedElementData}
              />
              {selectedElementData && (
                <div className="absolute top-2 right-2 border border-white p-2 rounded bg-white shadow-md z-10">
                  <h2 className="text-lg font-semibold mb-1">Element</h2>
                  <p>
                    <strong>Name:</strong> {selectedElementData.label}
                  </p>
                  <p>
                    <strong>Info:</strong> {selectedElementData.info}
                  </p>
                </div>
              )}
            </div>
        </div>
      )}

      {/* Inline style to hide the "Source Data" title inside our SourceDataPanel instance */}
      <style>{`
        .custom-source h2 {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default BuilderPage;