// src/components/builder/SourceDataPanel.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaSearch, FaEdit, FaCheck } from 'react-icons/fa';

const SourceDataPanel = ({
  inputText,
  onInputChange,
  searchTerm,
  onSearchTermChange,
  isSearchVisible,
  onToggleSearch,
  highlightedHTML,
}) => {
  // Default to editing mode if there's no content.
  const [isEditing, setIsEditing] = useState(!inputText);

  // Switch to editing mode if inputText becomes empty.
  useEffect(() => {
    if (!inputText) {
      setIsEditing(true);
    }
  }, [inputText]);

  return (
    <div>
      <h2 className="text-lg font-semibold">Source Data</h2>

      <div className="relative mt-2">
        {isEditing ? (
          <textarea
            className="w-full bg-white rounded p-2 overflow-auto border border-white focus:outline-none focus:border-success focus:ring-0"
            style={{ height: '196px' }} // Inline style to match the viewer height
            value={inputText}
            onChange={onInputChange}
            placeholder="Enter your text..."
          />
        ) : (
          <div
            className="bg-white rounded p-2 text-sm text-gray-800 overflow-auto"
            style={{ height: '196px' }}
            dangerouslySetInnerHTML={{ __html: highlightedHTML }}
          />
        )}

        {isEditing ? (
          // In editing mode, show a check mark in the top-right if there's non-empty text.
          inputText.trim() !== '' && (
            <button
              onClick={() => setIsEditing(false)}
              className="absolute top-2 right-2 flex items-center justify-center w-8 h-8 bg-white rounded-full shadow"
              title="Save source data"
            >
              <FaCheck className="text-success" size={16} />
            </button>
          )
        ) : (
          // In read-only mode, render a container that holds either the edit button or the search input (on the left)
          // and always show the search toggle button on the right.
          <div className="absolute top-2 right-2 z-10 flex items-center space-x-2">
            {isSearchVisible ? (
              // When search is active, show the search input (without the icon)
              <div className="relative">
                <input
                  type="text"
                  className="w-full bg-white rounded p-2 border border-white focus:outline-none focus:border-success focus:ring-0"
                  placeholder="Search in text..."
                  value={searchTerm}
                  onChange={onSearchTermChange}
                  autoFocus
                  onBlur={onToggleSearch}
                />
              </div>
            ) : (
              // When search is not active, show the edit button.
              <button
                onClick={() => setIsEditing(true)}
                className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow"
                title="Edit source data"
              >
                <FaEdit className="text-success" size={16} />
              </button>
            )}
            {/* Always display the search toggle button on the right */}
            <button
              onClick={onToggleSearch}
              className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow"
              title="Search in text"
            >
              <FaSearch className="text-success" size={16} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SourceDataPanel.propTypes = {
  inputText: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  isSearchVisible: PropTypes.bool.isRequired,
  onToggleSearch: PropTypes.func.isRequired,
  highlightedHTML: PropTypes.string.isRequired,
};

export default SourceDataPanel;