import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"

import { cn } from "../../lib/utils.ts"
import { Button } from "../ui/button.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover.tsx"

interface SpeciesSelectProps {
  /**
   * Now returns an object:
   * {
   *   id: string;   // numeric ID as a string
   *   name: string; // common name (or "Unnamed Species")
   * }
   */
  onSelect?: (speciesData: { id: string; name: string }) => void
}

export function SpeciesSelect({ onSelect }: SpeciesSelectProps) {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")

  // 1) Fetch from your backend
  const fetchSpecies = async () => {
    const res = await fetch("https://atlaz-api.com/species/get-species")
    if (!res.ok) {
      throw new Error("Error fetching species.")
    }
    return res.json() // => { species_data: [ { id?: number, name, latin_name, imagelink }, ... ] }
  }

  const {
    data,
    isLoading,
    isError,
    error
  } = useQuery({
    queryKey: ["allSpeciesForSelect"],
    queryFn: fetchSpecies,
  })

  if (isLoading) {
    return (
      <div className="py-2 px-3 flex items-center space-x-2 text-gray-600">
        <Loader2 className="h-4 w-4 animate-spin" />
        <span>Loading species list...</span>
      </div>
    )
  }

  if (isError) {
    return (
      <p className="text-red-500">
        Error: {(error as Error).message}
      </p>
    )
  }

  // 2) Transform data into a list of { value, label } + maybe store the original
  const fetchedSpecies = data?.species_data ?? []

  // We'll prefer the backend's real ID, falling back to index
  const speciesOptions = fetchedSpecies.map((sp: any, index: number) => ({
    // Keep the ID numeric, but store as string so we can pass it easily
    value: String(sp.id ?? index + 1),
    label: sp.name || "Unnamed Species",
  }))

  // 3) Render
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedValue
            ? speciesOptions.find((opt) => opt.value === selectedValue)?.label
            : "Select species..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-[200px] p-0 bg-white shadow-md" align="start">
        <Command>
          {/* This built-in filter handles searching within the CommandItems */}
          <CommandInput placeholder="Search species..." />
          <CommandList>
            <CommandEmpty>No species found.</CommandEmpty>
            <CommandGroup>
              {speciesOptions.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.label} // used for searching
                  onSelect={() => {
                    // If user selects the same item again, that means "clear"
                    const isCurrentlySelected = (item.value === selectedValue)
                    const newValue = isCurrentlySelected ? "" : item.value
                    setSelectedValue(newValue)

                    // Also call parent callback with BOTH id and name
                    if (onSelect) {
                      if (!newValue) {
                        // Means user deselected
                        onSelect({ id: "", name: "" })
                      } else {
                        onSelect({ id: item.value, name: item.label })
                      }
                    }

                    setOpen(false)
                  }}
                >
                  {/* A check icon if it's the active one */}
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedValue === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}