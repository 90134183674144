// src/components/common/Footer.jsx
import React from 'react';

const Footer = () => (
  <footer className="bg-inputBackground shadow shrink-0">
    <div className="w-full px-6 py-4 text-center text-bg-success text-sm">
      &copy; {new Date().getFullYear()} Atlaz. All rights reserved.
    </div>
  </footer>
);

export default Footer;