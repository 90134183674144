// src/components/common/Button.jsx
import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, onClick, variant, disabled, iconOnly }) => {
  const baseClasses = iconOnly
    ? 'p-2 text-white hover:text-white transition inline-flex items-center justify-center focus:outline-none'
    : 'px-4 py-2 rounded-md text-sm font-medium transition';

  // Using Tailwind classes that refer to our custom color names
  const variants = {
    primary: 'bg-success text-white hover:bg-successHover',
    secondary: 'bg-success text-white hover:bg-successHover',
    success: 'bg-success text-white hover:bg-successHover',
    danger: 'bg-danger text-white hover:bg-danger',
    fifth: 'bg-secondary text-white hover:bg-successHover',
  };

  const classes = iconOnly
    ? `${baseClasses} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`
    : `${baseClasses} ${variants[variant]} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;

  return (
    <button onClick={onClick} className={classes} disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => {},
  variant: 'primary',
  disabled: false,
  iconOnly: false,
};

export default Button;