// src/components/graph/KnowledgeGraph.jsx
import React, { useEffect, useRef, useMemo } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import Cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import fcose from 'cytoscape-fcose';
import colors from '../../theme/colors.js';
import { remapColor } from '../../utils/colors.js';

// Cytoscape.use(dagre);
Cytoscape.use(fcose);

// Helper: clamp a value between a minimum and maximum.
const clamp = (value, min, max) => Math.min(max, Math.max(min, value));

// New helper function to return white if the provided color is white
const resolveColor = (color) => {
  if (!color) return color;
  return color.trim().toLowerCase() === '#ffffff' ? '#ffffff' : remapColor(color);
};

const KnowledgeGraph = ({
  data,
  onElementSelect,
  highlightNodes = []
}) => {
  const cyRef = useRef(null);
  const containerRef = useRef(null);
  const prevDataRef = useRef(null);

  // Layout configuration.
  const layout = {
    name: 'fcose',
    rankDir: 'TB',
    nodeSep: 50,
    edgeSep: 10,
    rankSep: 100,
    ranker: 'longest-path'
  };

  // Prepare graph elements.
  const elements = useMemo(() => {
    const clusterElements = (data.clusters || []).map((cluster) => ({
      data: {
        id: cluster.id,
        label: cluster.label,
        // Use resolveColor to ensure white isn't remapped
        color: resolveColor(cluster.color),
        shape: cluster.shape,
      },
      classes: 'cluster',
      group: 'nodes',
    }));

    const nodeElements = (data.nodes || []).map((node) => {
      const nodeData = {
        id: node.id,
        label: node.label,
        // Use resolveColor to override if the node's color is white
        color: resolveColor(node.color),
        shape: 'ellipse',
      };
      if (node.parent) nodeData.parent = node.parent;
      if (node.info) nodeData.info = node.info;
      if (node.references) nodeData.references = node.references;
      if (node.Type) nodeData.Type = node.Type;
      return { data: nodeData, group: 'nodes' };
    });

    const edgeElements = (data.links || []).map((link) => {
      const edgeData = {
        id: link.id || `${link.source}-${link.target}-${Math.random()}`,
        source: link.source,
        target: link.target,
        color: 'black',
        width: link.width || 2,
        style: link.style || 'solid',
        arrowShape: link.arrowShape || 'triangle',
        label: link.label,
        Type: link.Type
      };
      if (link.info) edgeData.info = link.info;
      if (link.references) edgeData.references = link.references;
      // Use a larger minLen for "type of" links.
      edgeData.minLen = (link.label && link.label.toLowerCase() === 'type of') ? 3 : 1;
      return { data: edgeData, group: 'edges' };
    });

    return [...clusterElements, ...nodeElements, ...edgeElements];
  }, [data]);

  // Cytoscape stylesheet.
  const stylesheet = [
    {
      selector: 'node[color = "#1d1d22"], node[color = "#45454a"], node[color = "#3b3b40"], node[color = "#2a2a2f"], node[color = "#5C5C62"], node[color = "#5c5c62"]',
      style: { 'color': '#ffffff' },
    },
    {
      selector: 'node:parent[color = "#1d1d22"], node:parent[color = "#45454a"], node:parent[color = "#3b3b40"], node:parent[color = "#2a2a2f"], node:parent[color = "#5C5C62"], node:parent[color = "#5c5c62"]',
      style: { 'color': '#ffffff' },
    },
    {
      selector: 'node',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-wrap': 'wrap',
        'text-max-width': 100,
        'font-size': '14px',
        'text-valign': 'center',
        'text-halign': 'center',
        width: 'label',
        height: 'label',
        padding: '10px',
        'border-color': '#000000',
        'border-width': '1px',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    {
      selector: 'node:parent',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'text-max-width': 150,
        'font-size': '16px',
        padding: '20px',
        'border-color': '#000000',
        'border-width': '1px',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    {
      selector: 'edge',
      style: {
        'line-color': 'data(color)',
        width: '1',
        'line-style': 'data(style)',
        'target-arrow-shape': 'data(arrowShape)',
        'target-arrow-color': 'data(color)',
        'curve-style': 'bezier',
        label: 'data(label)',
        'font-size': '12px',
        color: '#1d1d22',
        'text-background-color': '#fff',
        'text-background-opacity': 0.7,
        'text-rotation': 'autorotate',
        'text-margin-y': '-10px',
        'edge-text-rotation': 'autorotate',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    {
      selector: '.cluster',
      style: {
        'background-color': 'data(color)',
        shape: 'data(shape)',
        label: 'data(label)',
        'text-valign': 'top',
        'text-halign': 'center',
        'font-size': '16px',
        'z-index': 10,
        cursor: 'pointer',
      },
    },
    {
      selector: '.highlighted',
      style: {
        'border-color': colors.graphBorderHighlight,
        'border-width': 4,
        'background-color': colors.graphHighlight,
        width: 100,
        height: 100,
        'font-size': '16px',
      },
    },
  ];

  // (The rest of your KnowledgeGraph component remains unchanged,
  // including effects to update Cytoscape and panning logic.)

  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;
    cy.nodes().removeClass('highlighted');
    highlightNodes.forEach((id) => {
      cy.$(`node[id="${id}"]`).addClass('highlighted');
    });
  }, [highlightNodes]);

  useEffect(() => {
    const cy = cyRef.current;
    if (!cy) return;
    const dataString = JSON.stringify(data);
    if (prevDataRef.current === dataString) return;
    prevDataRef.current = dataString;
    cy.elements().remove();
    cy.add(elements);
    cy.layout(layout).run();
    cy.off('tap');
    cy.on('tap', 'node, edge', (e) => {
      if (onElementSelect) onElementSelect(e.target.data());
    });
    cy.on('tap', (e) => {
      if (e.target === cy && onElementSelect) onElementSelect(null);
    });
  }, [data, elements, layout, onElementSelect]);

  useEffect(() => {
    const cy = cyRef.current;
    const container = containerRef.current;
    if (!cy || !container) return;

    const clampPan = () => {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const zoom = cy.zoom();
      const bb = cy.elements().boundingBox();

      const minPanX = containerWidth - zoom * bb.x2;
      const maxPanX = -zoom * bb.x1;
      const minPanY = containerHeight - zoom * bb.y2;
      const maxPanY = -zoom * bb.y1;

      const pan = cy.pan();
      const newPanX = clamp(pan.x, minPanX, maxPanX);
      const newPanY = clamp(pan.y, minPanY, maxPanY);

      if (newPanX !== pan.x || newPanY !== pan.y) {
        cy.pan({ x: newPanX, y: newPanY });
      }
    };

    cy.on('panend zoomend', clampPan);
    clampPan();

    return () => {
      cy.off('panend zoomend', clampPan);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full h-full rounded-md"
      style={{ backgroundColor: '#f9fafb', overflow: 'hidden' }}
    >
      <CytoscapeComponent
        cy={(cy) => {
          cyRef.current = cy;
        }}
        elements={[]} // Managed manually
        stylesheet={stylesheet}
        style={{ width: '100%', height: '100%' }}
        wheelSensitivity={0.5}
      />
    </div>
  );
};

export default KnowledgeGraph;