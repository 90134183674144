// src/components/common/JoelHeader.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const JoelHeader = () => {
  return (
    <header className="bg-white shrink-0 relative">
      {/* Centered title */}
      <div className="w-full px-6 py-4 flex items-center justify-center">
        <h1 className="text-xl font-bold text-gray-800">
          On leadership | Jensen Huang and Joel Hellermark
        </h1>
      </div>
      {/* Logo on the left */}
      <div className="absolute left-6 top-1/2 transform -translate-y-1/2">
        <Link to="/home">
          <img
            src="/logo.png"
            alt="Logo"
            className="h-auto w-auto mr-3 mix-blend-multiply"
            style={{ maxHeight: '40px' }}
          />
        </Link>
      </div>
    </header>
  );
};

export default JoelHeader;