
export const newPalette = [
    "#45454a",
    "#ffffff",
    "#1d1d22",
    "#2a2a2f",
    "#3b3b40",
    "#5c5c63",
    "#707077",
    "#8a8a91",
    "#b0b0b5",
    "#d4d4d9"
  ];
  
  export const colorMapping = {}; // mapping: originalColor => newColor
  let nextColorIndex = 0;
  
  /**
   * remapColor:
   *  - Returns the previously assigned new color if the originalColor was seen.
   *  - Otherwise, it assigns the next available color from the palette.
   */
  export function remapColor(originalColor) {
    if (!originalColor) return newPalette[0];
  
    if (colorMapping[originalColor]) {
      return colorMapping[originalColor];
    } else {
      const mappedColor = newPalette[nextColorIndex % newPalette.length];
      colorMapping[originalColor] = mappedColor;
      nextColorIndex++;
      return mappedColor;
    }
  }
  