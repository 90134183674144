// src/theme/colors.js #EF4444
const colors = {
    primary: "#EEC887",
    primaryForeground: "#ffffff",
    secondary: "#F1F1F1",
    success: "#1d1d22",
    successHover: "#45454a",
    danger: "#EF4444",
    inputBackground: "#f9fafb",
    graphHighlight: "#800080",
    graphBorder: "#a0a0a0",
    graphBorderHighlight: "#000000",
  };
  
module.exports = colors;

// src/utils/colorMapping.js
