// src/components/graph/CurveView.jsx
import React, { useMemo } from 'react';
import KnowledgeGraph from './KnowledgeGraph';
import { createGraphData } from '../../utils/graphData';

const CurveView = ({ graphData, height = 600, onElementSelect, highlightNodes = [] }) => {
  const processedGraphData = useMemo(
    () => createGraphData(graphData),
    [graphData]
  );

  return (
    <div style={{ width: '100%', height: `${height}px` }}>
      <KnowledgeGraph
        data={processedGraphData}
        height={height}
        onElementSelect={onElementSelect}
        highlightNodes={highlightNodes}  // forward the array
      />
    </div>
  );
};

export default CurveView;