// src/features/species/components/upload/UploadForm.tsx

import { FileUpload } from "../features/FileUpload.tsx"
import { SpeciesSelect } from "../species/SpeciesSelect.tsx"
import { Button } from "../ui/button.tsx"

/**
 * Updated prop definition:
 *  onSpeciesSelect now receives an object { id: string; name: string }
 *  rather than just a single string.
 */
interface UploadFormProps {
  onSpeciesSelect: (speciesData: { id: string; name: string }) => void
  onFileSelect: (file: File | null) => void
  onExtractData: () => void
  selectedFile: File | null
}

export const UploadForm = ({
  onSpeciesSelect,
  onFileSelect,
  onExtractData,
  selectedFile,
}: UploadFormProps) => {
  return (
    <div className="space-y-6">
      <div className="bg-white shadow rounded-lg p-8">
        <h2 className="text-lg font-medium mb-4">Select Species</h2>
        {/* Now this dropdown is populated from your backend.
            It will return { id: string, name: string } when selected. */}
        <SpeciesSelect
          onSelect={(selectedSpecies) => {
            // selectedSpecies => { id: "4", name: "Klockgentiana" }, for example
            onSpeciesSelect(selectedSpecies)
          }}
        />
      </div>

      <div className="bg-white shadow rounded-lg p-8">
        <h2 className="text-lg font-medium mb-4">Upload PDF Report</h2>
        <FileUpload onFileSelect={onFileSelect} />
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
      </div>

      <div className="flex justify-end">
        <Button onClick={onExtractData}>Extract Data</Button>
      </div>
    </div>
  )
}